// Validation errors messages for window.Parsley && window.Parsley
// Load this after window.Parsley && window.Parsley

window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('hr', {
    dateiso:  "Ovo polje treba sadržavati ispravno unešen datum (GGGG-MM-DD).",
    minwords: "Unos je prekratak. Treba sadržavati %s ili više riječi.",
    maxwords: "Unos je predugačak. Treba sadržavati %s ili manje riječi.",
    words:    "Neispravna duljina unosa. Treba sadržavati između %s i %s riječi.",
    gt:       "Ova vrijednost treba biti veća.",
    gte:      "Ova vrijednost treba biti veća ili jednaka.",
    lt:       "Ova vrijednost treba biti manja.",
    lte:      "Ova vrijednost treba biti manja ili jednaka.",
    notequalto: "Ova vrijednost treba biti drugačija."
  });
});

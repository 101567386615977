import BaseComponent from '$Foundation/src/js/BaseComponent';
import { config } from '../../index';
import MediatorEvents from '../enums/mediatorEvents';
import MediatorEventModel from '../models/mediatorEventModel';

export default class FilterListingViewAllControl extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'filter-view-all-btn';
    }

    static get constructorName() {
        return 'filterListingViewAllControl';
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    setVariables() {
        this.isInited = true;
        this.mediator = null;
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    addListeners() {
        this.addListener(this.el, 'click', e => this.handleViewAllBtnClick(e));
    }

    handleViewAllBtnClick(e) {
        e.preventDefault();
        const category = e.target.getAttribute('data-filter-category');
        const value = e.target.getAttribute('data-filter-value');
        const mediatorEvent = new MediatorEventModel();

        mediatorEvent.eventData = {
            category,
            value,
            viewAll: true
        };

        mediatorEvent.eventType = MediatorEvents.viewAllBtnClicked;

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    onDestroy() {
        this.el = null;
    }
}

import BaseComponent from "$Foundation/src/js/BaseComponent";

export default class MobileSearchButton extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return "mobile-search-button";
    }

    init() {
        this.addListeners();
    }

    addListeners() {
        if(location.href.indexOf("hk")!==-1){
            const testArr = [document.querySelectorAll(".mobile-search-button"), document.querySelectorAll("[data-react = 'my-account-dropdown']")]

            for(let nodes of testArr){
                for(let el of nodes){
                    el.style.display = "none"
                }
            }
        }

        const searchButtonElement = document.querySelector(
            ".top-navigation-actions-container > .rc-search--xs"
        );
        const searchInputElement = document.querySelector(
            ".rc-search_top_menu"
        );
        const topNavigationMenuButtonElement = document.querySelector(
            "button[role=menuitem].rc-menu--xs"
        );
        function hideSearchBar() {
            searchInputElement.style.display = "none";
            searchButtonElement.style.display = "block";
        }
        function showSearchBar() {
            searchInputElement.style.display = "block";
            searchButtonElement.style.display = "none";
        }
        function handleResize() {
            if (window.scrollY > 100 && searchInputElement) {
                hideSearchBar();
            } else {
                showSearchBar();
            }
        }
        handleResize();
        window.addEventListener("scroll", handleResize);
        searchButtonElement.addEventListener("click", showSearchBar);
        topNavigationMenuButtonElement.addEventListener("click", () => {
            if (document.querySelector(".rc-secondary-nav--open")) {
                showSearchBar();
            } else {
                hideSearchBar();
            }
        });

        if(location.href.indexOf("hk")!==-1){
            const testArr = [document.querySelectorAll(".mobile-search-button"), document.querySelectorAll("[data-react = 'my-account-dropdown']")]

            for(let nodes of testArr){
                for(let el of nodes){
                    el.style.display = "none"
                }
            }
        }
    }
}

import BaseComponent from '$Foundation/src/js/BaseComponent';
import GTM from '$Foundation/src/js/gtm/gtmProductDetails';
import intersectionHelper from '$Foundation/src/js/intersection-helper';
import EventEmitter from '$Foundation/src/js/EventEmitter';

export default class ProductTrack extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'product-track';
    }

    setVariables() {
        this.trackDetails = this.el.getAttribute('data-product-details');
        this.trackDetailsParsed = JSON.parse(this.trackDetails);
        this.trackEventClick = 'eComProductClick';
        this.trackEventImpression = 'eComProductImpression';
        this.handleGettingInViewport = this.handleGettingInViewport.bind(this);
        this.isImpressionTracked = false;
        this.impressionTrackingDelay = 1000;
    }

    addListeners() {
        this.addListener(this.el, 'click', e => this.handleClick(e));
        EventEmitter.subscribe('productListTracked:started', () => {
            this.impressionTrackingDelay = 3000;
        });
        EventEmitter.subscribe('productListTracked:stopped', () => {
            this.impressionTrackingDelay = 0;
        });
    }

    init() {
        this.setVariables();
        this.addListeners();
        // impression track - track when element is in viewport
        setTimeout(() => {
            this.isImpressionTracked = this.el.classList.contains('is-impression-tracked');
            if (!this.isImpressionTracked) {
                intersectionHelper.enterViewportOnce(
                    this.el,
                    this.handleGettingInViewport.bind(this),
                    {
                        threshold: 1
                    }
                );
            }
        }, this.impressionTrackingDelay);
    }

    handleClick() {
        // e.preventDefault(); // temp for testing (to avoid jumping to another page)
        GTM.push(this.trackDetailsParsed, this.trackEventClick, 'click');
    }

    handleGettingInViewport() {
        GTM.push(this.trackDetailsParsed, this.trackEventImpression, 'impression');
    }
}

const callbackName = 'onRecatpchaLoad';

let _isLoaded = null;
const onloadQueue = [];

window[callbackName] = () => {
    _isLoaded = true;
    onloadQueue.forEach(callback => callback(window.grecaptcha));
};

function insertScript(lang) {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = `https://www.google.com/recaptcha/api.js?onload=${callbackName}&render=explicit&hl=${lang}`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
}

function isLoaded(lang) {
    if (_isLoaded === null) {
        _isLoaded = false;
        insertScript(lang);
    }
    return _isLoaded;
}

export default function onLoad(callback, lang) {
    if (isLoaded(lang)) {
        callback(window.grecaptcha);
    } else {
        onloadQueue.push(callback);
    }
}

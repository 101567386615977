import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class AbstractFilterControl extends BaseComponent {
    constructor(el, id) {
        super(el);
        this.el = el;
        this.id = id;
    }

    clear() {}

    isActive() {}

    getValue() {}

    getLabel() {}

    getCategory() {}

    setValue() {}
}

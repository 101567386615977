import BaseComponent from '$Foundation/src/js/BaseComponent';
import Utils from '$Foundation/src/js/utils';

export default class ServerValidation extends BaseComponent {
    constructor(el) {
        super(el);
        this.fieldErrorMessage = this.el.querySelector('[data-ref="field-server-error-message"');
        this.fieldInput = this.el.querySelector('[data-ref="field-server-error-input"');
        this.fieldWrapper = this.el.querySelector('[data-ref="field-server-error-input-wrapper"');
        this.inputHappened = false;
        this.errorClassName = 'input-with-error-message';

        this.init();
    }

    static get tagName() {
        return 'field-server-error';
    }
    
    init() {
        if (!this.fieldErrorMessage || !this.fieldWrapper) return;
        Utils.classWatcher(this.fieldInput, 'focus-visible', () => {
            this.onFieldUpdate();
        }, null);
    }

    onFieldUpdate() {
        if (this.inputHappened) return;
        this.inputHappened = true;
        this.fieldWrapper.classList.remove(this.errorClassName);
        this.fieldErrorMessage.classList.add('rc-hidden');
    }
}

import { camelizeString } from '$Foundation/src/js/utils';

class GTM {
    push(trackDetails, event = 'eventTrack', trackType) {

        const siteId = document.body.dataset.siteId;
        const eventCamelized = camelizeString(event);
        const eventFull = siteId + eventCamelized;
        
        if (window.dataLayer) {

            const resultObject = {};

            switch(trackType) {
                case 'click':                
                    Object.assign(resultObject,
                        {
                            event: eventFull
                        },
                        {
                            ecommerce: {
                                click: {
                                actionField: { list: trackDetails.list },
                                products: [{      // can only contain one product
                                    name: trackDetails.name,
                                    id: trackDetails.id,
                                    price: undefined,
                                    brand: trackDetails.brand,
                                    category: trackDetails.category,
                                    variant: undefined,
                                    position: trackDetails.position
                                }]
                                }
                            }
                        });
                    break;

                case 'impression':                
                    Object.assign(resultObject,
                        {
                            event: eventFull
                        },
                        {
                            ecommerce: {
                                impressions: [
                                    {      // can only contain one product
                                    name: trackDetails.name,
                                    id: trackDetails.id,
                                    price: undefined,
                                    brand: trackDetails.brand,
                                    category: trackDetails.category,
                                    list: trackDetails.list,
                                    variant: undefined,
                                    position: trackDetails.position
                                    } 
                                ]                                
                            }
                        });
                    break;
                case 'impressionList':
                    const productList = [...trackDetails];                    
                    let products = [];
                    productList.forEach((item)  => {
                        let product = {
                            name: item.name,
                            id: item.id,
                            price: undefined,
                            brand: item.brand,
                            category: item.category,
                            list: item.list,
                            variant: undefined,
                            position: item.position
                        }
                        products = [...products, product];
                    });                   

                    Object.assign(resultObject,
                        {
                            event: eventFull
                        },
                        {
                            ecommerce: {
                                impressions: products
                            }
                        });
                    break;
                default:
                    break;
            }
            
            window.dataLayer.push(resultObject);
        }
    }
}

export default new GTM();

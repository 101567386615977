import BaseComponent from '$Foundation/src/js/BaseComponent';
import './styles.scss';

export default class StockistsLandingPage extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'stockists-landing-page';
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.initializeAutocomplete();
        if (typeof google !== 'object' || typeof google.maps !== 'object') {
            this.loadGoogleMap();
        }
    }

    setVariables() {
        this.isInited = true;
        this.WTBLandingPage = this.el.querySelectorAll('[data-ref="wtb-landing-page"]');
        this.WTBMapRetailers = this.el.getAttribute('data-wtb-map-retailers');
        this.WTBMapVets = this.el.getAttribute('data-wtb-map-vets');
        this.WTBOnlineRetailers = this.el.getAttribute('data-wtb-map-online-retailers');
        this.landingPageLocationBtn = this.el.querySelector('[data-ref="location-btn"]');
        this.landingPageOnlineRetailersBtn = this.el.querySelector(
            '[data-ref="online-retailers-btn"]'
        );
        this.currentCulture = this.el.querySelector('[data-control="filter-places-search"').getAttribute('data-search-current-culture');
        this.stockistButton = this.el.querySelector('[data-ref="stockist-submit"]');
        this.stockistType = this.stockistButton && this.stockistButton.getAttribute('data-stockist-type');
        this.stockistId = this.stockistButton && this.stockistButton.getAttribute('data-contact-type');
        this.searchFieldNode = this.el.querySelector('[data-ref="filter-input"]');
        this.searchButtonNode = this.el.querySelector('[data-ref="filter-search-btn"]');
        this.hasDownBeenPressed = false;
    }

    addListeners() {
        this.addListener(this.landingPageOnlineRetailersBtn, 'click', (e) =>
            this.handleOnlineRetailersRedirect(e)
        );
        this.addListener(this.landingPageLocationBtn, 'click', (e) =>
            this.handleLocationPermissions(e)
        );
        this.addListener(this.searchButtonNode, 'click', e => this.handlePlacesSearch(e));
        this.addListener(this.searchFieldNode, 'keydown', e => this.handleKeyDown(e));
        this.addListener(this.searchFieldNode, 'focus', () => this.handleSearchFieldFocus());
    }

    initializeAutocomplete() {
        const cultures = JSON.parse(this.currentCulture);
        const options = {
            componentRestrictions: { country: cultures }
        };

        const interval = setInterval(() => {
            if (typeof google === 'object' && typeof google.maps === 'object') {

                this.autocomplete = new window.google.maps.places.Autocomplete(this.searchFieldNode, {
                    options
                });

                this.autocomplete.setFields(['address_components', 'geometry', 'name']);
                window.google.maps.event.addListener(
                    this.autocomplete,
                    'place_changed',
                    this.handlePlaceChanged.bind(this)
                );
                clearInterval(interval);
            }
        }, 250);

    }

    handlePlacesSearch(e) {
        e.preventDefault();
        if (!this.hasDownBeenPressed) {
            const event = document.createEvent('Event');
            event.keyCode = 13;
            event.which = 13;
            event.key = 13;
            event.initEvent('keydown', true, true, false, 13);
            this.searchFieldNode.dispatchEvent(event);
        }
    }

    handlePlaceChanged() {
        const place = this.autocomplete.getPlace();

        if (typeof place.address_components !== 'undefined') {
            this.hasDownBeenPressed = false;
        }

        const placeName = place.name;
        const location = place.geometry ? place.geometry.location : null;

        const latitudeParam = 'latitude=' + location.lat();
        const longitudeParam = 'longitude=' + location.lng();
        const searchQuery = 'searchQuery=' + placeName;
        const urlParams = `${searchQuery}&${latitudeParam}&${longitudeParam}`;
        this.handleWTBRedirect(urlParams);
    }

    loadGoogleMap() {
        const gmapApiKey =
            window.initialWebsiteConfig && window.initialWebsiteConfig.googleMapApiKey
                ? window.initialWebsiteConfig.googleMapApiKey
                : '';

        const mapscript = document.createElement('script');
        mapscript.type = 'text/javascript';
        mapscript.async = true;
        mapscript.src = `//maps.googleapis.com/maps/api/js?key=${gmapApiKey}&callback=gmapInitialize&libraries=places`;
        document.body.appendChild(mapscript);
    }

    handleSearchFieldFocus() {
        this.hasDownBeenPressed = false;
    }

    handleKeyDown(e) {
        if (e.keyCode === 40) {
            this.hasDownBeenPressed = true;
        } else if (e.keyCode ===13) {
            e.preventDefault();
        }
    }

    handleWTBRedirect(urlParams) {
        const stockistId = 'contacttypes=' + this.stockistId;
        const urlParamsPresent = urlParams ? `&${urlParams}` : '';
        const sortResults = 'sortresultsby=1';
        if (this.stockistType === 'retailer') {
            window.location.assign(`${this.WTBMapRetailers}?${stockistId}&${sortResults}${urlParamsPresent}`);
        } else {
            window.location.assign(`${this.WTBMapVets}?${stockistId}&${sortResults}${urlParamsPresent}`);
        }
    }

    handleOnlineRetailersRedirect(e) {
        const url = `${this.WTBOnlineRetailers}`;
        e.preventDefault();
        window.location.assign(url);
    }

    handleLocationPermissions(e) {
        e.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitudeParam = 'currentLatitude=' + position.coords.latitude;
                    const longitudeParam = 'currentLongitude=' + position.coords.longitude;
                    const urlParams = `${latitudeParam}&${longitudeParam}&location=true`;
                    this.handleWTBRedirect(urlParams)
                },
                (error) => {
                    console.log('error', error),
                    this.handleWTBRedirect();
                }
            );
        } else {
            console.log('Geolocation is not supported by this browser');
        }
    }
}

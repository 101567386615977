import BaseComponent from "$Foundation/src/js/BaseComponent";
import "./styles.scss";

export default class HomepageCarousel extends BaseComponent {
    constructor(el) {
        super(el);

        this.init();
    }

    static get tagName() {
        return "homepage-carousel";
    }

    init() {
        window.addEventListener("load", () => {
            this.startInterval();
        });
        this.el.addEventListener("mouseover", () => {
            this.stopInterval();
        });
        this.el.addEventListener("mouseout", () => {
            this.startInterval();
        });
        this.addCarouselTracking();
    }

    startInterval() {
        this.carouselSlideInterval = setInterval(
            () => this.changeSlide(),
            5000
        );
    }

    trackCarouselSlideCTAClick(btnEl) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: "carouselInteraction",
            carouselInteraction: {
                name: "buttonClick",
                buttonLabel: btnEl.target.innerText,
                carousselType: "Homepage",
            },
        });
    }

    trackCarouselNavigationClick() {
        const currentActiveSlideIndex = document.querySelector(
            '[data-component="homepage-carousel"] .tns-nav button.tns-nav-active'
        );
        const currentActiveSlideTitle = document.querySelector(
            '[data-component="homepage-carousel"] .tns-item.tns-slide-active .rc-hero__title'
        );

        if (currentActiveSlideIndex && currentActiveSlideTitle) {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                event: "carouselInteraction",
                carouselInteraction: {
                    name: "slideLoad",
                    slideId:
                        parseInt(
                            currentActiveSlideIndex.getAttribute("data-nav")
                        ) + 1,
                    slideTitle: currentActiveSlideTitle.textContent,
                    carousselType: "Homepage",
                },
            });
        }
    }

    addCarouselTracking() {
        const navigationButtons = document.querySelectorAll(
            '[data-component="homepage-carousel"] [data-controls="next"], [data-component="homepage-carousel"] [data-controls="prev"], [data-component="homepage-carousel"] .tns-nav button'
        );

        const contentButtons = document.querySelectorAll(
            '[data-component="homepage-carousel"] .rc-hero.tns-item .rc-btn'
        );

        for (let i = 0; i < navigationButtons.length; i++) {
            navigationButtons[i].addEventListener(
                "click",
                this.trackCarouselNavigationClick
            );
        }

        for (let i = 0; i < contentButtons.length; i++) {
            contentButtons[i].addEventListener(
                "click",
                this.trackCarouselSlideCTAClick
            );
        }
    }

    removeCarouselTracking() {
        const navigationButtons = document.querySelectorAll(
            '[data-component="homepage-carousel"] [data-controls="next"], [data-component="homepage-carousel"] [data-controls="prev"], [data-component="homepage-carousel"] .tns-nav button'
        );

        const contentButtons = document.querySelectorAll(
            '[data-component="homepage-carousel"] .rc-hero.tns-item .rc-btn'
        );

        for (let i = 0; i < navigationButtons.length; i++) {
            navigationButtons[i].removeEventListener(
                "click",
                this.trackCarouselNavigationClick
            );
        }

        for (let i = 0; i < contentButtons.length; i++) {
            contentButtons[i].removeEventListener(
                "click",
                this.trackCarouselSlideCTAClick
            );
        }
    }

    changeSlide() {
        const nextButtons = document.querySelectorAll(
            '[data-component="homepage-carousel"] [data-controls="next"]'
        );
        const firstSlideButtons = document.querySelectorAll(
            '[data-component="homepage-carousel"] .tns-nav [data-nav="0"]'
        );

        const handleClickEvent = (event) => {
            event.stopPropagation();
        };

        this.removeCarouselTracking();
        if (nextButtons && firstSlideButtons) {
            for (let i = 0; i < nextButtons.length; i++) {
                if (nextButtons[i].disabled == true) {
                    firstSlideButtons[i].parentElement.addEventListener(
                        "click",
                        handleClickEvent
                    );
                    firstSlideButtons[i].click();
                    firstSlideButtons[i].parentElement.removeEventListener(
                        "click",
                        handleClickEvent
                    );
                } else {
                    nextButtons[i].parentElement.addEventListener(
                        "click",
                        handleClickEvent
                    );
                    nextButtons[i].click();
                    nextButtons[i].parentElement.removeEventListener(
                        "click",
                        handleClickEvent
                    );
                }
            }
        }
        this.addCarouselTracking();
    }

    stopInterval() {
        clearInterval(this.carouselSlideInterval);
    }
}

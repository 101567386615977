import "./styles.scss";
import BaseComponent from "$Foundation/src/js/BaseComponent";
import dLScriptsObserver from "$Foundation/src/js/dLScriptsObserver";

export default class ContentCards extends BaseComponent {
    constructor(el) {
        super(el);
        dLScriptsObserver.onLoad(() => {
            this.init();
        });
    }

    static get tagName() {
        return "content-cards";
    }

    setVariables() {
        this.cardButtons = this.el.querySelectorAll(".content-card-btn a");
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    addListeners() {
        this.cardButtons.forEach((el) => {
            this.addListener(el, "click", (e) => this.onBtnClick(e, el));
        });
    }

    onBtnClick(e, el) {
        let dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push({
            event: "contentCardClick",
            contentCardClick: {
                itemName: el.innerText,
            },
        });
    }
}

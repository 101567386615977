// Validation errors messages for window.Parsley && window.Parsley
// Load this after window.Parsley && window.Parsley

window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('sk', {
    dateiso:  "Prosím zadajte dátum vo formáte RRRR-MM-DD.",
    minwords: "Prosím zadajte hodnotu dlhú %s slov a viacej.",
    maxwords: "Prosím zadajte hodnotu kratšiu ako %s slov.",
    words:    "Prosím zadajte hodnotu medzi %s a %s slov.",
    gt:       "Táto hodnota musí byť väčšia.",
    gte:      "Táto hodnota musí byť väčšia alebo rovná.",
    lt:       "Táto hodnota musí byť menšia.",
    lte:      "Táto hodnota musí byť menšia alebo rovná."
  });
});

import BaseComponent from '$Foundation/src/js/BaseComponent';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';
import GTM from '$Foundation/src/js/gtm/gtm';
import Utils from '$Foundation/src/js/utils';

export default class CarouselEventTracker extends BaseComponent {
    constructor(el) {
        super(el);
        this.trackCategory = null;
        this.carouselInstance = null;
        this.onIndexChanged = this.onIndexChanged.bind(this);
        dLScriptsObserver.onLoad(() => {
            this.init();
        });
    }

    static get tagName() {
        return 'carousel-event-tracker';
    }

    init() {
        const DLObject = Utils.getDLObject();

        Object.keys(DLObject.features.Carousel.instance).forEach((i) => {
            const instance = DLObject.features.Carousel.instance[i];
            if (instance!=null && instance.isOn) {
                const container = instance.getInfo().container;
                if (
                    this.carouselInstance === null &&
                    (container === this.el || this.el.contains(container))
                ) {
                    this.carouselInstance = instance;
                }
            }
        });

        if (this.carouselInstance) {
            this.trackCategory = this.el.getAttribute('data-track-category');
            if (this.trackCategory && this.getPagesCount(this.carouselInstance.getInfo()) > 1) {
                this.carouselInstance.events.on('indexChanged', this.onIndexChanged);
            }
        }
    }

    getPagesCount(info) {
        return info.pages;
    }

    onIndexChanged(info) {
        const trackEvent = this.trackCategory + ' ' + this.getTrackLabel(info);
        GTM.push(this.trackCategory, 'Scroll', this.getTrackLabel(info), trackEvent);
    }

    getTrackLabel(info) {
        return `${this.getCurrentPage(info)} of ${this.getPagesCount(info)}`;
    }

    getCurrentPage(info) {
        if (info.index === 0) {
            return 1;
        }
        return Math.ceil((info.index / info.items)) + 1;
    }

    onDestroy() {
        this.trackCategory = null;
        this.carouselInstance = null;
        this.onIndexChanged = null;
        this.el = null;
    }
}

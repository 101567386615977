import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import ViewportInfo from '$Foundation/src/js/ViewportInfo';
import MoveTo from 'moveto';

export default class Timeline extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'timeline';
    }

    setVariables() {
        this.nav = this.el.querySelector('[data-ref="timeline-nav"]');
        this.navLinks = [...this.el.querySelectorAll('[data-ref="timeline-link"]')];
        this.sections = [...this.el.querySelectorAll('[data-ref="timeline-section"]')];
        this.content = this.el.querySelector('[data-ref="timeline-content"]');
    }

    addListeners() {
        this.navLinks.forEach((link) => {
            this.addListener(link, 'click', e => this.onLinkClick(e, link));
        });
    }

    init() {
        if (ViewportInfo.isTouch) return;
        this.setVariables();
        this.addListeners();
        this.initTimeline();
    }

    initTimeline() {
        window.onscroll = () => {
            this.updateTimeline();
        };
    }

    updateTimeline() {
        this.onTimelineVisible();
        this.onSectionsVisible();
        this.onSectionVisible();
    }

    onTimelineVisible() {
        // show/hide timeline navigation when timeline is shown
        if (
            this.el.offsetTop - window.innerHeight / 2 < window.pageYOffset &&
            this.el.offsetTop + this.el.offsetHeight - window.innerHeight / 2 > window.pageYOffset
        ) {
            this.nav.classList.add('nav-active');
        } else {
            this.nav.classList.remove('nav-active');
        }
    }

    onSectionsVisible() {
        // add/remove class to nav when sections are shown
        if (
            this.content.offsetTop - window.innerHeight / 2 < window.pageYOffset &&
            this.content.offsetTop + this.content.offsetHeight - window.innerHeight / 2 >
                window.pageYOffset
        ) {
            this.nav.classList.add('sections-active');
        } else {
            this.nav.classList.remove('sections-active');
        }
    }

    onSectionVisible() {
        // add/remove class to nav link when particular section is active
        // if the top of the section passed middle of the screen
        // and its bottom didn't pass the middle of the screen

        this.sections.forEach((item) => {
            const sectionName = item.getAttribute('id');
            if (
                item.offsetTop - window.innerHeight / 2 < window.pageYOffset &&
                item.offsetTop + item.offsetHeight - window.innerHeight / 2 > window.pageYOffset
            ) {
                /* find proper nav item and make it active */
                this.navLinks.forEach((link) => {
                    if (link.getAttribute('data-id') === sectionName) {
                        link.classList.add('section-active');
                    }
                });
            } else {
                /* find proper nav item and make it unactive */
                this.navLinks.forEach((link) => {
                    if (link.getAttribute('data-id') === sectionName) {
                        link.classList.remove('section-active');
                    }
                });
            }
        });
    }

    onLinkClick(e, link) {
        e.preventDefault();
        const targetName = link.hash.substr(1);
        const targetNode = this.el.querySelector('#' + targetName);
        const header = document.querySelector('[data-js-header-scroll]');
        let headerOffset = header.getBoundingClientRect().height;
        const distanceToTop = targetNode.getBoundingClientRect().top;
        const headerReducingValue = 40;

        // distance from top depends on header height at the moment, when the page is scrolled to top, header is full height
        if (distanceToTop > 0) {
            headerOffset -= headerReducingValue;
        }
        const moveTo = new MoveTo({
            tolerance: headerOffset
        });
        moveTo.move(targetNode);
    }
}

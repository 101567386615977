import { camelizeString } from '$Foundation/src/js/utils';

class GTM {
    push(category, action, label, event = 'eventTrack', customerType, customerName) {
        const siteId = document.body.dataset.siteId;
        const eventCamelized = camelizeString(event);
        const eventFull = siteId + eventCamelized;
        const customer = {};
        let isStockistDataEmpty = true;

        const collectStockistData = (customerTypeValue, customerNameValue) => {
            if (customerTypeValue) {
                Object.assign(customer, { type: customerTypeValue });
                isStockistDataEmpty = false;
            }
            if (customerName) {
                Object.assign(customer, { name: customerNameValue });
                isStockistDataEmpty = false;
            }
        }

        if (window.dataLayer) {

            const resultObject = {};

            Object.assign(resultObject,
                {
                    event: eventFull
                },
                {
                    interaction: {
                        category,
                        action,
                        label,
                        value: 1
                    }
                }
            );

            collectStockistData(customerType, customerName);
            if (!isStockistDataEmpty) {
                Object.assign(resultObject, { customer });
            }
            window.dataLayer.push(resultObject);
        }
    }
}

export default new GTM();

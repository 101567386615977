import './styles.scss';

import BaseComponent from '$Foundation/src/js/BaseComponent';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';
import spriteRed from "../../../../../../front/assets/royal-canin.sprite-brand1-xs.svg";

export default class ArticleReadingTime extends BaseComponent {
    constructor(el) {
        super(el);
        dLScriptsObserver.onLoad(() => {
            this.init();
        });
    }

    static get tagName() {
        return 'reading-time';
    }

    init() {
        this.setVariables();
        this.addIcon();
    }

    setVariables() {
        this.readingTimeIcon = this.el.querySelector('.reading-time-icon');
    }

    addIcon() {
        this.readingTimeIcon.style.backgroundImage = `url('${spriteRed}')`;
    }
}

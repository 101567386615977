import axios from 'axios';

const instance = axios.create({
    timeout: 30000,
    headers: { 'X-Requested-With': 'XMLHttpRequest' }
});

instance.interceptors.response.use(response => response, error => Promise.reject(error));

export default instance;

export const CancelToken = axios.CancelToken;
export const isCancel = axios.isCancel;

import BaseComponent from '$Foundation/src/js/BaseComponent';
import EventEmitter from '$Foundation/src/js/EventEmitter';

export default class RegionLinks extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'region-link';
    }

    setVariables() {
        this.regionList = this.el.getAttribute('data-region-name');
        this.regionListArray=[];
        this.manualRegionCookieName = 'ManualRegion';
        this.browserRegionCookieName = 'BrowserRegion';
        this.serverRegionCookieName = 'ServerRegion';
        this.visibilityClass = 'rc-hidden';
    }

    addListeners() {      
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.initRegionLinks();
    }

    initRegionLinks() {        
        this.getRegionListArray();
        const currentRegion = this.getCurrentRegion();

        if (this.regionListArray.indexOf(currentRegion) > -1) {
            this.el.classList.remove('rc-hidden');            
        }

        EventEmitter.subscribe('region:get', this.updateRegionlinks.bind(this));
    }

    getRegionListArray() {
        this.regionListArray = this.regionList.split(', ');
    }

    getCurrentRegion() {
        let currentRegion = '';
        
        let manualRegionCookie = this.getCookie(this.manualRegionCookieName);
        let browserRegionCookie = this.getCookie(this.browserRegionCookieName);
        let serverRegionCookie = this.getCookie(this.serverRegionCookieName);

        if (manualRegionCookie !== undefined) {
            currentRegion = decodeURIComponent(manualRegionCookie);
        } else { 
            if (browserRegionCookie !== undefined) {
                currentRegion = decodeURIComponent(browserRegionCookie);
            } else { 
                if (serverRegionCookie !== undefined && serverRegionCookie !== 'No detect geolocation'){
                currentRegion = decodeURIComponent(serverRegionCookie);
                }
            }
        }        
        return currentRegion;
    }

    updateRegionlinks() {
        let currentRegion;
        let browserRegionCookie = this.getCookie(this.browserRegionCookieName);

        if (browserRegionCookie !== undefined) {
            currentRegion = decodeURIComponent(browserRegionCookie);
        }    

        if (this.regionListArray.indexOf(currentRegion) > -1) {
            this.el.classList.remove('rc-hidden');            
        }
      
    }

    getCookie(name) {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

}
import "./styles.scss";
import BaseComponent from "$Foundation/src/js/BaseComponent";
import Utils from "$Foundation/src/js/utils";

export default class CommerceButton extends BaseComponent {
  constructor(el) {
    super(el);
    this.init();
    this.isInited = true;
  }

  static get tagName() {
    return "commerce-button";
  }

  setVariables() {
    this.elseSpan = document.querySelector(".buy-now-opt");
    this.customBuyNow = document.querySelector(".styled-custom-buy-now");
    this.sizeButtons = [...this.el.querySelectorAll('[data-ref="size"]')];
    this.shopButtons = [
      ...document.querySelectorAll('[data-ccid="wtb-target"]'),
    ];
    this.PSButtons = [...document.querySelectorAll('[data-ccid="ps-widget"]')];
    this.recipe = [...this.el.querySelectorAll('[data-ref="recipe"]')];
    this.recipePackSize = [
      ...this.el.querySelectorAll('[data-ref="pack-size"]'),
    ];
    this.checkedSize = null;
    this.vetProducts = document.getElementsByClassName("vet-product");
    this.retailProducts = document.getElementsByClassName("retail-global-ean");

    const jsonScriptNode = document.getElementById("cc-widget-settings-json");
    this.cciWidgetSettingsData = jsonScriptNode
      ? JSON.parse(jsonScriptNode.innerHTML).commerceConnectorWidgetSettings
      : null;
  }

  addListeners() {
    this.sizeButtons.forEach((item) => {
      this.addListener(item, "change", (e) => this.onRadioChange(e, item));
    });
    this.recipe.forEach((item) => {
      this.addListener(item, "change", (e) =>
        this.onRadioChangeRecipe(e, item)
      );
    });
  }

  init() {
    this.setVariables();
    this.addListeners();
    this.sizeButtons.length > 0 && this.initButtonsOnLoad();
    this.recipe.length > 0 && this.initRecipesOnLoad();
    this.initCommerceTracking();
    this.initCommerceButtonPlaceholder();
    this.cciWidgetSettingsData && this.checkGoogleAnalyticsLoaded();

    if (this.customBuyNow) {
      this.waitForElm('[data-ccid="wtb-inline-button"]').then(() => {
        this.showButtonAndElse(
          this.shopButtons[0].querySelectorAll("a").length
        );
      });
    } else {
      this.elseSpan.style.display = "block";
    }
  }

  waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  showButtonAndElse(isShown) {
    if (isShown) {
      this.elseSpan.style.display = "block";
      this.customBuyNow.style.display = "block";
    } else {
      this.elseSpan.style.display = "none";
      this.customBuyNow.style.display = "none";
    }
  }

  // remove placeholder button after Buy Now button finished loading
  initCommerceButtonPlaceholder() {
    const targetId = document.querySelector('[id^="commerce-button"]').id;
    const targetNode = document.getElementById(targetId);
    const placeholderButton = document.getElementById(
      "placeholder-commerce-button"
    );
    const config = { attributes: true, childList: true, subtree: true };
    const callback = function (mutationsList) {
      // Use traditional 'for loops' for IE 11
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          placeholderButton && placeholderButton.remove();
          console.log("A child node has been added or removed.");
        }
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }

  initButtonsOnLoad() {
    let checkedEan;

    this.sizeButtons.forEach((item) => {
      checkedEan = item.checked ? item.getAttribute("data-ean-id") : checkedEan;
      this.checkedSize = item.checked
        ? item.getAttribute("value")
        : this.checkedSize;
    });

    this.shopButtons.forEach((item) => {
      let ean = item.getAttribute("data-ean");
      let globalEANCode =
        this.cciWidgetSettingsData && this.cciWidgetSettingsData.eanCode;
      if (this.vetProducts.length > 0) {
        if (ean !== globalEANCode) {
          item.classList.add("rc-hidden");
        }
      } else if (this.retailProducts.length > 0) {
        if (ean !== globalEANCode) {
          item.classList.add("rc-hidden");
        }
      } else {
        if (ean !== checkedEan) {
          item.classList.add("rc-hidden");
        }
      }
    });

    this.PSButtons.forEach((item) => {
      let ean = item.getAttribute("data-ean");
      if (ean !== checkedEan) {
        item.classList.add("rc-hidden");
      }

      console.log("initial")

      document.addEventListener("DOMContentLoaded", function(event) { 
        const buyButtonSelector = document.querySelectorAll(".styled-custom-buy-now");
        if (document.querySelectorAll(".styled-custom-buy-now .rc-hidden").length > 0){buyButtonSelector[0].style.display="none"}
        console.log("final")
      });
    });


    document
      .querySelector("h1")
      .setAttribute("data-checked-size", this.checkedSize);

  }

  initRecipesOnLoad() {
    let checkedEan;
    let checkedItem;

    this.recipe.forEach((item) => {
      checkedEan = item.checked ? item.getAttribute("data-ean-id") : checkedEan;
    });

    this.recipePackSize.forEach((container) => {
      const current = container.getAttribute("data-ean-id");
      if (current !== checkedEan) {
        container.classList.add("rc-hidden");
      }
    });

    this.sizeButtons.forEach((size) => {
      const currentSizeTarget = size.getAttribute("data-ref-target");
      checkedItem = size.checked
        ? size.getAttribute("data-ean-id")
        : checkedItem;
      if (checkedEan === currentSizeTarget) {
        this.shopButtons.forEach((button) => {
          const curEan = button.getAttribute("data-ean");
          if (curEan !== checkedItem) {
            button.classList.add("rc-hidden");
          } else {
            button.classList.remove("rc-hidden");
          }
        });
      }
    });
  }

  checkGoogleAnalyticsLoaded() {
    let timer = Date.now();
    let checkIntervalInMilliseconds = 200;
    let maxMillisecondsToWait = 4000;

    // check once every checkIntervalInMilliseconds if GA script loaded for maxMillisecondsToWait
    // if it loaded in maxMillisecondsToWait then get client ID from GA and add it in the CC widget script
    let interval = setInterval(() => {
      if (Date.now() - timer > maxMillisecondsToWait) {
        clearInterval(interval);
      } else {
        if (typeof window.ga === "function") {
          window.ga(() => {
            let clientId = "";

            let trackers = window.ga.getAll();
            for (var i = 0; i < trackers.length; ++i) {
              var tracker = trackers[i];

              if (tracker) {
                clientId = tracker.get("clientId");
                break;
              }
            }

            this.addCommerceConnectorScriptWidget(clientId);
          });
        }
      }
    }, checkIntervalInMilliseconds);

    // if GA is blocked by AdBlocker or did not load in maxMillisecondsToWait
    // add the CC widget script anyway without the client ID
    setTimeout(() => {
      this.addCommerceConnectorScriptWidget();
    }, maxMillisecondsToWait + 50);
  }

  addCommerceConnectorScriptWidget(clientId) {
    let scriptContainer = document.getElementById("cc-script-container");

    if (scriptContainer.innerHTML.length > 0) {
      return;
    }

    let script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "cci-widget";
    script.src = this.cciWidgetSettingsData.scriptSrc;
    script.setAttribute("data-token", this.cciWidgetSettingsData.token);
    script.setAttribute("data-locale", this.cciWidgetSettingsData.locale);
    script.setAttribute(
      "data-displaylanguage",
      this.cciWidgetSettingsData.displayLanguage
    );
    script.setAttribute("data-widgetid", this.cciWidgetSettingsData.widgetId);
    script.setAttribute("data-ean", this.cciWidgetSettingsData.eanCode);
    script.setAttribute("data-subid", this.cciWidgetSettingsData.subID);
    script.setAttribute(
      "data-trackingid",
      clientId ? this.cciWidgetSettingsData.trackingPrefix + clientId : ""
    );

    scriptContainer.appendChild(script);
  }

  onRadioChangeRecipe(e, item) {
    const checkedEan = item.getAttribute("data-ean-id");
    const recipeURL = item.getAttribute("data-product-url");
    this.checkedSize = item.getAttribute("value");
    let checkedItem;

    recipeURL && history.replaceState(null, "", recipeURL);

    this.recipePackSize.forEach((container) => {
      const current = container.getAttribute("data-ean-id");
      if (current !== checkedEan) {
        container.classList.add("rc-hidden");
      } else {
        container.classList.remove("rc-hidden");
      }
    });

    // display PS button based on selected pack size
    this.sizeButtons.forEach((size) => {
      const currentSizeTarget = size.getAttribute("data-ref-target");
      checkedItem = size.checked
        ? size.getAttribute("data-ean-id")
        : checkedItem;
      if (checkedEan === currentSizeTarget) {
        this.shopButtons.forEach((button) => {
          const curEan = button.getAttribute("data-ean");
          if (curEan !== checkedItem) {
            button.classList.add("rc-hidden");
          } else {
            button.classList.remove("rc-hidden");
          }
        });
      }
    });

    // display product ingredients based on selected recipe
    document
      .querySelectorAll('[data-ref="product-specific-details"]')
      .forEach((x) => {
        const current = x.getAttribute("data-ean-id");
        if (current !== checkedEan) {
          x.classList.add("rc-hidden");
        } else if (current === checkedEan) {
          x.classList.remove("rc-hidden");
        }
      });
  }

  onRadioChange(e, item) {
    const checkedEan = item.getAttribute("data-ean-id");
    this.checkedSize = item.getAttribute("value");
    document
      .querySelector("h1")
      .setAttribute("data-checked-size", this.checkedSize);

    this.shopButtons.forEach((button) => {
      const curEan = button.getAttribute("data-ean");
      if (curEan !== checkedEan) {
        button.classList.add("rc-hidden");
      } else {
        button.classList.remove("rc-hidden");
        this.showButtonAndElse(button.querySelectorAll("a").length);
      }
    });
    this.PSButtons.forEach((button) => {
      const curEan = button.getAttribute("data-ean");
      if (curEan !== checkedEan) {
        button.classList.add("rc-hidden");
      } else {
        button.classList.remove("rc-hidden");
      }
    });
  }

  initCommerceTracking() {
    window.CCEventTrackingCallback = function (eventType, eventContext) {
      const siteId = document.body.getAttribute("data-site-id");
      const productId = document
        .querySelector("h1")
        .getAttribute("data-product-id");
      const productSize = document
        .querySelector("h1")
        .getAttribute("data-checked-size");

      if (eventType === "wtb_button_click") {
        var dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push({
          event: siteId + "productPageBuyNow",
          interaction: {
            category: "Buy Now",
            action: "Click",
            label: "CommerceConnector",
          },
        });
      }

      if (eventType === "shop_clickout") {
        //push Data Layer event - {{site.id}} to be replaced with context site.id
        var dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push({
          event: siteId + "dealerFinderEretailerClick",
          product: {
            id: productId, //Main item ID of the context product page
            variant: productSize, //currently selected pack size
          },
          interaction: {
            category: undefined,
            action: undefined,
            label: eventContext.shop_name, //shop name from widget event context
          },
          component: {
            title: "CommerceConnector",
          },
        });
      }
    };
  }
}

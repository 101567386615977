import BaseComponent from '$Foundation/src/js/BaseComponent';
import { config } from '../../../index';
import FilterMapBridge from './filterMapBridge';

export default class FilterMapControl extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'filter-map';
    }

    static get constructorName() {
        return 'filterMapControl';
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.initMap();
    }

    setVariables() {
        this.isInited = true;
        this.mediator = null;
        this.mapBridge = null;
        this.jsonMapDefaultData = null;
        this.options = {};
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    addListeners() {}

    initMap() {
        const coords = this.el.getAttribute('data-coords').split(',');
        const zoom = this.el.getAttribute('data-zoom')
            ? parseInt(this.el.getAttribute('data-zoom'), 10)
            : 10;

        this.options = {
            center: {
                lat: window.parseFloat(coords[0]),
                lng: window.parseFloat(coords[1])
            },
            zoom
        };

        this.mapBridge = new FilterMapBridge(this.el, this.options);

        this.jsonMapDefaultData = this.getData();

        this.mapBridge.initApi().then(() => {
            this.mapBridge.initMap();

            if (this.jsonMapDefaultData) {
                config.state.pins.data = this.jsonMapDefaultData.stockists;
                this.mapBridge.afterMapLoaded(this.jsonMapDefaultData);
            }
        });
    }

    getData() {
        const jsonScriptNode = this.el.querySelector('[data-ref="json-map-data"]');
        const jsonData = jsonScriptNode ? JSON.parse(jsonScriptNode.innerHTML) : null;
        return jsonData;
    }
}

const PF_LOCAL_STORAGE = 'pfls';

const getStateFromLocalStorage = () => {
    const localStorageValue = localStorage.getItem(PF_LOCAL_STORAGE);
    return localStorageValue ? JSON.parse(localStorageValue) : null;
};

const setStateToLocalStorage = (data) => {
    localStorage.setItem(PF_LOCAL_STORAGE, JSON.stringify(data));
};

const removeLocalStorage = () => {
    localStorage.removeItem(PF_LOCAL_STORAGE);
}

// this function is copied from Utils but it's updated so it's possible to sent empty params
const convertToQuery = (data, endPointUrl) => {
    const isUrlWithParameters = endPointUrl && endPointUrl.indexOf('?') > -1;
    const query = Object.keys(data)
        .filter(key => data[key] !== null)
        .map(key => key + '=' + data[key])
        .join('&');
    const queryString = isUrlWithParameters ? '&' + query : '?' + query;
    return query.length > 0 ? queryString : '';
};

export default  {
    getStateFromLocalStorage,
    setStateToLocalStorage,
    removeLocalStorage,
    convertToQuery
};
// Extra validation errors messages for window.Parsley && window.Parsley
// Load this after window.Parsley && window.Parsley
window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('sr', {
    dateiso:  "Unesite validan datum u formatu YYYY-MM-DD.",
    minwords: "Potrebno je da unesete %s ili više reči.",
    maxwords: "Moguće je uneti maksimalno %s reči.",
    words:    "Potrebno je da unesete između %s i %s reči.",
    gt:       "Ova vrednost mora da bude veća.",
    gte:      "Ova vrednost mora da bude veća ili jednaka.",
    lt:       "Ova vrednost mora da bude manja.",
    lte:      "Ova vrednost mora da bude manja ili jednaka.",
    notequalto: "Sadržaj ovog polja mora biti različit."
  });
});

import styled from "styled-components";

const DropdownWrapper = styled.div`
  position: fixed;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
  width: 80vw;
  max-width: 330px;
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 3px;

  .register-container {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 1.5rem;

    & > div {
      margin-top: 2rem;
    }

    &_log-in-btn {
      max-width: 100%;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: #d7d7d7;
  }

  .external-links {
    width: 100%;
    font-weight: 500;
    padding: 1.5rem;

    & > a {
      text-decoration: none;
      display: block;
      width: 100%;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
`;

export default DropdownWrapper;

import MoveTo from "moveto";
import api from "$Foundation/src/js/api";
import { EVENT_ON_ERROR } from "../recaptcha/index";
import Utils, { createEvent, createElement } from "$Foundation/src/js/utils";
import GTM from "$Foundation/src/js/gtm/gtmContactUs";
import BaseComponent from "$Foundation/src/js/BaseComponent";
import eventEmitter from "$Foundation/src/js/EventEmitter";

import { localeList } from "./parlsey-localization";

const ENCTYPE_URLENCODED = "application/x-www-form-urlencoded";
const ENCTYPE_MULTIPART = "multipart/form-data";
const FORM_CONTROL_ATTRIBUTE = "data-form-control";
const PARSLEY_SERVER_CONSTRAINT_NAME = "server";
const isForm = document.getElementById("is-form") !== null;

// removed scripts from webpack bundle and loaded them via CDN since they're used only in the Form
if (isForm) {
    const jQueryScript = document.createElement("script");
    jQueryScript.setAttribute("type", "text/javascript");
    jQueryScript.async = false;
    jQueryScript.setAttribute(
        "src",
        "https://ajax.googleapis.com/ajax/libs/jquery/3.7.0/jquery.min.js"
    );
    document.head.appendChild(jQueryScript);

    const parsleyJsScript = document.createElement("script");
    parsleyJsScript.setAttribute("type", "text/javascript");
    parsleyJsScript.async = false;
    parsleyJsScript.setAttribute(
        "src",
        "https://cdnjs.cloudflare.com/ajax/libs/parsley.js/2.9.2/parsley.min.js"
    );
    document.head.appendChild(parsleyJsScript);

    const jQuerySerializeJsonScript = document.createElement("script");
    jQuerySerializeJsonScript.setAttribute("type", "text/javascript");
    jQuerySerializeJsonScript.async = false;
    jQuerySerializeJsonScript.setAttribute(
        "src",
        "https://cdnjs.cloudflare.com/ajax/libs/jquery.serializeJSON/2.9.0/jquery.serializejson.min.js"
    );
    document.head.appendChild(jQuerySerializeJsonScript);
}

export default class FormComponent extends BaseComponent {
    constructor(el) {
        super(el);
        // wait for all scripts to load
        window.addEventListener("load", () => {
            if (isForm) {
                this.element = el;
                this.isSubmiting = false;
                this.isSubmitAttempted = false;
                this.formControlSelector = `[${FORM_CONTROL_ATTRIBUTE}]`;
                this.preloader = this.el.querySelector(
                    '[data-ref="preloader"]'
                );

                this.onSubmit = this.onSubmit.bind(this);
                this.onFieldError = this.onFieldError.bind(this);
                this.sendFormTracking = this.sendFormTracking.bind(this);
                this.onSubmitAttempt = this.onSubmitAttempt.bind(this);
                this.init(true);
                this.isInited = true;
                this.fieldInput = this.el.querySelector(
                    '[data-ref="field-server-error-input"'
                );
            }
        });
    }

    static get tagName() {
        return "form";
    }

    init(first = false) {
        this._parsleyForm = null;
        this._trackCategory = null;
        this._trackAction = null;
        this._trackLabel = null;
        this._trackEvent = null;
        this._trackUserCity = null;
        this._trackUserType = null;
        this.isFormTrackingSended = false;

        this.form = this.el.querySelector("form");
        this.$form = window.$(this.form);
        this.method = this.form.method || "post";
        this.action = this.form.action;
        this.enctype = this.form.enctype || ENCTYPE_URLENCODED;

        const DLOBject = Utils.getDLObject();

        if (this.form.nodeName !== "FORM") {
            throw new Error("element must be a form");
        }

        this.submitElement = this.form.querySelector('[data-ref="submit"]');

        this.formName = this.el.getAttribute("data-form-name");

        if (first) {
            this.form.addEventListener("submit", this.onSubmitAttempt);
        }

        if (this.isAsync()) {
            this.getParsleyForm().on("form:submit", this.onSubmit);
        }

        this.getParsleyForm().on("field:error", this.onFieldError);

        this.applyServerValidation();
        if (Utils.isEukanuba()) {
            this.hideBreederInputPlaceholder();
            this.handleValidationInput();
        }

        if (!first) {
            this.getParsleyForm().validate();
        }

        if (this.isMustBeTracked()) {
            this.setUpFormTracking();
        }

        if (DLOBject.features.Selects) {
            this.initSelects();
        } else {
            const interval = setInterval(() => {
                if (DLOBject.features.Selects) {
                    this.initSelects();
                    clearInterval(interval);
                }
            }, 500);
        }

        if (this.formName === "Pet Expert Booking") {
            const checkboxes = this.el.querySelectorAll(".rc-input__checkbox");

            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].required = true;
            }

            this.el
                .querySelector("button[type=submit]")
                .addEventListener("click", () => {
                    if (!Array.from(checkboxes).every((c) => c.checked)) {
                        document.querySelector(
                            ".rc-form-submit-show-display-error"
                        ).style.display = "flex";
                    }
                });
        }
    }

    hideBreederInputPlaceholder() {
        const container = document.getElementsByClassName(
            "euka-input__control"
        );
        container &&
            container.forEach((element) => {
                element.addEventListener("input", (event) => {
                    const placeholderElement =
                        event.currentTarget.nextElementSibling;
                    const lengthInput = element.value.length;
                    if (placeholderElement) {
                        placeholderElement.childNodes[0].style.visibility =
                            "hidden";
                    }
                    if (lengthInput == 0) {
                        placeholderElement.childNodes[0].style.visibility =
                            "visible";
                    }
                });
            });
    }

    handleValidationInput() {
        console.log("handle validation input");
        const container = document.getElementsByClassName(
            "euka-input__validation-message"
        );
        console.log("container", container);
        for (let i = 0; i <= container.length; i++) {
            console.log(container[i], "  container[i]");
            container[i].classList.remove("filled");
        }
    }

    onSubmitAttempt() {
        this.isSubmitAttempted = true;
    }

    initSelects() {
        const selectInstancesWithinContainer = [
            ...this.el.querySelectorAll("[data-js-select]"),
        ];
        const DLObject = Utils.getDLObject();

        selectInstancesWithinContainer.map((instance) => {
            DLObject.features.Selects.init("[data-js-select]", instance, null);
        });

        const customOptions = {
            shouldSort: false,
        };
        const customSelectInstancesWithinContainer = [
            ...this.el.querySelectorAll("[data-js-select-no-sort]"),
        ];
        customSelectInstancesWithinContainer.map((instance) => {
            DLObject.features.Selects.init(
                "[data-js-select-no-sort]",
                instance,
                customOptions
            );
        });
    }

    applyServerValidation() {
        this.getParsleyForm().fields.forEach((field) => {
            const errors = this.getFieldServerErrors(field);
            if (errors.length > 0) {
                // programmatically add constraint (not public Parsley method)
                field.addConstraint(PARSLEY_SERVER_CONSTRAINT_NAME);

                // programmatically set constraint error text
                field.options.serverMessage = errors.join(", ");

                if (!FormComponent.hasFieldValue(field)) {
                    field.options.validateIfEmpty = true;
                }

                if (FormComponent.isCaptchaField(field)) {
                    field.element.dispatchEvent(createEvent(EVENT_ON_ERROR));
                }

                // reset ui for apply new trigger rule
                field.reset();
                field.validate();
            }
        });
    }

    getFieldServerErrors(field) {
        const element = field.$element[0];
        const serverErrorsString = element.getAttribute("data-server-errors");
        if (serverErrorsString) {
            try {
                return JSON.parse(serverErrorsString);
            } catch (error) {
                console.log(error);
            }
        }
        return [];
    }

    getCurrentLanguage() {
        const html = document.querySelector("html");
        const lang = html.getAttribute("lang");
        const language = lang.includes("pt")
            ? lang.toLowerCase()
            : lang.slice(0, lang.indexOf("-"));

        return language;
    }

    isParsleyLocaleExist(locale) {
        return localeList.includes(locale);
    }

    setLocale() {
        const locale = this.getCurrentLanguage();
        if (this.isParsleyLocaleExist(locale)) {
            window.Parsley.setLocale(locale);
        } else {
            window.Parsley.setLocale("en");
        }
    }

    getParsleyForm() {
        const DLClass = Utils.getDLClass();
        const inputValidationClass = `${DLClass}-input__validation-message`;
        if (this._parsleyForm === null) {
            this.setLocale();
            this._parsleyForm = new window.Parsley.Factory(this.form, {
                inputs: `${window.Parsley.options.inputs},[data-parsley-checkboxes-group]`,
                // exclude hidden, disabled but keep custom selects included
                excluded: (index, element) => {
                    const $element = window.$(element);
                    if ($element.is("[data-js-select]")) {
                        return false;
                    }
                    return $element.is(
                        "input[type=button], input[type=submit], input[type=reset], input[type=hidden], :disabled"
                    );
                },
                errorClass: `${DLClass}-input--error`,
                successClass: `${DLClass}-input--success`,
                classHandler: (field) =>
                    field.$element.closest(this.formControlSelector),
                errorsContainer: (field) =>
                    field.$element.closest(this.formControlSelector),
                errorsWrapper: `<span class=${inputValidationClass}></span>`,
                errorTemplate: "<span></span>",
                trigger: "input",
                validationThreshold: 0,
            });
        }
        return this._parsleyForm;
    }

    isMustBeTracked() {
        const formName = this.element.getAttribute("data-form-name");

        return (
            formName.includes("Contact Us") && this.getTrackCategory() !== null
        );
    }

    getTrackCategory() {
        if (this._trackCategory === null) {
            this._trackCategory =
                this.element.getAttribute("data-track-category") ||
                this.element.getAttribute("data-form-name");
        }
        return this._trackCategory;
    }

    getTrackAction() {
        if (this._trackAction === null) {
            this._trackAction = this.element.getAttribute("data-track-action");
        }
        return this._trackAction;
    }

    getTrackLabel() {
        if (this._trackLabel === null) {
            this._trackLabel = this.element.getAttribute("data-track-label");
        }
        return this._trackLabel;
    }

    getTrackEvent() {
        this._trackEvent = this.getTrackCategory();
        return this._trackEvent;
    }

    getTrackUserCity() {
        this._trackUserCity = this.el.querySelector("[data-track-city]");
        if (!this._trackUserCity) return this._trackUserCity;
        return this._trackUserCity.value;
    }

    getTrackUserType() {
        this._trackUserType = this.el.querySelector("[data-track-type]");
        if (!this._trackUserType) return this._trackUserType;
        this._trackUserTypeValue =
            this._trackUserType.options[
                this._trackUserType.selectedIndex
            ].value;
        return this._trackUserTypeValue;
    }

    isAsync() {
        return this.form.getAttribute("data-ajax") === "true";
    }

    onSubmit() {
        if (!this.isSubmiting) {
            this.isSubmiting = true;
            try {
                this.beforeSubmit();
                api({
                    method: this.method,
                    url: this.getUrl(),
                    data: this.getRequestParams(),
                }).then(
                    (response) => {
                        // Shouldn't be here, dirty fix before rewrite
                        if (this.formName === "Pet Expert Booking") {
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                event: "petExpertBookingConfirmation",
                            });
                        }
                        this.afterSubmit();
                        return this.handleSuccessSubmit(response);
                    },
                    (error) => {
                        this.afterSubmit();
                        return this.onError(error);
                    }
                );
            } catch (err) {
                console.error(err);
            }
        }

        // prevent default form submit
        return false;
    }

    handleSuccessSubmit(response) {
        this.handleDestroy();
        this.element.innerHTML = "";
        this.element.insertAdjacentElement(
            "beforeend",
            this.getResultElementFromResponse(response)
        );
        eventEmitter.emit("content:updated", this.el);
        this.init();
        this.onSuccess(response);
    }

    getResultElementFromResponse(response) {
        let resultElement = createElement(response.data);
        const form =
            resultElement.children[0] &&
            resultElement.children[0].nodeName === "FORM"
                ? resultElement.children[0]
                : null;
        if (form) {
            resultElement = form;
        }

        return resultElement;
    }

    getRequestParams() {
        let data = null;
        switch (this.enctype) {
            case ENCTYPE_URLENCODED:
                data = this.getUrlencodedFormData();
                break;
            case ENCTYPE_MULTIPART:
                data = this.getMultipartFormData();
                break;
            default:
                throw new Error("Unknown form enctype");
        }

        return data;
    }

    getUrlencodedFormData() {
        const dataObject = this.$form.serializeJSON({
            checkboxUncheckedValue: "false",
            parseBooleans: true,
            useIntKeysAsArrayIndex: true,
        });
        return window.$.param(dataObject);
    }

    getMultipartFormData() {
        const { name } = this.submitElement;
        let data = name ? [{ name, value: this.submitElement.value }] : [];

        data = data.concat(window.$(this.form).serializeArray());

        data.push({ name: "X-Requested-With", value: "XMLHttpRequest" });

        const formData = new FormData();
        data.forEach((v) => {
            formData.append(v.name, v.value);
        });

        const emptyRadioListButtonData = this.getEmptyRadioListsData();
        emptyRadioListButtonData.forEach((kv) => {
            formData.append(kv.k, kv.v);
        });

        return formData;
    }

    getEmptyRadioListsData() {
        const radioInputs = [
            ...this.form.querySelectorAll('input[type="radio"]'),
        ];

        const groupBy = (xs, keyGetter) =>
            xs.reduce((rv, x) => {
                const key = keyGetter(x);
                (rv[key] = rv[key] || []).push(x);
                return rv;
            }, {});

        const groupMap = groupBy(radioInputs, (r) => r.name);
        return Object.keys(groupMap)
            .map((name) => {
                const val = groupMap[name].reduce((result, rb) => {
                    result = result || (rb.checked ? rb.value : "");
                    return result;
                }, "");
                return { k: name, v: val };
            })
            .filter((o) => o.v.length === 0);
    }

    getUrl() {
        return this.action;
    }

    setUpFormTracking() {
        this.getParsleyForm().on("form:success", this.sendFormTracking);
    }

    sendFormTracking() {
        if (!this.isFormTrackingSended) {
            this.isFormTrackingSended = true;
            const trackAction = "Form submmit";
            const trackLabel = "Success";
            const trackEvent = this.getTrackEvent();
            GTM.push(
                this.getTrackCategory(),
                trackAction,
                trackLabel,
                trackEvent,
                this.getTrackUserCity(),
                this.getTrackUserType()
            );
        }
    }

    getSubmitTrackingDetails() {}

    onSubmitClick() {
        // hook for descendants
    }

    onBeforeSubmit() {
        // hook for descendants
        // can be used for showing preloader

        const errorMessage = this.el.querySelector(
            ".rc-form-submit-show-display-error"
        );

        if (errorMessage) {
            errorMessage.remove();
        }
    }

    // onSuccess(result) {}
    onSuccess() {
        // hook for descendants
    }

    // onError(error) {}
    onError(error) {
        // hook for descendants
        GTM.push(
            this.formName,
            "submit",
            `error ${error}`,
            `${this.formName} submit: error`
        );
    }

    onAfterSubmit() {
        // hook for descendants
        // can be used for hiding preloader
        if (this.isMustBeTracked()) {
            this.sendFormTracking();
        }
        this.scrollElIntoView();
    }

    onAfterReset() {
        // hook for descendants
    }

    afterSubmit() {
        this.hidePreloader();
        if (this.submitElement) {
            this.submitElement.disabled = false;
        }
        this.isSubmiting = false;
        this.onAfterSubmit();
    }

    beforeSubmit() {
        this.showPreloader();
        if (this.submitElement) {
            this.submitElement.disabled = true;
        }
        this.onBeforeSubmit();
    }

    handleDestroy() {
        this.getParsleyForm().destroy();
        eventEmitter.emit("content:willRemove", this.form);
    }

    onDestroy() {
        this.handleDestroy();
    }

    onFieldError(event) {
        // a11y fix
        event.$element.attr("aria-describedby", event._ui.errorsWrapperId);

        if (event.$element[0].hasAttribute("data-parsley-splitdate")) {
            [...event.$element[0].children].forEach((childNode) => {
                childNode.setAttribute(
                    "aria-describedby",
                    event._ui.errorsWrapperId
                );
            });
        }
    }

    showPreloader() {
        this.preloader.classList.add("is-active");
    }

    hidePreloader() {
        this.preloader.classList.remove("is-active");
    }

    scrollElIntoView() {
        if (window.pageYOffset > this.el.offsetTop) {
            setTimeout(() => {
                const header = document.querySelector(
                    "[data-js-header-scroll]"
                );
                const headerOffset = header.getBoundingClientRect().height;

                const moveTo = new MoveTo({
                    tolerance: headerOffset,
                });
                const target = this.element;
                moveTo.move(target);
            }, 100);
        }
    }

    static isCaptchaField(field) {
        return "recaptcha" in field.constraintsByName;
    }

    static hasFieldValue(field) {
        return (
            (field.element.tagName === "INPUT" &&
                field.element.type !== "file") ||
            field.element.tagName === "TEXTAREA" ||
            field.element.tagName === "SELECT"
        );
    }
}

// wait for script load
window.addEventListener("load", () => {
    if (isForm) {
        window.Parsley &&
            window.Parsley.addValidator("recaptcha", {
                validateString: (value) => /\S/.test(value),
                priority: 512,
                messages: {
                    en: "Please confirm that you are not a robot",
                },
            });

        window.Parsley &&
            window.Parsley.addValidator(PARSLEY_SERVER_CONSTRAINT_NAME, {
                validateString: (value, c, field) => {
                    // if field cant store value we show error just once
                    if (!FormComponent.hasFieldValue(field)) {
                        if (field.serverErrorValue !== true) {
                            field.serverErrorValue = true;
                            return false;
                        }

                        return true;
                    }

                    // otherwise field is valid only if new value do not matches old one
                    if ("serverErrorValue" in field) {
                        return field.serverErrorValue !== field.$element.val();
                    }

                    field.serverErrorValue = field.$element.val();
                    return false;
                },
                requirementType: "string",
                priority: 1024,
            });

        window.Parsley &&
            window.Parsley.addValidator("checkboxesGroup", {
                validateMultiple: (values) => values.length > 0,
                priority: 512,
            });
    }
});
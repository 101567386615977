// Validation errors messages for window.Parsley && window.Parsley
// Load this after window.Parsley && window.Parsley

window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('ro', {
    dateiso:    "Trebuie să fie o dată corectă (YYYY-MM-DD).",
    minwords:   "Textul e prea scurt. Trebuie să aibă cel puțin %s cuvinte.",
    maxwords:   "Textul e prea lung. Trebuie să aibă cel mult %s cuvinte.",
    words:      "Textul trebuie să aibă cel puțin %s și cel mult %s caractere.",
    gt:         "Valoarea ar trebui să fie mai mare.",
    gte:        "Valoarea ar trebui să fie mai mare sau egală.",
    lt:         "Valoarea ar trebui să fie mai mică.",
    lte:        "Valoarea ar trebui să fie mai mică sau egală.",
    notequalto: "Valoarea ar trebui să fie diferită."
  });
});

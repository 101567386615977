import BaseComponent from '$Foundation/src/js/BaseComponent';
import GTM from '$Foundation/src/js/gtm/gtmProductDetails';
import intersectionHelper from '$Foundation/src/js/intersection-helper';
import EventEmitter from '$Foundation/src/js/EventEmitter';
import PFUtils from '../../../../ProductFinder/code/src/product-finder/js/utils';
export default class ProductListTrack extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'product-list-track';
    }

    setVariables() {
        this.productItems = [...this.el.querySelectorAll('[data-component="product-track"]')];
        this.trackEventImpression = 'eComProductImpression';
        this.trackDetailsArray = [];
    }

    addListeners() {
            if (window.location.href.indexOf("products") != -1) {
                this.addListener(window, 'beforeunload', () => {
                    PFUtils.removeLocalStorage();
                });
            }
    }

    init() {
        this.setVariables();
        this.addListeners();

        EventEmitter.emit('productListTracked:started');

        this.productItems.forEach((item) => {
            setTimeout(() => {
                intersectionHelper.enterViewportOnce(
                    item,
                    this.handleGettingInViewport.bind(this, item),
                    {
                        threshold: 1
                    }
                );
            }, 300);
        });

        setTimeout(() => {
            this.pushGTM();
        }, 1000);
    }

    handleGettingInViewport(item) {
        const trackDetails = item.getAttribute('data-product-details');
        const trackDetailsParsed = JSON.parse(trackDetails);
        this.trackDetailsArray.push(trackDetailsParsed);
        item.classList.add('is-impression-tracked');
    }

    pushGTM() {
        if (!this.trackDetailsArray.length) {
            return;
        }
        GTM.push(this.trackDetailsArray, this.trackEventImpression, 'impressionList');
        this.trackDetailsArray.length = 0;
        EventEmitter.emit('productListTracked:stopped');
    }
}

import { get } from "lodash";
import React from "react";
import ReactDOM from "react-dom";

const bind = (componentPath, domElement) => {
    const props = {};
    Object.keys(domElement.dataset).forEach((key) => {
        if (key.startsWith("prop")) {
            const Key = key.substring(4, key.length);
            const newKey = Key.charAt(0).toLowerCase() + Key.slice(1);
            props[newKey] = domElement.dataset[key];
        }
    });

    const Component = get(window, `RC.components.${componentPath}`);
    if (Component) {
        ReactDOM.render(<Component {...props} />, domElement);
    }
};

export const autobind = () => {
    const targets = Array.from(document.querySelectorAll("[data-react]"));

    targets.forEach((target) => {
        bind(target.dataset.react, target);
    });
};

import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import Utils from '$Foundation/src/js/utils';

export default class MarketPicker extends BaseComponent {
    constructor(el) {
        super(el);        
        this.redirectPage = this.el.getAttribute('data-redirect-page');
        this.countrySelect = document.getElementById('country-select');        

        this.addListeners();
    }

    static get tagName() {
        return 'marketpicker';
    }

    updateRedirectUrl() {
        let languageSelect = document.getElementById('language-select');
        let selectedLanguage = languageSelect.options[languageSelect.selectedIndex].value;
        document.getElementById('redirect-button').setAttribute("href", selectedLanguage + "/" + this.redirectPage);
    }

    updateLanguages() {
        let params = {
            method: 'GET',
            headers: {}
        };
        var _that = this;
        let selectedCountry = this.countrySelect.options[this.countrySelect.selectedIndex].value;        
        fetch(`/api/kittenzooplus/getlanguages` + "?site=" + selectedCountry, params).then(function (response) {
            return response.json();
        })
            .then(function (response) {
                let selectedLanguages = [];
                response.map(y => selectedLanguages.push(y));

                document.getElementById('language-select-container').innerHTML = `
            <span class="rc-select rc-input--label rc-margin-bottom--md--mobile rc-margin-bottom--sm--desktop">
                <label class="rc-select__label" for="language-select">
                    Language
                </label>
                <select data-js-select-language="" class="id-select-language" id="` + `language-select` + `" >
                    <option 
                        selected="" 
                        disabled="" 
                        value="${selectedLanguages[0].Path}"
                    >
                        ${selectedLanguages[0].Name} 
                    </option>
                    ${selectedLanguages.map(
                        (language) =>
                            `<option value="${language.Path}">  
                                ${language.Name}
                            </option>`
                    )}
                </select>
            </span>
            `
                const DLOBject = Utils.getDLObject();
                const languageOptions = {
                    placeholderValue: 'data-js-custom-placeholder',
                    shouldSort: false,
                    itemSelectText: '',
                    classNames: {
                        item: 'choices__item choices__item--custom-data'
                    }
                };
                DLOBject.features.Selects.init('[data-js-select-language]', null, languageOptions);
                _that.updateRedirectUrl();
                document.getElementById('language-select').addEventListener('change', () => {
                    _that.updateRedirectUrl();
                });
            });
    }

    addListeners() {        
        this.countrySelect.addEventListener('change', () => {
            this.updateLanguages();
        });
        document.getElementById('language-select').addEventListener('change', () => {
            this.updateRedirectUrl();                
        });
    }    
}

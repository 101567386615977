import BaseComponent from '$Foundation/src/js/BaseComponent';
import Utils from '$Foundation/src/js/utils';

export default class FiltersList extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
        this.handleFiltersListOpen = this.handleFiltersListOpen.bind(this);
        this.handleFiltersListClose = this.handleFiltersListClose.bind(this);
    }

    static get tagName() {
        return 'filters-list';
    }

    handleFiltersListOpen() {
        const tentantPrefix = Utils.getDLClass();

        document.documentElement.classList.add(`${tentantPrefix}-nav-open`);
    }
    handleFiltersListClose() {
        const tentantPrefix = Utils.getDLClass();

        document.documentElement.classList.remove(`${tentantPrefix}-nav-open`);
    }

    addClassWatcher() {
        const tentantPrefix = Utils.getDLClass();

        Utils.classWatcher(
            this.el,
            `${tentantPrefix}-active`,
            this.handleFiltersListOpen,
            this.handleFiltersListClose
        );
    }

    init() {
        this.addClassWatcher();
    }
}

import BaseComponent from "$Foundation/src/js/BaseComponent";
import Utils from "$Foundation/src/js/utils";
import { config } from "../../index";
import MediatorEvents from "../enums/mediatorEvents";
import MediatorEventModel from "../models/mediatorEventModel";
import FilterCheckboxControl from "./filterCheckboxControl";
import FilterRangeControl from "./filterRangeControl";
import FilterSelectControl from "./filterSelectControl";
import EventEmitter from "$Foundation/src/js/EventEmitter";

export default class FilterPanelControl extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return "filter-panel";
    }

    static get constructorName() {
        return "filterPanelControl";
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.createFilters();
    }

    setVariables() {
        this.isInited = true;
        this.mediator = null;
        this.isActive = false;
        this.filters = [];
        this.filterButtons = [
            ...this.el.querySelectorAll('[data-ref="filter-button"]'),
        ];
        this.closeFilterBtnNode = this.el.querySelector(
            '[data-ref="close-filter-btn"]'
        );
        this.applyFilterBtnNode = this.el.querySelector(
            '[data-ref="apply-filters"]'
        );
        this.checkboxes = [
            ...this.el.querySelectorAll('[data-ref="filter-checkbox"]'),
        ];
        this.clearAllLinkNode = this.el.querySelector(
            '[data-ref="clear-all-filters-link"]'
        );
        this.clearAllBtnNode = this.el.querySelector(
            '[data-ref="clear-all-filters-btn"]'
        );
        this.filterCategoryNodes = [
            ...this.el.querySelectorAll('[data-ref="filter-category"]'),
        ];
        this.filterRangeNodes = [
            ...this.el.querySelectorAll('[data-ref="filter-range"]'),
        ];
        this.filterSelectNodes = [
            ...this.el.querySelectorAll('[data-ref="filter-select"]'),
        ];
        this.filterFacetNodes = [
            ...this.el.querySelectorAll('[data-ref="filter-facet"]'),
        ];
        this.clearCategoryBtnNodes = [
            ...this.el.querySelectorAll(
                '[data-ref="clear-category-filters-btn"]'
            ),
        ];
        this.isHistoryApiEnabled = !!(
            window.history && window.history.pushState
        );
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    addListeners() {
        this.clearCategoryBtnNodes.forEach((item) => {
            this.addListener(item, "click", (e) =>
                this.handleClearCategoryFilters(e)
            );
        });

        this.filterButtons.forEach((button) => {
            this.addListener(button, "click", (e) =>
                this.handleFilterBtnClick(e)
            );
        });

        this.addListener(this.clearAllLinkNode, "click", (e) =>
            this.handleClearAllFilters(e)
        );
        this.addListener(this.clearAllBtnNode, "click", (e) =>
            this.handleClearAllFilters(e)
        );
        this.addListener(this.applyFilterBtnNode, "click", (e) =>
            this.handleApplyFilters(e)
        );
        this.addListener(this.closeFilterBtnNode, "click", () =>
            this.handleCloseFilterClick()
        );

        EventEmitter.subscribe("facets:updated", (data) =>
            this.handleFacetsUpdate(data)
        );
    }

    getAllFilters() {
        return this.filters;
    }

    createFilters() {
        this.createCheckboxes();
        this.createRanges();
        this.createSelects();
    }

    createCheckboxes() {
        this.checkboxes.forEach((checkbox) => {
            const checkboxId = checkbox.getAttribute("id");
            const filter = new FilterCheckboxControl(checkbox, checkboxId);
            filter.setMediator(config.el);
            this.filters.push(filter);
        });
    }

    createRanges() {
        this.filterRangeNodes.forEach((range) => {
            const rangeId = range.getAttribute("id");
            const filter = new FilterRangeControl(range, rangeId);
            this.filters.push(filter);
        });
    }

    createSelects() {
        this.filterSelectNodes.forEach((select) => {
            const selectId = select.getAttribute("id");
            const filter = new FilterSelectControl(select, selectId);
            filter.setMediator(config.el);
            this.filters.push(filter);
        });
    }

    createMediatorEventModel(eventType, data) {
        const mediatorEvent = new MediatorEventModel();
        const urlParams = new URLSearchParams(window.location.search);

        const searchQuery = this.getSearchQuery();

        Object.assign(searchQuery, ...config.requestParameters);
        // searchQuery.searchQuery = config.filterSearchControl.getInputValue();

        if (data && data.viewAll) {
            searchQuery.viewAll = data.viewAll;
        }

        if (urlParams.get('searchQuery')) {
            searchQuery.searchQuery = urlParams.get('searchQuery');
        }

        mediatorEvent.searchQuery = searchQuery;

        const queryString = Utils.convertToQuery(searchQuery);
        mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
        mediatorEvent.eventType = MediatorEvents[`${eventType}`];

        return mediatorEvent;
    }

    handleFilterBtnClick() {
        const mediatorEvent =
            this.createMediatorEventModel("clickFilterButton");
        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    handleFilterChanged(data) {
        const mediatorEvent = this.createMediatorEventModel(
            "filterChanged",
            data
        );
        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    handleClearAllFilters(e) {
        e.preventDefault();
        this.filters.forEach((filter) => {
            filter.clear();
        });

        const mediatorEvent = this.createMediatorEventModel("clearAllFilters");

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    handleClearCategoryFilters(e) {
        e.stopPropagation();
        e.preventDefault();

        const filterCategoryNode = Utils.getClosestNodes(
            e.target,
            '[data-ref="filter-category"]'
        )[0];

        this.filters
            .filter(
                (filter) =>
                    filter.getCategory() ===
                    filterCategoryNode
                        .getAttribute("data-filter-category")
                        .toLowerCase()
            )
            .forEach((filter) => filter.clear());

        const mediatorEvent = this.createMediatorEventModel("filterChanged");

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    handleCloseFilterClick() {
        const mediatorEvent = this.createMediatorEventModel("closeFilters");

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    handleApplyFilters() {
        const mediatorEvent = this.createMediatorEventModel("applyFilters");

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    resetConcreteFilters(id) {
        const filter = this.filters.filter((f) => f.id === id)[0];

        if (filter) {
            filter.clear();
            this.handleFilterChanged();
        }
    }

    updateFilterCategory(data) {
        const { category, value } = data;
        const filters = this.filters.filter(
            (f) => f.getCategory() === category.toLowerCase()
        );
        filters.forEach((filter) => {
            filter.setValue(value);
        });

        this.handleFilterChanged(data);
    }

    updateClearBtnVisibility() {
        const tenantPrefix = Utils.getDLClass();

        this.filterCategoryNodes.forEach((filterCategory) => {
            const clearBtnNode = filterCategory.querySelector(
                '[data-ref="clear-category-filters-btn"]'
            );
            if (
                this.filters
                    .filter(
                        (filter) =>
                            filter.getCategory() ===
                            filterCategory
                                .getAttribute("data-filter-category")
                                .toLowerCase()
                    )
                    .some((f) => f.isActive())
            ) {
                if (clearBtnNode) {
                    clearBtnNode.classList.remove(`${tenantPrefix}-hidden`);
                }
            } else if (clearBtnNode) {
                clearBtnNode.classList.add(`${tenantPrefix}-hidden`);
            }
        });
    }

    updateClearAllLinkVisibility() {
        const tenantPrefix = Utils.getDLClass();

        if (
            this.filters.some(
                (filter) => filter.isActive() && !filter.shouldBeExcluded()
            )
        ) {
            this.clearAllLinkNode.classList.remove(`${tenantPrefix}-hidden`);
        } else if (this.clearAllLinkNode) {
            this.clearAllLinkNode.classList.add(`${tenantPrefix}-hidden`);
        }
    }

    isFilterPanelActive() {
        this.isActive = !this.isActive;
        return this.isActive;
    }

    getSearchQuery() {
        const searchQuery = {};

        this.filters.forEach((filter) => {
            if (filter.isActive()) {
                const value = filter.getValue();
                const category = filter.getCategory();

                if (
                    searchQuery[`${category}`] &&
                    searchQuery[`${category}`].length > 0
                ) {
                    searchQuery[`${category}`].push(`${value}`);
                } else {
                    searchQuery[`${category}`] = [`${value}`];
                }
            }
        });
        return searchQuery;
    }

    handleFacetsUpdate(data) {
        const { facets } = data;

        if (!facets) {
            return;
        }

        this.filterFacetNodes.forEach((facet) => {
            facet.innerHTML = "0";
        });

        Object.keys(facets).forEach((key) => {
            const checkboxNodes = this.checkboxes.filter(
                (checkbox) => checkbox.value === key
            );
            checkboxNodes.forEach((checkbox) => {
                const facet = this.el.querySelector(`[data-facet-id="${key}"]`);
                facet.innerHTML = facets[key];
                const checkboxParent = Utils.getClosestNodes(
                    facet,
                    '[data-ref="filter-checkbox-parent"]'
                )[0];
                checkboxParent.classList.remove("is-disabled");
                checkbox.removeAttribute("disabled");
            });
        });

        this.filterFacetNodes.forEach((facet) => {
            const checkboxParent = Utils.getClosestNodes(
                facet,
                '[data-ref="filter-checkbox-parent"]'
            )[0];
            const checkbox = checkboxParent.querySelector(
                '[data-ref="filter-checkbox"]'
            );
            if (facet.innerHTML === "0" && !checkbox.checked) {
                checkboxParent.classList.add("is-disabled");
                checkbox.setAttribute("disabled", "disabled");
            }
        });
    }

    setUrl(url) {
        if (!this.isHistoryApiEnabled) {
            return false;
        }

        if (url) {
            window.history.replaceState({}, "", url);
        } else {
            const cleanUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
            window.history.replaceState({}, "", cleanUrl);
        }
        return true;
    }

    onAttributeAdded(targetElement, attributeName, onAddedCallback) {
        const observerCallback = (mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (
                    mutation.type === "attributes" &&
                    mutation.attributeName === attributeName &&
                    !!mutation.target.getAttribute(attributeName)
                ) {
                    onAddedCallback();
                    observer.disconnect();
                }
            }
        };

        const attributeObserver = new MutationObserver(observerCallback);

        if (targetElement) {
            attributeObserver.observe(targetElement, { attributes: true });
        }
    }

    collapsePrefilteredCategories() {
        let anyFilterApplied = false;
        const firstFilterCategory = document.getElementById("header-id-0");

        this.filters.forEach((filter) => {
            const categoryId = filter.id.split("-")[2];
            const categoryListHeader = document.getElementById(
                `header-id-${categoryId}`
            );

            if (
                filter.isActive() &&
                categoryListHeader &&
                categoryListHeader.getAttribute("aria-selected") !== "true"
            ) {
                categoryListHeader.click();
                anyFilterApplied = true;
            }
        });

        if (!anyFilterApplied && firstFilterCategory) {
            firstFilterCategory.click();
        }
    }
}

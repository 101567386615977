class IntersectionObserverHelper {
    constructor() {
        // take a fixed header into account
        this.defaultOptions = {
            rootMargin: '-80px 0px 0px 0px'
        };
    }

    enterViewportOnce(element, callback, options = {}) {
        options = { ...this.defaultOptions, ...options };
        const observerInstance = new IntersectionObserver((changes, observer) => {
            const change = changes[0];
            if (change.isIntersecting) {
                observer.disconnect();
                callback(change);
            }
        }, options);
        observerInstance.observe(element);
        return observerInstance;
    }

    enterViewport(element, callback, options = {}) {
        options = { ...this.defaultOptions, ...options };
        const observerInstance = new IntersectionObserver((changes) => {
            const change = changes[0];
            if (change.isIntersecting) {
                callback(change);
            }
        }, options);
        observerInstance.observe(element);
        return observerInstance;
    }
}

const instance = new IntersectionObserverHelper();

export default instance;

import { config } from '../index';
import MediatorEvents from './enums/mediatorEvents';
import ViewportInfo, { DEVICE_TYPE_MOBILE } from '$Foundation/src/js/ViewportInfo';
import { isCancel } from '$Foundation/src/js/api';
import FilterTrackingService from './filterTrackingService';
import Utils from '$Foundation/src/js/utils';

export default class Mediator {
    constructor() {
        this.lastSearchQuery = null;
    }

    getTrackingAction(searchQuery) {
        return searchQuery;
    }

    shouldTriggerTrackingEvent(eventModel) {
        const searchQuery = this.getSearchQueryFromEvent(eventModel);
        return searchQuery && searchQuery !== this.lastSearchQuery;
    }

    storeLastSearchInfo(eventModel) {
        this.lastSearchQuery = this.getSearchQueryFromEvent(eventModel);
    }

    triggerTracking(eventModel) {
        const searchQuery = this.getSearchQueryFromEvent(eventModel);
        const trackCategory = this.getTrackDetailsFromEvent(eventModel).category;
        const trackLabel = this.getTrackDetailsFromEvent(eventModel).label;
        const trackEvent = this.getTrackDetailsFromEvent(eventModel).event;
        const trackBreedId = this.getTrackDetailsFromEvent(eventModel).breedId;
        const trackSpecieId = this.getTrackDetailsFromEvent(eventModel).specieId;
        const trackAge = this.getTrackDetailsFromEvent(eventModel).age;

        FilterTrackingService.triggerTracking(
            this.getTrackingAction(searchQuery),
            trackLabel,
            trackEvent,
            trackBreedId,
            trackSpecieId,
            trackAge,
            trackCategory
        );
    }

    getSearchQueryFromEvent(eventModel) {
        return eventModel.searchQuery.searchQuery;
    }

    getTrackDetailsFromEvent(eventModel) {
        return eventModel.trackDetails;
    }

    stateChanged(eventModel) {
        switch (eventModel.eventType) {
            case MediatorEvents.filterChanged: {
                config.filterListingControl.showPreloader();

                const getListingItems = config.dataLoader.getData(
                    eventModel.eventMethod,
                    eventModel.eventParams,
                    eventModel.eventUrl
                );

                getListingItems.then(
                    (result) => {
                        config.filterListingControl.updateListing(
                            result.data,
                            eventModel.typeOfInsertingContent
                        );
                        const queryString = Utils.convertToQuery(eventModel.searchQuery);
                        config.filterPanelControl.setUrl(queryString);

                        if (config.filterTagsControl) config.filterTagsControl.updateTags();
                        config.filterPanelControl.updateClearBtnVisibility();
                        config.filterPanelControl.updateClearAllLinkVisibility();

                        if (config.filterMapControl) {
                            config.filterMapControl.mapBridge.updateData(result.data, true);
                        }


                        config.filterListingControl.hidePreloader();
                    },
                    (error) => {
                        if (isCancel(error)) {
                            return;
                        }
                        config.filterListingControl.hidePreloader();
                        throw new Error(error);
                    }
                );

                //

                break;
            }

            case MediatorEvents.clearAllFilters: {
                config.filterListingControl.showPreloader();
                const getListingItems = config.dataLoader.getData(
                    eventModel.eventMethod,
                    eventModel.eventParams,
                    eventModel.eventUrl
                );

                getListingItems.then(
                    (result) => {
                        config.filterListingControl.updateListing(
                            result.data,
                            eventModel.typeOfInsertingContent
                        );
                        if (config.filterTagsControl) config.filterTagsControl.updateTags();
                        config.filterPanelControl.updateClearBtnVisibility();
                        config.filterPanelControl.setUrl();
                        config.filterPanelControl.updateClearAllLinkVisibility();
                        if (config.filterMapControl) {
                            config.filterMapControl.mapBridge.updateData(result.data, true);
                        }
                        if (ViewportInfo.deviceTypeByViewport === DEVICE_TYPE_MOBILE) {
                            config.filterPanelControl.handleCloseFilterClick();
                        }
                        config.filterListingControl.hidePreloader();
                    },
                    (error) => {
                        if (isCancel(error)) {
                            return;
                        }
                        config.filterListingControl.hidePreloader();
                        throw new Error(error);
                    }
                );

                break;
            }

            case MediatorEvents.applyFilters: {
                config.filterListingControl.showPreloader();

                const getListingItems = config.dataLoader.getData(
                    eventModel.eventMethod,
                    eventModel.eventParams,
                    eventModel.eventUrl
                );

                getListingItems.then(
                    (result) => {
                        config.filterListingControl.updateListing(
                            result.data,
                            eventModel.typeOfInsertingContent
                        );
                        const queryString = Utils.convertToQuery(eventModel.searchQuery);
                        config.filterPanelControl.updateClearBtnVisibility();
                        config.filterPanelControl.updateClearAllLinkVisibility();
                        config.filterPanelControl.setUrl(queryString);

                        if (config.filterTagsControl) config.filterTagsControl.updateTags();
                        if (config.filterMapControl) {
                            config.filterMapControl.mapBridge.updateData(result.data, true);
                        }
                        config.filterPanelControl.handleCloseFilterClick();
                        config.filterListingControl.hidePreloader();
                    },
                    (error) => {
                        if (isCancel(error)) {
                            return;
                        }
                        config.filterListingControl.hidePreloader();
                        throw new Error(error);
                    }
                );
                break;
            }

            case MediatorEvents.tagRemoved: {
                const id = eventModel.eventData.id;
                config.filterPanelControl.resetConcreteFilters(id);
                break;
            }

            case MediatorEvents.clickFilterButton: {
                const DLObject = Utils.getDLObject();
                config.state.isFilterPanelActive = config.filterPanelControl.isFilterPanelActive();
                config.filterTagsControl.updateVisibility(config.state.isFilterPanelActive);
                DLObject.features.filter('[data-filter-trigger]');
                break;
            }

            case MediatorEvents.closeFilters: {
                config.state.isFilterPanelActive = false;
                config.filterTagsControl.updateVisibility(config.state.isFilterPanelActive);
                break;
            }

            case MediatorEvents.search: {
                config.filterListingControl.showPreloader();

                if (FilterTrackingService.isTrackingEnabled()) {
                    if (this.shouldTriggerTrackingEvent(eventModel)) {
                        this.storeLastSearchInfo(eventModel);
                        this.triggerTracking(eventModel);
                    }
                }

                const getListingItems = config.dataLoader.getData(
                    eventModel.eventMethod,
                    eventModel.eventParams,
                    eventModel.eventUrl
                );

                getListingItems.then(
                    (result) => {
                        config.filterListingControl.updateListing(
                            result.data,
                            eventModel.typeOfInsertingContent
                        );
                        const queryString = Utils.convertToQuery(eventModel.searchQuery);
                        config.filterPanelControl.setUrl(queryString);

                        if (config.filterMapControl) {
                            config.filterMapControl.mapBridge.updateData(result.data, true);
                        }
                        config.filterListingControl.hidePreloader();
                    },
                    (error) => {
                        if (isCancel(error)) {
                            return;
                        }
                        config.filterListingControl.hidePreloader();
                        throw new Error(error);
                    }
                );
                break;
            }

            case MediatorEvents.viewAllBtnClicked: {
                config.filterPanelControl.updateFilterCategory(eventModel.eventData);
                break;
            }

            case MediatorEvents.paginationButtonClicked: {
                config.filterListingControl.showPreloader();

                const getListingItems = config.dataLoader.getData(
                    eventModel.eventMethod,
                    eventModel.eventParams,
                    eventModel.eventUrl
                );

                getListingItems.then(
                    (result) => {
                        config.filterListingControl.updateListing(
                            result.data,
                            eventModel.typeOfInsertingContent
                        );
                        const queryString = Utils.convertToQuery(eventModel.searchQuery);
                        config.filterPanelControl.setUrl(queryString);
                        if (config.filterMapControl) {
                            config.filterMapControl.mapBridge.updateData(result.data, true);
                        }

                        config.filterListingControl.hidePreloader();
                    },
                    (error) => {
                        if (isCancel(error)) {
                            return;
                        }
                        config.filterListingControl.hidePreloader();
                        throw new Error(error);
                    }
                );
                break;
            }

            /**
             * Map mediator events
             */

            case MediatorEvents.mapMarkerClicked: {
                config.filterMapControl.mapBridge.openInfoWindow(eventModel.eventData.pin);
                config.filterListingControl.setActiveItem(eventModel.eventData.pin);
                break;
            }

            case MediatorEvents.mapClicked: {
                config.filterListingControl.resetActiveItems();
                break;
            }

            case MediatorEvents.filterItemClicked: {
                config.filterMapControl.mapBridge.setMarkerActive(eventModel.eventData.pinId);
                config.filterListingControl.setActiveItem(eventModel.eventData.targetPin);
                break;
            }

            case MediatorEvents.filterSelectChanged: {
                config.filterListingControl.showPreloader();

                const getListingItems = config.dataLoader.getData(
                    eventModel.eventMethod,
                    eventModel.eventParams,
                    eventModel.eventUrl
                );

                getListingItems.then(
                    (result) => {
                        config.filterListingControl.updateListing(
                            result.data,
                            eventModel.typeOfInsertingContent
                        );
                        const queryString = Utils.convertToQuery(eventModel.searchQuery);
                        config.filterPanelControl.setUrl(queryString);

                        if (config.filterMapControl) {
                            config.filterMapControl.mapBridge.updateData(result.data, true);
                        }
                        config.filterListingControl.hidePreloader();
                    },
                    (error) => {
                        if (isCancel(error)) {
                            return;
                        }
                        config.filterListingControl.hidePreloader();
                        throw new Error(error);
                    }
                );
                break;
            }

            case MediatorEvents.mapIdleFinished: {
                config.filterListingControl.showPreloader();
                const getListingItems = config.dataLoader.getData(
                    eventModel.eventMethod,
                    eventModel.eventParams,
                    eventModel.eventUrl
                );

                getListingItems.then(
                    (result) => {
                        config.filterListingControl.updateListing(
                            result.data,
                            eventModel.typeOfInsertingContent
                        );
                        if (config.filterMapControl) {
                            config.filterMapControl.mapBridge.updateData(result.data, false);
                        }
                        config.filterListingControl.hidePreloader();
                    },
                    (error) => {
                        if (isCancel(error)) {
                            return;
                        }
                        config.filterListingControl.hidePreloader();
                        throw new Error(error);
                    }
                );
                break;
            }

            case MediatorEvents.getCurrentPosition: {
                config.filterListingControl.showPreloader();
                const getListingItems = config.dataLoader.getData(
                    eventModel.eventMethod,
                    eventModel.eventParams,
                    eventModel.eventUrl
                );

                const IsGeoPositionEnabled = true;

                getListingItems.then(
                    (result) => {
                        config.filterListingControl.updateListing(
                            result.data,
                            eventModel.typeOfInsertingContent
                        );
                        if (config.filterMapControl) {
                            config.filterMapControl.mapBridge.updateData(
                                result.data,
                                true,
                                IsGeoPositionEnabled,
                                eventModel.position
                            );
                        }
                        config.filterListingControl.hidePreloader();
                    },
                    (error) => {
                        if (isCancel(error)) {
                            return;
                        }
                        config.filterListingControl.hidePreloader();
                        throw new Error(error);
                    }
                );
                break;
            }

            default: {
                throw new Error(eventModel.eventType, ' hasn\'t been handled.');
            }
        }
    }
}

import { camelizeString } from '$Foundation/src/js/utils';

class GTM {
    push(category, action, label, id, publishDate, masterName, format, type, event = 'eventTrack') {
        const siteId = document.body.dataset.siteId;
        const eventCamelized = camelizeString(event);
        const eventFull = siteId + eventCamelized; 

        if (window.dataLayer) {

            const resultObject = {};

            Object.assign(resultObject,
                {
                    event: eventFull
                },
                {
                    interaction: {
                        category,
                        action,
                        label
                    }
                },
                {
                    issue: {
                        id,
                        publishDate,
                        masterName,
                        format,
                        type
                    }
                }
            );
            window.dataLayer.push(resultObject);
        }
    }
}

export default new GTM();
// Validation errors messages for window.Parsley && window.Parsley
// Load this after window.Parsley && window.Parsley

window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('sl', {
    dateiso:  "Vnesite datum v ISO obliki (YYYY-MM-DD).",
    minwords: "Vpis je prekratek. Vpisati morate najmnaj %s besed.",
    maxwords: "Vpis je predolg. Vpišete lahko največ %s besed.",
    words:    "Dolžina vpisa je napačna. Dolžina je lahko samo med %s in %s besed.",
    gt:       "Vpisani podatek mora biti večji.",
    gte:      "Vpisani podatek mora biti enak ali večji.",
    lt:       "Vpisani podatek mora biti manjši.",
    lte:      "Vpisani podatek mora biti enak ali manjši.",
    notequalto: "Vpisana vrednost mora biti drugačna."
  });
});

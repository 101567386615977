import BaseComponent from '$Foundation/src/js/BaseComponent';
import GTM from '$Foundation/src/js/gtm/gtm';

export default class LiveChat extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'live-chat-link';
    }

    setVariables() {        
        this.liveChatHelpButtonEnabled = 'helpButtonEnabled';
        this.siteDomain = '';
        this.liveChatUrl = '/contact-us/livechat';
        this.liveChatUrlFull = '';
		this.trackCategory = 'Live-Chat Open / Outbound Link';
        this.trackLabel = null;
        this.trackEvent = null;
    }

    addListeners() {
        this.addListener(this.el, 'click', e => this.openLiveChat(e));
    }

    init() {
        this.setVariables();
        this.addListeners();        
    }

    openLiveChat(e) {        
        e.preventDefault();
        const liveChatRibbon = document.getElementsByClassName(this.liveChatHelpButtonEnabled);
        if (!(typeof liveChatRibbon === undefined || liveChatRibbon[0] === undefined)) {
            liveChatRibbon[0].click();     
        } else {            
            this.siteDomain = document.body.dataset.marketHomeUrl;
            this.liveChatUrlFull = this.siteDomain + this.liveChatUrl;
			this.trackLabel = this.liveChatUrlFull;
            this.trackEvent = this.trackCategory + ' ' + this.trackLabel;
            GTM.push(this.trackCategory, 'Click', this.trackLabel, this.trackEvent);
            window.open(this.liveChatUrlFull, 'popUpWindow', 'height=400,width=390,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');
        }        
    }
}    

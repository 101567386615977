import BaseComponent from '$Foundation/src/js/BaseComponent';
import Utils from '$Foundation/src/js/utils';
import './styles.scss';
import intersectionHelper from '$Foundation/src/js/intersection-helper';

export default class ContentAnimation extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'content-animation';
    }

    setVariables() {
        const DLClass = Utils.getDLClass();

        this.defaultOptions = {
            baseAnimationCSSClass: `${DLClass}-animation-001--base`,
            activateAnimationCSSClass: `${DLClass}-animation-001--active`,
            mobile: true,
            animationDisabled: false
        };

        this.settings = {
            ...this.defaultOptions,
            ...this.el.dataset
        };

        this.animate = this.animate.bind(this);
        this.readMoreButton = this.el.getElementsByClassName('read-more-btn')[0]

    }

    addListeners() {
        if (this.readMoreButton != undefined) {
            this.addListener(this.readMoreButton, 'click', e => {
                this.readMore(e)
            });
        }
    }

    init() {

        this.isInited = true;
        this.setVariables();
        if (this.readMoreButton != undefined) {
        this.initialReadMore();
        }
        this.addListeners();

        if (this.settings.animationDisabled) return;

        setTimeout(() => {
            const isElementIntoView = this.isElementIntoView();
            if (!isElementIntoView) {
                this.applyBaseAnimationStyles();
                intersectionHelper.enterViewportOnce(this.el, this.animate.bind(this), {
                    threshold: 0
                });
            }
        }, 300);
    }

    

    readMore() {
        this.textContainer = this.el.getElementsByClassName('rc-container-text')[0]
        this.paragraph = [...this.textContainer.getElementsByTagName("p")];
        this.div = [...this.textContainer.getElementsByTagName("div")];

        if (this.paragraph.length > 2) {
            if (this.readMoreButton.classList.contains('rc-more')) {
                this.paragraph[0].classList.add('ellipsis');
            }
            else{
                this.paragraph[0].classList.remove('ellipsis');
            }
            this.paragraph.slice(1).forEach(element => {
                if (this.readMoreButton.classList.contains('rc-more')) {
                    element.classList.add('delete')
                }
                else {
                    element.classList.remove('delete')
                }
            })
        }
        else {
            if (this.readMoreButton.classList.contains('rc-more')) {
                this.paragraph[0].classList.add("ellipsis")
                this.paragraph[1].classList.add("single-paragraph")
            }
            else {
                this.paragraph[0].classList.remove("ellipsis")
                this.paragraph[1].classList.remove("single-paragraph")
            }
        }

        // Tagging(Analytics)
        let analyticsInteraction = "Read more";
        if (this.readMoreButton.classList.contains('rc-more')) {
            analyticsInteraction = "Read less";
        }

        const titleElement = this.textContainer && this.textContainer.querySelector("h2");
        if(titleElement && titleElement.dataset.enTitle){
            let analyticsTitle = titleElement.dataset.enTitle;
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event' : 'ReadMoreOrLessButton',
                'component' : {
                    'title' : analyticsTitle,
                    'interaction' : analyticsInteraction
                }
            })
        }
    }

    initialReadMore() {
        this.textContainer = this.el.getElementsByClassName('rc-container-text')[0]
        this.paragraph = [...this.textContainer.getElementsByTagName("p")];
        if (this.paragraph.length === 0) {
            this.readMoreButton.style.display = 'none';
        }
        if (this.paragraph.length > 2) {
            this.paragraph[0].classList.add('ellipsis');
            this.paragraph.slice(1).forEach(element => {
            element.classList.add('delete');
            });
        }
        else {
            if (this.paragraph.length !== 0) {
                this.paragraph[0].classList.add('ellipsis');
                this.paragraph[1].classList.add('single-paragraph');
            }
        }
    }

    isElementIntoView() {
        const windowBottomEdge = window.scrollY + window.innerHeight;
        const elementTopEdge = this.el.offsetTop;
        return elementTopEdge <= windowBottomEdge;
    }

    applyBaseAnimationStyles() {
        this.el.classList.add(this.settings.baseAnimationCSSClass);
    }

    animate() {
        this.el.classList.add(this.settings.activateAnimationCSSClass);
    }
}

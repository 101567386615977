import './scss/recommendations';
import './scss/profile';
import './scss/our-products';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import ViewportInfo, { EVENT_RESIZE_FINISHED, EVENT_DEVICE_TYPE_CHANGED } from '$Foundation/src/js/ViewportInfo';
import EventEmitter from '$Foundation/src/js/EventEmitter';
import PFUtils from '../product-finder/js/utils';
import { events } from '../product-finder/js/enums';

const RC_FADE_CLASS = 'rc-fade--x';
const SUMMARY_HIDDEN_CLASS = 'pf-recommend__summary-hidden';
const SUMMARY_TITLE_OPEN_CLASS = 'pf-recommend__summary-title--open';
const SUMMARY_BORDER_CLOSE_CLASS = 'pf-recommend__summary-border--close';

export default class Recommend extends BaseComponent {
    constructor(el) {
        super(el);
        this.setVariables(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'pf-recommend';
    }

    setVariables() {
        this.scroll = {
            fadeInContainer: this.el.querySelector('[data-ref="fade-in"]'),
            items: [...this.el.querySelectorAll('[data-ref="scroll-item"]')],
            container: this.el.querySelector('[data-ref="scroll-items-container"]')
        };

        this.summary = {
            title: this.el.querySelector('[data-ref="summary-title"]'),
            hiddenItems: [...this.el.querySelectorAll('[data-ref="summary-hidden"]')],
            border: this.el.querySelector('[data-ref="summary-border"]'),
            isOpen: false
        };

        this.startAgainBtn = this.el.querySelector('[data-ref="start-again-btn"]');
     
    }

    addListeners() {
        EventEmitter.subscribe(EVENT_RESIZE_FINISHED, () => {
            this.initFadeScroll();
        });
        EventEmitter.subscribe(EVENT_DEVICE_TYPE_CHANGED, () => {
            this.initSummaryAccordion();
        });
        this.addListener(this.summary.title, 'click', () => this.handleSummaryTitleClick());
        this.addListener(this.startAgainBtn, 'click', e => this.handleStartAgainBtnClick(e));
    }

    init() {
        this.addListeners();
        setTimeout(() => { // give time for lazyload to finish its job
            this.initFadeScroll();
        }, 300);
        this.initSummaryAccordion();

    }

    initFadeScroll() {
        if (!this.scroll.container || !this.scroll.items) return;

        let itemsWidth = 0;
        const itemsContainerWidth = this.scroll.container.offsetWidth;

        this.scroll.items.forEach((item) => {
            itemsWidth += item.offsetWidth;
        });

        if (itemsWidth > itemsContainerWidth) {
            this.scroll.fadeInContainer.classList.add(RC_FADE_CLASS);
        } else {
            this.scroll.fadeInContainer.classList.remove(RC_FADE_CLASS);
        }
    }

    initSummaryAccordion() {
        if (!this.summary.title || !this.summary.hiddenItems) return;

        if (ViewportInfo.isDesktop || this.summary.isOpen) {
            this.summary.hiddenItems.forEach((item) => {
                item.classList.remove(SUMMARY_HIDDEN_CLASS);
            });
            this.summary.border.classList.remove(SUMMARY_BORDER_CLOSE_CLASS);
        } else {
            this.summary.hiddenItems.forEach((item) => {
                item.classList.add(SUMMARY_HIDDEN_CLASS);
            });
            this.summary.border.classList.add(SUMMARY_BORDER_CLOSE_CLASS);
        }
    }

    handleSummaryTitleClick() {
        if (!this.summary.title || !this.summary.hiddenItems || ViewportInfo.isDesktop) return;

        this.summary.hiddenItems.forEach((item) => {
            item.classList.toggle(SUMMARY_HIDDEN_CLASS);
        });
        this.summary.title.classList.toggle(SUMMARY_TITLE_OPEN_CLASS);
        this.summary.border.classList.toggle(SUMMARY_BORDER_CLOSE_CLASS);
        this.summary.isOpen = !this.summary.isOpen;
    }

    handleStartAgainBtnClick(e) {
        e.preventDefault();
        this.clearState();
        EventEmitter.emit(events.STEP_SUBMITTED);
    }

    clearState() {
        PFUtils.removeLocalStorage();
    }
}

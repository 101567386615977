import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import FilterPanelControl from './js/controls/filterPanelControl';
import FilterTagsControl from './js/controls/filterTagsControl';
import FilterListingControl from './js/controls/filterListingControl';
import FilterSearchControl from './js/controls/filterSearchControl';
import FilterPlacesSearchControl from './js/controls/map/filterPlacesSearchControl';
import FilterViewAllBtnControl from './js/controls/filterViewAllBtnControl';
import FilterPaginationControl from './js/controls/filterPaginationControl';
import FilterMapControl from './js/controls/map/filterMapControl';
import Mediator from './js/mediator';
import DataLoader from './js/dataLoader';
import Utils from '$Foundation/src/js/utils';
import EventEmitter from '$Foundation/src/js/EventEmitter';
import MoveTo from 'moveto';
import FilterStockistsControl from './js/controls/filterStockistsControl';

const config = {};

export default class FilterAndListing extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'filter-and-listing';
    }

    setVariables() {
        this.controls = [];
        this.controlInstances = [];

        // Create config
        config.el = this;
        config.mediator = new Mediator();
        config.dataLoader = new DataLoader();
        config.endPointUrl = this.el.getAttribute('data-endpoint');
        config.tracking = {
            category: this.el.getAttribute('data-track-category'),
            action: this.el.getAttribute('data-track-action')
        };

        const dataRequestAttributes = 'data-request-';
        const regex = new RegExp(dataRequestAttributes, 'gi');
        config.requestParameters = [...this.el.attributes]
            .filter(attr => regex.test(attr.name))
            .map(attr => ({ [`${attr.name.substr(dataRequestAttributes.length)}`]: attr.value }));

        this.controls.push(
            FilterMapControl,
            FilterPanelControl,
            FilterStockistsControl,
            FilterTagsControl,
            FilterListingControl,
            FilterSearchControl,
            FilterPaginationControl,
            FilterViewAllBtnControl,
            FilterPlacesSearchControl
        );

        config.state = {
            isfilterPanelActive: false,
            pins: {
                data: [],
                activePin: null
            },
            map: {
                bounds: {},
                latitude: null,
                longitude: null,
                currentLatitude: null,
                currentLongitude: null
            }
        };
        this.stockistsList = this.el.querySelector('[data-control="filter-panel"]');
        this.stockistsMap = this.el.querySelector('.stockist-map');
        this.parentNode = this.el.querySelector('.parent-node');
    }
    handleMobileMap() {
        if (this.isWhereToBuyPage() && this.parentNode 
            && this.stockistsMap && this.stockistsList
            && window.matchMedia('(max-width: 770px)').matches) {
                
            this.parentNode.insertBefore(this.stockistsMap, this.stockistsList)
        }
    }

    addListeners() {
        EventEmitter.subscribe('content:updated', () => {
            this.initControls();
        });

        EventEmitter.subscribe('content:willRemove', (container) => {
            this.destroyControls(container);
        });

        EventEmitter.subscribe('pagination:change', () => {
            this.scrollElIntoView();
        });
    }

    sortResultsByDistance() {
        // set default Sort results by distance for Euka project after page load
        window.addEventListener("load", function () {
            const locateMeBtn = document.querySelector('[data-ref="locate-to-me-btn"]');
            const isEukaWhereToBuyPage = Utils.isEukanuba() && this.isWhereToBuyPage() 
                && window.location.search.length === 0;
            if (isEukaWhereToBuyPage) {
                // timeout needed for button load
                setTimeout(function () {
                    window.EUKA && window.EUKA.click(locateMeBtn)
                }, 2000);
            }
        });
    }

    isWhereToBuyPage() {
        const location = window.location;
        
        return location && location.pathname.split('/')[2] 
            && location.pathname.split('/')[2].includes('where-to-buy');
    }

    init() {
        this.setVariables();
        this.handleMobileMap();
        this.addListeners();
        this.initControls();
        this.sortResultsByDistance();
    }

    initControls(container) {
        this.controls.forEach((Control) => {
            this.initControl(container, Control);
        });
    }

    initControl(container = this.el, Control) {
        const controlName = Control.tagName;

        if (typeof controlName !== 'string') {
            throw new Error(' tagName is empty', Control);
        }

        const controlNodes = [...container.querySelectorAll(`[data-control~="${controlName}"]`)];

        controlNodes.forEach((controlNode) => {
            if (!controlNode.components) {
                controlNode.components = [];
            }

            let allowToInit = true;

            controlNode.components.forEach((instanse) => {
                if (instanse.constructor.tagName === controlName && instanse.isInited) {
                    allowToInit = false;
                }
            });

            if (allowToInit) {
                const controlInstance = new Control(controlNode);
                controlNode.components.push(controlInstance);
                this.controlInstances.push(controlInstance);
                const controlInstanceName = controlInstance.constructor.constructorName;
                config[controlInstanceName] = controlInstance;
                config[controlInstanceName].setMediator(this);
            }
        });
    }

    destroyControls(container = this.el) {
        this.controls.forEach((Control) => {
            const controlName = Control.tagName;

            if (typeof controlName !== 'string') {
                throw new Error(' tagName is empty', Control);
            }

            const controlNodes = [
                ...container.querySelectorAll(`[data-control~="${controlName}"]`)
            ];

            controlNodes.forEach((controlNode) => {
                if (!controlNode.components || !controlNode.components.length) {
                    return;
                }

                controlNode.components.forEach((instance) => {
                    instance.destroy();
                    instance.isDestroyed = true;
                });

                this.controlInstances = this.controlInstances.filter(x => !x.isDestroyed);
            });
        });
    }

    scrollElIntoView() {
        if (window.pageYOffset > this.el.offsetTop) {
            setTimeout(() => {
                const header = document.querySelector('[data-js-header-scroll]');
                const headerOffset = header.getBoundingClientRect().height;

                const moveTo = new MoveTo({
                    tolerance: headerOffset
                });
                const target = this.el;
                moveTo.move(target);
            }, 100);
        }
    }
}

export { config };

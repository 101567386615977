import './styles.scss';
import GTM from '$Foundation/src/js/gtm/gtm';
import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class VetFocusSocialLinks extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'social-links';
    }

    setVariables() {
        this.socialLinks = this.el.querySelectorAll('[data-ref="social-link"]');
    }

    addListeners() {
        this.socialLinks.forEach(link => {
            link.addEventListener('click', e => this.pushGTM(e));
        });
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.initContentBlock();
    }

    initContentBlock() {
        console.log(`Module inited: social-links`);
    }
    
    pushGTM(e) {
        const category = e.currentTarget.getAttribute('data-track-category');
        const action = e.currentTarget.getAttribute('data-track-action');
        const label = e.currentTarget.getAttribute('data-track-label');
        GTM.push(category, action, label, 'socialShare');
    }
}

import React, { useEffect, useState, } from "react";
import is from "is_js";
import { useWindowSize, } from "rooks";
import Wrapper from "./styled";
import BreakPoints from "../../utils/break-points";

const ArticleCard = ({
  href,
  editorialFormat,
  heading,
  summary,
  imgSrc,
  imgSrcSet,
  imgAlt,
},) => {
  const { innerWidth: windowWidth, } = useWindowSize();

  const showOnly = (str, n,) => {
    if (!is.string(str,) || str.length <= n) return str;
    return `${str.substring(0, n,)}...`;
  };

  const [
    isVertical,
    setIsVertical,
  ] = useState(false,);

  useEffect(() => {
    setIsVertical(windowWidth < Number(BreakPoints.S,),);
  }, [
    windowWidth,
  ],);

  return (
    <Wrapper className="article-card" href={href} isVertical={isVertical}>
      <div className="article-card__inner">
        <div className="article-card__image-wrapper">
          <img
            src={imgSrc}
            alt={imgAlt}
            srcSet={imgSrcSet}
            sizes="auto"
          />
        </div>
        <div className="article-card__content-wrapper">
          <div className="article-card__content-wrapper__editorial-format">{editorialFormat}</div>
          <div className="article-card__content-wrapper__editorial-heading">{showOnly(heading, 65,)}</div>
          <div className="article-card__content-wrapper__editorial-summary">{showOnly(summary, 135,)}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ArticleCard;

import BaseComponent from '$Foundation/src/js/BaseComponent';
import { createEvent } from '$Foundation/src/js/utils';
import onLoad from './loadRecaptcha';

export const EVENT_ON_ERROR = 'recaptcha:onError';

export default class Recaptcha extends BaseComponent {
    constructor(el) {
        super(el);

        this.captchaId = null;

        this.containerElement = this.el.querySelector('[data-ref="container"]');
        this.inputElement = this.el.querySelector('[data-ref="input"]');

        this.sitekey = this.el.getAttribute('data-sitekey');
        this.lang = this.el.getAttribute('data-lang');

        this.onError = this.onError.bind(this);
        this.init = this.init.bind(this);
        this.successCallback = this.successCallback.bind(this);
        this.expiredCallback = this.expiredCallback.bind(this);

        onLoad(this.init, this.lang);
    }

    static get tagName() {
        return 'recaptcha';
    }

    init(grecaptcha) {
        this.grecaptcha = grecaptcha;

        this.captchaId = grecaptcha.render(this.containerElement, {
            sitekey: this.sitekey,
            callback: this.successCallback,
            'expired-callback': this.expiredCallback
        });

        this.inputElement.addEventListener(EVENT_ON_ERROR, this.onError);
    }

    successCallback(response) {
        this.inputElement.value = response;
        this.inputElement.dispatchEvent(createEvent('input'));
        this.inputElement.dispatchEvent(createEvent('change'));
    }

    expiredCallback() {
        this.reset();
    }

    onError() {
        this.grecaptcha.reset(this.captchaId);
        this.reset();
    }

    reset() {
        this.inputElement.value = '';
        this.inputElement.dispatchEvent(createEvent('input'));
        this.inputElement.dispatchEvent(createEvent('change'));
    }
}

import BaseComponent from '$Foundation/src/js/BaseComponent';
import EventEmitter from '$Foundation/src/js/EventEmitter';

export default class BrowserRegion extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'browser-region';
    }

    setVariables() {}

    addListeners() {}

    init() {
        this.setVariables();
        this.addListeners();
        this.initContentBlock();
    }

    initContentBlock() {
        const yandexLocationCookieName = 'BrowserRegion';

        if (document.cookie.indexOf(yandexLocationCookieName) === -1 && window.ymaps) {
            window.ymaps.ready(init);
        }

        function init() {
            const geolocation = window.ymaps.geolocation;
            let geoObject = null,
                metaDataProperty = null,
                region = null;
            geolocation.get({
                provider: 'auto',
                autoReverseGeocode: true
                })
                .then((result) => {
                    geoObject = result.geoObjects.get(0);
                    metaDataProperty = geoObject.properties.get('metaDataProperty');
                    region = metaDataProperty.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.AdministrativeAreaName;

                    const CookieDate = new Date();
                    CookieDate.setFullYear(CookieDate.getFullYear() + 1); // expire in 1 year
                    document.cookie = `${yandexLocationCookieName}=${encodeURIComponent(region)}; expires=${CookieDate.toGMTString()};path=/`;
                    EventEmitter.emit('region:get', document);
                });
        }
    }
}

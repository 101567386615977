import BaseComponent from '$Foundation/src/js/BaseComponent';

console.log('initiated script index.js')
export default class MyAccount extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'myaccount';
    }

    setVariables() {
        this.dropDown = this.el.getElementsByClassName('choices__list--dropdown')[0]
        this.selectOption=this.el.getElementsByClassName('choices');
		this.settingssection = this.el.getElementsByClassName('vf-settings-hidden')[0];
        this.selectInnerChoices=document.getElementById("id-job-role-select");
        this.listItem=this.el.querySelectorAll('[role="option"]')[1];
        console.log(this.listItem,'list item')
        this.editButton = this.el.querySelector('[role=tablist]');
        this.vetFocusSettings=this.el.querySelector('[data-js-my-account-edit-target="my-account"]') 
        this.cancelButton=this.el.getElementsByClassName('vf-settings-cancel-button')[0];
        console.log('  this.vetFocusSettings',  this.vetFocusSettings)
        console.log('  this.cancelButton',  this.cancelButton)
    }
    addListeners() {

        this.addListener(this.selectOption[0], 'click', e => this.setModalBehavior(e));
        // this.addListener(this.selectOption[0], 'mouseover' , e => this.onHover(e));
        this.addListener(this.editButton, 'click', e => this.clickOnEditButton(e));
        this.addListener(this.cancelButton, 'click', e => this.clickOnCancelButton(e));

    }

    init() {
        console.log('initited ',this.isInited)
        this.setVariables();
        this.addListeners();
       
    }
    clickOnEditButton(e){
        console.log('editButton',this.editButton)
        console.log('  this.vetFocusSettings',  this.vetFocusSettings)
        this.vetFocusSettings.classList.remove('rc-hidden')
		this.settingssection.classList.add('rc-hidden')
    }
    clickOnCancelButton(e){
        this.vetFocusSettings.classList.add('rc-hidden')
		this.settingssection.classList.remove('rc-hidden')
    }
    setModalBehavior(e){
        e.preventDefault();
        if (this.isInited==true){
            this.openModal(e);
            this.isInited=false;

        }
        else{
            this.closeModal(e);
            this.isInited= true;
        }
    }
    
    openModal(e) {

        console.log('isInited open modal' ,this.isInited)
        e.preventDefault();
        this.dropDown.classList.add('is-active');
        this.dropDown.setAttribute("aria-expanded", "true");
        // this.addListener(this.listItem, 'click', e => this.onHover(e));
        let i=0;
        for (i = 0;
            i < this.listItem.length; i++) { 
                console.log(this.listItem[i] ,i)

         this.addListener(this.listItem[i], 'click', e => {
             console.log('clicked on ',this.listItem[i])
         });
         }
       
        console.log('isInited open modal' ,this.isInited)
    };
    closeModal(e){
        console.log('isInited close modal' ,this.isInited)
        e.preventDefault();
        this.dropDown.classList.remove('is-active');
        this.dropDown.setAttribute("aria-expanded", "false");
       
        console.log('isInited close modal' ,this.isInited)
    }
    onHover(e){
        console.log('onHover')
        // this.listOfLanguages =document.getElementsByClassName('choices__item--choice')
        // this.listOfLanguages.array.forEach(element => {
        //     element.classList.add('is-highlighted')
        //     console.log('test')
        // });

    } 
    clickOnItemList(i,e){
        console.log('click on item list');
        console.log('this.listItem[i]',this.listItem[i])


    }
    
    
    
    
}

import BaseComponent from "$Foundation/src/js/BaseComponent";

export default class RoutineStep extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return "routine-step";
    }

    init() {
        const hasParagraph = el.querySelector(".numeric-articles-content > p");

        if (!hasParagraph) {
            const numericArticlesContent = el.querySelector(
                ".numeric-articles-content"
            );
            const videoWrapper = el.querySelector(
                ".numeric-articles-content > .video-wrapper"
            );

            if (numericArticlesContent) {
                numericArticlesContent.style.margin = "16px 0 0 0";
            }
            if (videoWrapper) {
                videoWrapper.style.margin = "40px 0 0 0";
            }
        }
    }
}

import './scss/index.scss';
// src set way for background images
import 'lazysizes/plugins/bgset/ls.bgset';
// data-src set
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes';
import Utils from '$Foundation/src/js/utils';


window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = false;
window.lazySizesConfig.minSize = 1;
window.lazySizesConfig.expand = 200;
const isIE = document.querySelector('html').classList.contains('ie11');

window.onload = function() {	
	
	if (isIE) { // instant load without delay
		const delayedItems = document.querySelectorAll('.lazyload--delay');
		for (let i = 0; i < delayedItems.length; i++) {
			delayedItems[i].classList.remove('lazyload--delay');
			delayedItems[i].classList.add('lazyload');
		  }
	}

    window.lazySizes.init();
};


if (!isIE) {
    // delayed lazyloading for carousels to prevent wrong size calculation
    let lazyloadDelayCarousels = document.querySelectorAll('.rc-carousel--lazyload');

    lazyloadDelayCarousels.forEach((item) => {
		Utils.classWatcher(item, 'rc-carousel--loaded', null, () => {		
			setTimeout(() => {			
				const delayedItems = item.querySelectorAll('.lazyload--delay');
				delayedItems.forEach((image) => {
					image.classList.add('lazyload');
				})
			}, 150);
		});	
	})
}
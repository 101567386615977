export default class MapBridge {
    constructor(el, options) {
        this.el = el;
        this.options = options;
        this.mapClasses = {
            Google: null,
            Baidu: null,
            Yandex: null,
            Kakao: null
        };
        this.defineImplementations();
        this.chooseImplementation();
    }

    defineImplementations() {
        throw new Error('Please define the method in inherited class');
    }

    chooseImplementation() {

        const mapType = document.querySelector('body').getAttribute('data-map-type');
        
        switch (mapType) {
            case 'KakaoMaps':
                this._implementation = new this.mapClasses.Kakao(this.el, this.options);
                break;

            default:
                this._implementation = new this.mapClasses.Google(this.el, this.options);
                break;
        }
    }

    initApi() {
        return this._implementation.initApi();
    }

    triggerResize() {
        this._implementation.triggerResize();
    }

    setZoom(zoom) {
        this._implementation.setZoom(zoom);
    }
}

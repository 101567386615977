// Validation errors messages for window.Parsley && window.Parsley
// Load this after window.Parsley && window.Parsley
window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('cs', {
    dateiso:  "Tato položka musí být datum ve formátu RRRR-MM-DD.",
    minwords: "Tato položka musí mít délku nejméně %s slov.",
    maxwords: "Tato položka musí mít délku nejvíce %s slov.",
    words:    "Tato položka musí být od %s do %s slov dlouhá.",
    gt:       "Tato hodnota musí být větší.",
    gte:      "Tato hodnota musí být větší nebo rovna.",
    lt:       "Tato hodnota musí být menší.",
    lte:      "Tato hodnota musí být menší nebo rovna."
  });
});

import BaseComponent from '$Foundation/src/js/BaseComponent';
import MoveTo from 'moveto';

export default class HeroPanel extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'hero-panel';
    }

    setVariables() {
        this.title = this.el.querySelector('h1');
        this.arrowDown = this.el.getElementsByClassName('euka-header-hero-banner__arrow')
        this.parentElem = document.querySelector('.euka-header-hero-banner').nextElementSibling
        this.arrowDown[0].style.cursor = 'pointer'
    }
    addListeners() {
        this.arrowDown[0].addEventListener("click", e => this.onArrowClick(e));
    }

    init() {
        this.setVariables();
        this.removeNbspFromTitleOnSmallScreens();
        this.addListeners();
       
    }

    removeNbspFromTitleOnSmallScreens() {
        if (window.innerWidth < 768 || window.innerHeight < 480) {
            this.title.innerHTML = this.title.innerHTML.replace(/&nbsp;/g, '');
        }
    }
    onArrowClick(e) {

        const target = this.parentElem;
        this.checkIfDiv(target);

    }
    checkIfDiv(target) {
        if (target.tagName == 'DIV' || target.tagName == 'SECTION') {
            console.log('target div ori section')
            const header = document.querySelector('[data-js-header-scroll]');
            let headerOffset = header.getBoundingClientRect().height;

            const moveTo = new MoveTo({
                tolerance: headerOffset
            });
            moveTo.move(target);
        }
        else {

            console.log('not div', target.tagName)
            let nextElem = target.nextElementSibling;
            this.checkIfDiv(nextElem);
        }
    }

}

import api, { CancelToken, isCancel } from '$Foundation/src/js/api';

export default class DataLoader {
    constructor() {
        this.lastRequestCancel = null;
    }

    async getData(method, data, url) {
        if (this.lastRequestCancel) {
            this.lastRequestCancel('Request canceled');
            this.lastRequestCancel = null;
        }

        const source = CancelToken.source();
        this.lastRequestCancel = source.cancel.bind(source);

        try {
            return await api({
                method,
                data,
                url,
                cancelToken: source.token
            });
        } catch (error) {
            if (isCancel(error)) {
                console.warn(error.message);
            }
            throw error;
        }
    }
}

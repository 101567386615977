export default class MediatorEventModel {
    constructor() {
        this.eventType = '';
        this.eventMethod = 'GET';
        this.eventData = {};
        this.eventParams = '';
        this.htmlData = '';
        this.eventUrl = '';
        this.searchQuery = [];
        this.typeOfInsertingContent = 'replace';
        this.trackDetails = {};
    }
}

import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';
import GTM from '$Foundation/src/js/gtm/gtm';
import Utils from '$Foundation/src/js/utils';

export default class LanguagePickerQRCode extends BaseComponent {
    constructor(el) {
        super(el);
        this.items = [...this.el.querySelectorAll('[data-toggle]')];
        this.countries = [...this.el.querySelectorAll('[data-country-code]')];
        this.buttonsWrapper = this.el.querySelector('[data-toggle-group]');
        this.modal = document.querySelector('[data-modal-target="country-lang-selector"]');
        this.filterSelect = this.el.querySelector('[data-ref="filter-select"]');
        this.selectedCountry = document.getElementById('id-select-country');
        this.selectedLanguage = document.getElementById('id-select-language');
        this._isModalOpened = false;
        this.isClickInit = null;
        dLScriptsObserver.onLoad(() => {
            if (this.isModalOpened()) {
                this.init();
            } else {
                this.modalObserver();
            }
        });
    }

    static get tagName() {
        return 'LanguagePickerQRCode';
    }

    setRedirectUrl(currentSelection) {
        let qrCodeItem = document.getElementById("qrCodeItem").value;
        let params = {
            method: 'GET',
            headers: {}
        };

        fetch(`${location.origin + "/" + location.pathname.split('/')[1]}/api/languagepicker/LanguagePickerQRCodeRedirect?languageCode=${currentSelection}&qrCodeItem=${qrCodeItem}`, params)
        .then(function (response) {
            return response.json();
        }).then(function (response) {
                document.getElementById('id-button-language').setAttribute("href", response.url);
            });
    }

    updateLanguages() {
        let params = {
            method: 'GET',
            headers: {}
        };
        
        const DLClass = Utils.getDLClass();
        fetch(`${location.origin + "/" + location.pathname.split('/')[1]}/api/languagepicker/getcountries`, params).then(function (response) {
            return response.json();
        })
            .then(function (response) {
                let selectedLanguages = [];
                let country = document.getElementById("id-select-country");
                let currentSelectedCountry = country.options[country.selectedIndex].value;
                response.map(
                    y => {
                        if (y.CountryCodeISO2 === currentSelectedCountry) {
                            y.Languages.map(
                                z => selectedLanguages.push(z)
                            )
                        }
                    }
                );
                document.getElementById('modal-select-language').innerHTML = `
            <span class="${DLClass}-select ${DLClass}-input--label ${DLClass}-margin-bottom--md--mobile ${DLClass}-margin-bottom--sm--desktop">
                <label class="${DLClass}-select__label" for="id-select-language">
                    Language
                </label>
                <select data-js-select-language="" id="` + `id-select-language` + `" onchange="setRedirectUrl()">
                    <option 
                        selected="" 
                        disabled="" 
                        value="${selectedLanguages[0].LanguageCode}"
                    >
                        ${selectedLanguages[0].Name} 
                    </option>
                    ${selectedLanguages.map(
                        (language) =>
                            `<option value="${language.LanguageCode}">  
                                ${language.Name}
                            </option>`
                    )}
                </select>
            </span>
            `
                const DLOBject = Utils.getDLObject();
                const languageOptions = {
                    placeholderValue: 'data-js-custom-placeholder',
                    shouldSort: false,
                    itemSelectText: '',
                    classNames: {
                        item: 'choices__item choices__item--custom-data'
                    }
                };
                DLOBject.features.Selects.init('[data-js-select-language]', null, languageOptions);
                let language = document.getElementById("id-select-language");
                let currentSelectedLanguage = language.options[language.selectedIndex].value;
               
                setRedirectUrl(currentSelectedLanguage);
                //document.getElementById('id-button-language').setAttribute("href", currentSelectedLanguage);
            });
    }

    addListeners() {
        this.countries.forEach((item) => {
            this.addListener(item, 'click', e => this.onCountryClick(e));
        });
        this.selectedCountry.addEventListener('change', () => {
            this.updateLanguages();
        });
        this.selectedLanguage.addEventListener('change', () => {
            let language = document.getElementById("id-select-language");
            let currentSelectedLanguage = language.options[language.selectedIndex].value;
            
            this.setRedirectUrl(currentSelectedLanguage);
        });
    }
    init() {
        this.edgeFix();
        this.addListeners();
        this.initLanguagePicker();
    }
    isModalOpened() {
        const DLClass = Utils.getDLClass();
        return (!(this.modal.classList.contains(`${DLClass}-hidden`)));
    }
    modalObserver() {
        const DLClass = Utils.getDLClass();
        Utils.classWatcher(this.modal, `${DLClass}-hidden`, null, () => { // init when rc-hidden removed (modal is shown)
            // this timeout required to get DL scripts finish their work on modal opening
            setTimeout(() => {
                this.init();
            }, 100);
        });
    }

    edgeFix() {
        const DLObject = Utils.getDLObject();
        const DLClass = Utils.getDLClass();
        if (!(document.querySelector('html').classList.contains('edge'))) return;
        if (this.modal.classList.contains(`${DLClass}-hidden`)) {
            Utils.classWatcher(this.modal, `${DLClass}-hidden`, null, () => {
                // this timeout required to get DL scripts finish their work on modal opening
                setTimeout(() => {
                    DLObject.features.ToggleGroup.init('[data-toggle-group]', this.buttonsWrapper);
                }, 100);
            });
        } else {
            DLObject.features.ToggleGroup.init('[data-toggle-group]', this.buttonsWrapper);
        }
    }

    initGTA() {
        this.items.forEach((item) => {
            this.addListener(item, 'click', e => this.onClick(e));
        });
    }
    onClick(e) {
        const item = Utils.getClosestNodes(e.target, '[data-ref="language-continent"]')[0];
        const trackCategory = item.getAttribute('data-track-category');
        const trackLabel = item.getAttribute('data-track-label');
        const trackEvent = trackCategory + ' ' + trackLabel;
        if (!this.isClickInit) {
            GTM.push(trackCategory, 'Click', trackLabel, trackEvent);
        } else {
            this.isClickInit = !this.isClickInit;
        }
    }
    onCountryClick(e) {
        const item = Utils.getClosestNodes(e.target, '[data-ref="language-country"]')[0];
        const countryCode = item.getAttribute('data-country-code');
        if (countryCode && countryCode.length) {
            this.setCookie('selected_country', countryCode, 365);
        }
    }
    setCookie(cname, cvalue, exdays) {
        const date = new Date();
        date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + date.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }
    initLanguagePicker() {
        // if no buttons stop function
        const buttonsWrapperSelector = this.buttonsWrapper && this.buttonsWrapper.querySelector('[role=tab]');
        if (!buttonsWrapperSelector) return;

        this.items.forEach((item) => {
            if (item.getAttribute('data-active-continent')) {
                this.isClickInit = true;
                item.click();
            }
        });
        this.initGTA();
    }
}

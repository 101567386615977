import Utils from '$Foundation/src/js/utils';
import { config } from '../../../index';
import MediatorEvents from '../../enums/mediatorEvents';
import MediatorEventModel from '../../models/mediatorEventModel';
import MapScriptObserver from '../mapScriptObserver';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import FilterStockistsControl from '../filterStockistsControl';

export default class FilterPlacesSearchControl extends BaseComponent {
    constructor(el) {
        super(el);

        MapScriptObserver.onLoad(() => {
            this.init();
        });
    }

    static get tagName() {
        return 'filter-places-search';
    }

    static get constructorName() {
        // Use the same name as filterSearchControl
        return 'filterSearchControl';
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.initializeAutocomplete();
        const locateMeBtn = document.querySelector('[data-ref="locate-to-me-btn"]');
        if (typeof google !== 'object' || typeof google.maps !== 'object') {
            this.loadGoogleMap();
        }
        if (!Utils.isEukanuba()) {
            const urlParams = new URLSearchParams(window.location.search);
            const locationShared = urlParams.get('location') && urlParams.get('location');
            locationShared && window.addEventListener('load', () => {
                setTimeout(() => {
                    window.RCDL && window.RCDL.click(locateMeBtn);
                }, 2000);
            });
        }
    }

    setVariables() {
        this.isInited = true;
        this.currentPositionBtn = this.el.querySelector('[data-ref="locate-to-me-btn"]');
        this.searchFieldNode = this.el.querySelector('[data-ref="filter-input"]');
        this.searchButtonNode = this.el.querySelector('[data-ref="filter-search-btn"]');
        this.currentCulture = this.el.getAttribute('data-search-current-culture');

        this.hasDownBeenPressed = false;
        this.trackCategory = this.searchFieldNode.getAttribute('data-track-category') || '';
        this.trackLabel = this.searchFieldNode.getAttribute('data-track-label') || '';
        this.trackEvent = this.searchFieldNode.getAttribute('data-track-event') || '';
        this.trackAction = this.searchFieldNode.getAttribute('data-track-action') || '';
        this.trackBreedId = this.searchFieldNode.getAttribute('data-track-breed-id') || '';
        this.trackSpecieId = this.searchFieldNode.getAttribute('data-track-specie-id') || '';
        this.trackAge = this.searchFieldNode.getAttribute('data-track-age') || '';

        this.mapType = document.querySelector('body').getAttribute('data-map-type');
        this.filteredStockists = document.querySelectorAll('[data-ref="filter-listing-item"]');

        this.deleteInputValueBtn = this.el.querySelector('[data-ref="delete-value-button"]');

    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    addListeners() {
        this.addListener(this.searchFieldNode, 'input', e => this.updateSearchFieldInputValue(e));
        this.addListener(this.searchButtonNode, 'click', e => this.handlePlacesSearch(e));
        this.addListener(this.searchFieldNode, 'keydown', e => this.handleKeyDown(e));
        this.addListener(this.searchFieldNode, 'focus', () => this.handleSearchFieldFocus());
        this.filteredStockists.forEach((item, i) => {
            this.addListener(item, 'click', e => this.handleStockistClicked(e, i));
        });
        this.addListener(this.deleteInputValueBtn, 'click', e => this.resetInputValue(e));

        this.addListener(this.currentPositionBtn, 'click', e => this.handleGetCurrentPosition(e));
        
        const interval = setInterval(() => {
            if (typeof google === 'object' && typeof google.maps === 'object') {
                window.google.maps.event.addDomListener(this.searchFieldNode, 'keydown', (e) => {
                    e.cancelBubble = true;
                    if (e.keyCode === 13 || e.keyCode === 9) {
                        if (!this.hasDownBeenPressed) {
                            const event = document.createEvent('Event');
                            event.keyCode = 40;
                            event.which = 40;
                            event.key = 40;
                            event.initEvent('keydown', true, true, false, 40);
                            this.searchFieldNode.dispatchEvent(event);
                            this.hasDownBeenPressed = true;
                        }
                    }
                });
                clearInterval(interval);
            }
        }, 250);
    }

    updateSearchFieldInputValue(e) {
        this.searchFieldNode.setAttribute("value",  e.target.value);
    }

    handleStockistClicked(e, i) {
        this.filteredStockists.forEach(stockist => {
            if (stockist.classList.contains(`stockist-${i}`)) {
                stockist.classList.add('rc-stockist-list__list-item--active')
            } else {
                stockist.classList.remove('rc-stockist-list__list-item--active')
            }
        });
    }

    handleSearchFieldFocus() {
        this.hasDownBeenPressed = false;
    }

    handleKeyDown(e) {
        if (e.keyCode === 40) {
            this.hasDownBeenPressed = true;
        } else if (e.keyCode ===13) {
            e.preventDefault();
        }
    }

    initializeAutocomplete() {
        const cultures = JSON.parse(this.currentCulture);
        const options = {
            componentRestrictions: { country: cultures }
        };

        const interval = setInterval(() => {
            if (typeof google === 'object' && typeof google.maps === 'object') {

                this.autocomplete = new window.google.maps.places.Autocomplete(this.searchFieldNode, {
                    options
                });

                this.autocomplete.setFields(['address_components', 'geometry', 'name']);
                window.google.maps.event.addListener(
                    this.autocomplete,
                    'place_changed',
                    this.handlePlaceChanged.bind(this)
                );  
                clearInterval(interval);
            }
        }, 250);

    }

    loadGoogleMap() {        
        const gmapApiKey =
            window.initialWebsiteConfig && window.initialWebsiteConfig.googleMapApiKey
                ? window.initialWebsiteConfig.googleMapApiKey
                : '';

        const mapscript = document.createElement('script');
        mapscript.type = 'text/javascript';
        mapscript.async = true;
        mapscript.src = `//maps.googleapis.com/maps/api/js?key=${gmapApiKey}&callback=gmapInitialize&libraries=places`;
        document.body.appendChild(mapscript);
    }

    handlePlacesSearch() {
        if (!this.hasDownBeenPressed) {
            const event = document.createEvent('Event');
            event.keyCode = 13;
            event.which = 13;
            event.key = 13;
            event.initEvent('keydown', true, true, false, 13);
            this.searchFieldNode.dispatchEvent(event);
        }
    }

    handlePlaceChanged() {
        const place = this.autocomplete.getPlace();    
        const mapZoomElement = document.getElementsByClassName('filter-map-custom')[0];
        // default map zoom value
        let mapZoom = 10;
        const placesArray = place && place.address_components;

        if (!Utils.isEukanuba()) {
            if (typeof placesArray !== 'undefined') {
                const types = placesArray.map(x => x.types).flat();
                if (types.includes('route')) {
                    mapZoom = 15;
                } else if (types.includes('locality')) {
                    mapZoom = 12;
                } else if (types.includes('sublocality')) {
                    mapZoom = 8;
                }
            }
        }

        if (typeof place.address_components !== 'undefined') {
            this.hasDownBeenPressed = false;
        }

        const placeName = place.name;
        const location = place.geometry ? place.geometry.location : null;

        mapZoomElement.setAttribute('data-zoom', mapZoom);
        this.handleSelectPlace(placeName, location);

        if(!document.querySelector("header")){
            document.querySelector("main").classList.remove("rc-content--fixed-header")
        }
    }

    handleSelectPlace(placeName, location) {
        const mediatorEvent = new MediatorEventModel();
        const urlParams = new URLSearchParams(window.location.search);
        const map = config.state.map;
        const urlCurrentLatitude = urlParams.get('currentLatitude') && urlParams.get('currentLatitude');
        const urlCurrentLongitude = urlParams.get('currentLongitude') && urlParams.get('currentLongitude');
        const urlLatitude = urlParams.get('latitude') && urlParams.get('latitude');
        const urlLongitude = urlParams.get('longitude') && urlParams.get('longitude');
        const contactTypes = urlParams.get('contacttypes') && urlParams.get('contacttypes');
        const sortResultsBy = urlParams.get('sortresultsby') && urlParams.get('sortresultsby');
        mediatorEvent.eventType = MediatorEvents.search;

        const searchQuery = config.filterPanelControl.getSearchQuery();
        Object.assign(searchQuery, ...config.requestParameters);
        config.state.map.bounds = {};

        if (placeName) {
            searchQuery.searchQuery = placeName;
        } else {
            searchQuery.searchQuery = urlParams.get('searchQuery') && urlParams.get('searchQuery');
        }

        if (location) {
            searchQuery.latitude = location.lat();
            config.state.map.latitude = location.lat();
            searchQuery.longitude = location.lng();
            config.state.map.longitude = location.lng();
        } else {
            searchQuery.latitude = urlLatitude;
            searchQuery.longitude = urlLongitude;
        }
        
        if (map.currentLatitude && map.currentLongitude) {
            searchQuery.currentLatitude = map.currentLatitude;
            searchQuery.currentLongitude = map.currentLongitude;
        } else {
            searchQuery.currentLatitude = urlCurrentLatitude;
            searchQuery.currentLongitude = urlCurrentLongitude;
        }

        if (contactTypes) {
            searchQuery.contacttypes = contactTypes;
        }

        if (sortResultsBy) {
            searchQuery.sortresultsby = sortResultsBy;
        }

        // keeping filters related url params
        const isLocal = urlParams.get("isLocal");
        if (isLocal) {
            searchQuery.isLocal = isLocal;

            if (!Utils.isEukanuba()) {
                searchQuery.isLocal =
                    !(searchQuery.searchQuery != null
                            && searchQuery.searchQuery.length > 0);
            }
        }

        const isFilterApplied = urlParams.get("IsFilterApplied");
        if (isFilterApplied) {
            searchQuery.IsFilterApplied = isFilterApplied;
        }

        const filter1 = urlParams.get("filter1");
        if (filter1) {
            searchQuery.filter1 = filter1;
        }
        const filter2 = urlParams.get("filter2");
        if (filter2) {
            searchQuery.filter2 = filter2;
        }
        const filter3 = urlParams.get("filter3");
        if (filter3) {
            searchQuery.filter3 = filter3;
        }

        mediatorEvent.searchQuery = searchQuery;
        const queryString = Utils.convertToQuery(searchQuery);
        mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;

        mediatorEvent.trackDetails = this.getTrackDetails();

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    handleGetCurrentPosition() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => this.handleSuccessPosition(position),
                error => this.handleErrorPosition(error)
            );
        } else {
            throw new Error('Geolocation is not supported by this browser');
        }
    }
 
    handleSuccessPosition(position) {
        const { latitude, longitude } = position.coords;
        const urlParams = new URLSearchParams(window.location.search);
        const contactTypes = urlParams.get('contacttypes') && urlParams.get('contacttypes');
        const sortResultsBy = urlParams.get('sortresultsby') && urlParams.get('sortresultsby');
        const urlCurrentLatitude = urlParams.get('currentLatitude') && urlParams.get('currentLatitude');
        const urlCurrentLongitude = urlParams.get('currentLongitude') && urlParams.get('currentLongitude');
 
        const mediatorEvent = new MediatorEventModel();
        this.resetInputValue();
        mediatorEvent.eventType = MediatorEvents.getCurrentPosition;
 
        const searchQuery = config.filterPanelControl.getSearchQuery();
        Object.assign(searchQuery, ...config.requestParameters);
        const bounds = config.state.map.bounds;
        if (Array.isArray(bounds)) Object.assign(searchQuery, ...bounds);
 
        if (Utils.isEukanuba()) {
            searchQuery.currentLatitude = latitude;
            searchQuery.latitude = undefined;
            searchQuery.currentLongitude = longitude;
            searchQuery.longitude = undefined;
            config.state.map.currentLatitude = latitude;
            config.state.map.latitude = undefined;
            config.state.map.currentLongitude = longitude;
            config.state.map.longitude = undefined;
        } else {
            if (urlCurrentLatitude && urlCurrentLongitude) {
                searchQuery.currentLatitude = urlCurrentLatitude;
                searchQuery.currentLongitude = urlCurrentLongitude;
            }

            if (contactTypes) {
                searchQuery.contacttypes = contactTypes;
            }
            if (sortResultsBy) {
                searchQuery.sortresultsby = sortResultsBy;
            }
        }
        searchQuery.isLocal = true;

        mediatorEvent.searchQuery = searchQuery;
        const queryString = Utils.convertToQuery(searchQuery);
        mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
        mediatorEvent.position = position;
 
        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }

        if(FilterStockistsControl.IsFilterApplied === true){
            FilterStockistsControl.handleApplyFilters();
        }

    }
 
    handleErrorPosition(error) {
        throw new Error(`ERROR(${error.code}): ${error.message}`);
    }

    getInputValue() {
        return this.searchFieldNode.value;
    }

    resetInputValue() {
        this.searchFieldNode.value = '';
    }

    getTrackDetails() {
        const trackDetails = {
            category: this.trackCategory,
            label: this.trackLabel,
            event: this.trackEvent,
            breedId: this.trackBreedId,
            specieId: this.trackSpecieId,
            age: this.trackAge
        };
        return trackDetails;
    }
}

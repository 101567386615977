import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class VideoPanel extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'video-panel';
    }

    setVariables() {
        this.video = this.el.querySelector('[data-ref="video"]');
        this.playButton = this.el.querySelector('[data-ref="video-play-button"]');
    }

    addListeners() {
        this.addListener(this.playButton, 'click', () => this.handleVideoPlay());
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.togglePlayButtonVisibility();
    }

    handleVideoPlay() {
        if (this.video.paused === true) {
            this.video.play();
            this.playButton.classList.remove('is-visible');
        } else {
            this.video.pause();
        }
    }

    isVideoControlsEnabled() {
        return this.video.hasAttribute('controls');
    }

    togglePlayButtonVisibility() {
        const videoControlsEnabled = this.isVideoControlsEnabled();
        if (videoControlsEnabled) {
            this.playButton.classList.remove('is-visible');
        } else {
            this.playButton.classList.add('is-visible');
        }
    }
}

import BaseComponent from '$Foundation/src/js/BaseComponent';
import GTM from '$Foundation/src/js/gtm/gtm';

export default class SearchEventTracker extends BaseComponent {
    constructor(el) {
        super(el);
        this.trackCategory = null;
        this.trackLabel = null;
        this.form = this.el.querySelector('form');
        this.searchInput = this.form.querySelector('[data-ref="input"]');
        this.init();
    }

    static get tagName() {
        return 'search-track';
    }

    init() {
        this.form.addEventListener('submit', this.onSubmit.bind(this));
    }

    onSubmit() {
        this.trackCategory = this.el.getAttribute('data-track-category');
        this.trackLabel = this.searchInput.value;
        const trackEvent = this.trackCategory + ' ' + this.trackLabel;
        GTM.push(this.trackCategory, 'Submit', this.trackLabel, trackEvent);
    }
}

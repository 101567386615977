import "./styles.scss";
import BaseComponent from "$Foundation/src/js/BaseComponent";

export default class ProductDetails extends BaseComponent {
    constructor(el) {
        super(el);
        this.customAnchor = this.customAnchor.bind(this);
        this.waitForEl = this.waitForEl.bind(this);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return "product-image-gallery";
    }

    addListeners() {
        window.addEventListener("load", () => {
            const images = document.querySelectorAll(".tns-item img");
            for (const img of images) {
                const imageUrl = img.getAttribute("src");
                if (imageUrl.indexOf("salsify.com") < 0) return;
                img.setAttribute("src", imageUrl.replace(/\/w_\d+/, ""));
            }
        });
    }

    vetDPT(){
        const DPT = document.querySelectorAll(".content-block-variation-1");
        
        if(DPT){
            const vetRegexp = DPT[0].getAttribute("vet-products-partial-path");
            const currentUrl = window.location.href;
            if(currentUrl.match(vetRegexp)){
                DPT.forEach(el => el.style.display = "none")
            }
        }
    }

    waitForEl(selector) {
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver((mutations) => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        });
    }

    async customAnchor() {
        const currentUrl = window.location.href;
        const anchorRegexp = /ConnectedPackRationsTable/;

        if (currentUrl.match(anchorRegexp)) {
            const scrollingEnd = await this.waitForEl("#scrollToConnectedDPT");
            if (scrollingEnd) {
                    const feedingButton = await this.waitForEl(
                        "#scrollToConnectedDPT li:last-child button"
                    );
                        window.scrollTo({ top: 300, behavior: "smooth" });
                        const yOffset = window.innerWidth > 960 ? -175 : -115;
                        const yAxis =
                            scrollingEnd.getBoundingClientRect().top +
                            window.pageYOffset +
                            yOffset;

                        window.scrollTo({ top: yAxis, behavior: "smooth" });
                        feedingButton.dispatchEvent(new Event("click"));                
            }
        }
    }

    init() {
        this.vetDPT();
        this.customAnchor();
        this.addListeners();
    }
}

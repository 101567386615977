import EventEmitter from '$Foundation/src/js/EventEmitter';

export default class Kakao {
    constructor(el, options) {
        this.el = el;
        this.options = options;
        this.map = null;
        this.marker = null;
    }

    initApi() {
        this.apiReadyPromise = new Promise((apiResolve) => {
            if (typeof window.kakao.maps.Map === 'undefined' || !Kakao.isMapReady) {
                Kakao.loadScript(apiResolve);
            } else {
                apiResolve();
            }
        });
        return this.apiReadyPromise;
    }

    triggerResize() {
        window.kakao.maps.event.trigger(this.map, 'resize');
    }

    setZoom(zoom) {
       this.map.setLevel(zoom);
    }

    static loadScript(callback) {
        Kakao.callbacks.push(callback);
        
        const interval = setInterval(() => {
            if (typeof window.kakao.maps.Map !== 'undefined') {

                for (let i = 0, l = Kakao.callbacks.length; i < l; i++) {
                    Kakao.callbacks[i]();
                }
                Kakao.isMapReady = true;
                window.mapIsInitialized = true;
                EventEmitter.emit('map:initialized');

                clearInterval(interval);
            }
        }, 250);

    }
}

Kakao.isMapReady = false;
Kakao.callbacks = [];

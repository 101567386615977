import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import { split } from 'sentence-splitter';

export default class ReadMore extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'read-more';
    }

    setVariables() {
        this.text = this.el.innerHTML;
        this.defaultVisibleSentencesQty = 7;
        this.visibleSentencesQty = this.el.getAttribute('data-sentence-qty') || 2;
        this.visibleText = '';
        this.hiddenText = '';
    }

    addListeners() {}

    init() {
        this.setVariables();
        this.addListeners();
        this.initReadMore();
    }

    splitText() {
        const sentences = split(this.text);
        let visibleSentencesCount = 0;
        sentences.forEach((val) => {
            if (visibleSentencesCount < this.visibleSentencesQty) {
                if (val.type === 'Sentence') {
                    visibleSentencesCount++;
                }
                this.visibleText = this.visibleText.concat(val.raw);
            } else if (val.type === 'Sentence') {
                this.hiddenText = this.hiddenText.concat(val.raw);
            }
        });
    }

    reformatText() {
        this.el.innerHTML = '';
        this.addDOMElement('div', 'read-more__wrapper', '', this.el, 'read-more-wrapper');
        this.addDOMElement(
            'div',
            'read-more__visible-text',
            this.visibleText,
            this.el.querySelector('[data-ref="read-more-wrapper"]'),
            'read-more-visible'
        );
        this.addDOMElement(
            'div',
            'read-more__hidden-text',
            this.hiddenText,
            this.el.querySelector('[data-ref="read-more-wrapper"]'),
            'read-more-hidden'
        );
        this.addDOMElement(
            'a',
            'rc-styled-link read-more__button',
            'Read more',
            this.el,
            'read-more-button'
        );

        this.visiblePart = this.el.querySelector('[data-ref="read-more-visible"]');
        this.allText = this.el.querySelector('[data-ref="read-more-wrapper"]');
        this.visibleTextHeight = this.visiblePart.clientHeight;
        this.textHeight = this.allText.clientHeight;
        this.button = this.el.querySelector('[data-ref="read-more-button"]');
    }

    addDOMElement(element, className, text, parent, dataRef) {
        const domElement = document.createElement(element);

        domElement.innerHTML = text;

        domElement.setAttribute('class', className);
        domElement.setAttribute('data-ref', dataRef);
        parent.appendChild(domElement);
    }

    hideInvisibleText() {
        this.allText.style.maxHeight = this.visibleTextHeight + 'px';
        this.el.classList.add('is-closed');
        this.button.innerHTML = 'Read more';
    }

    showInvisibleText() {
        this.allText.style.maxHeight = this.textHeight + 'px';
        this.el.classList.remove('is-closed');
        this.button.innerHTML = 'Read less';
    }

    btnClick(e) {
        e.preventDefault();
        if (this.el.classList.contains('is-closed')) {
            this.showInvisibleText();
        } else {
            this.hideInvisibleText();
        }
    }

    initReadMore() {
        this.splitText();
        if (this.hiddenText === '') return;
        this.reformatText();
        this.button.addEventListener('click', (this.buttonListener = this.btnClick.bind(this)));
        this.button.addEventListener(
            'touchstart',
            (this.buttonListener = this.btnClick.bind(this))
        );
        this.hideInvisibleText();
    }
}

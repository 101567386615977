import BaseComponent from '$Foundation/src/js/BaseComponent';
import './styless.scss';


export default class AccordionBreed extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'accordion-breed';
    }



    addListeners() {
        window.addEventListener('resize', this.getResolution());
    }

    init() {
        this.setVariables();
        this.addListeners()
        this.getResolution();
    }
    setVariables(){
        this.mobileAccordion = this.el.getElementsByClassName('mobile-only')[0];

        this.desktopAccordion = this.el.getElementsByClassName('desktop-only')[0];
    }

    getResolution() {

       console.log(this.desktopAccordion,'desktopAccordion')
    //   if( window.location.href.indexOf("breed-library") > -1){
    //        console.log('breed-library')
       
    //     if (window.matchMedia('(min-width: 767px)').matches) {
    //         this.mobileAccordion.classList.add("display-none")
    //     }
    //     else{
    //         console.log(this.desktopAccordion,'this.desktopAccordion')
    //         this.desktopAccordion.classList.add("display-none")
    //     }
    // }
    // else{
    //     console.log('else')
    //     this.desktopAccordion.classList.add("display-none");
    // }
    } 
}

import './styles.scss';

import Utils from '$Foundation/src/js/utils';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import Awesomplete from 'awesomplete';
import api from '$Foundation/src/js/api';

export default class AutoComplete extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isIE = document.querySelector('html').classList.contains('ie11');
    }

    static get tagName() {
        return 'autocomplete';
    }

    setVariables() {
        this.isInited = true;
        this.inputNode = this.el.querySelector('[data-ref="input"]');
        this.searchButtonNode = this.el.querySelector('[data-ref="search-button"]');
        this.autoCompleteInstanse = null;
        this.autoCompleteEnabled = this.el.getAttribute('data-autocomplete-enabled')
            ? this.el.getAttribute('data-autocomplete-enabled') === 'true'
            : true;

        this.jsonData = null;
        this.preDefinedSuggestion = null;

        this.isValueSelected = false;
        this.endPoint = this.el.getAttribute('data-endpoint');
        this.minChairs = 2;
        this.maxItems = 100;
        this.onKeyUpTimeOut = null;
        this.loadingTime = 300;

        this.settings = { ...this.defaultSettings, ...this.el.dataset };
    }

    addListeners() {
        this.addListener(this.inputNode, 'input', e => this.handleInputFill(e));
        this.addListener(this.inputNode, 'focus', e => this.handleInputFocus(e));
        this.addListener(this.inputNode, 'blur', e => this.handleInputBlur(e));
        this.addListener(this.inputNode, 'awesomplete-selectcomplete', e =>
        this.handleAwesompleteSelectComplete(e));
        this.addListener(this.inputNode, 'input', e => this.handleInput(e));
        this.addListener(this.el, 'submit', e => this.handleSubmit(e));
        this.addListener(this.searchButtonNode, 'click', e => this.handleSearchButtonClick(e));

    }

    init() {
        this.setVariables();
        this.addListeners();

        if (this.autoCompleteEnabled) {
            this.createAutocompleteInstanse();
        }
    }

    get defaultSettings() {
        return {
            mainClass: 'autocomplete',
            listClass: 'autocomplete__list',
            listWithFeaturedClass: 'autocomplete__list--featured'
        };
    }

    get awesompleteSettings() {
        return {
            minChars: this.minChairs,
            maxItems: this.maxItems,
            filter: () => true,
            sort: false,
            item: (text, input) => {
                const inputRegexPattern = Awesomplete.$.regExpEscape(input.trim());
                // highlight only if word starts with pattern
                const regExp = RegExp(`^${inputRegexPattern}|[\\s]+${inputRegexPattern}`, 'gi');
                const html = input.trim() === '' ? text : text.replace(regExp, '<mark>$&</mark>');
                return Awesomplete.$.create('li', {
                    innerHTML: html,
                    'aria-selected': 'false'
                });
            },
            data: (item) => {
                const suggestionObj = {
                    label: item.Title,
                    value: item.Title,
                    url: item.Url
                };
                return suggestionObj;
            }
        };
    }

    createAutocompleteInstanse() {
        this.autoCompleteInstanse = new Awesomplete(this.inputNode, {
            ...this.awesompleteSettings,
            ...this.settings
        });

        this.autoCompleteInstanse.container.className += ` ${this.settings.mainClass}`;
        this.autoCompleteInstanse.ul.classList.add(this.settings.listClass);
    }


    setDataFromModel() {
        const list = [...this.jsonData.Items];
        if (this.jsonData.FeaturedItems && this.jsonData.FeaturedItems.length > 0) {
            list.push(...this.jsonData.FeaturedItems);
            this.autoCompleteInstanse.ul.classList.add(this.settings.listWithFeaturedClass);
        } else {
            this.autoCompleteInstanse.ul.classList.remove(this.settings.listWithFeaturedClass);
        }

        if (this.autoCompleteEnabled) {
            this.autoCompleteInstanse.list = list;
        }
        let listElements = document.querySelectorAll('ul.autocomplete__list li');

        if (listElements != null) {
            for (var i = 0; i < listElements.length -1; i++) {
                listElements[i].onclick = function () {
                    handleDataLayerSearchResultClick(listElements, this);
                }
                listElements[i].mousedown = function () {
                    handleDataLayerSearchResultClick(listElements, this);
                }
            }
        }
    }

    handleSubmit(e) {
        if (this.inputNode.value.length < this.minChairs) {
            e.preventDefault();
        }
    }

    handleSearchButtonClick(e) {
        e.preventDefault();
        if (this.inputNode.value.length >= this.minChairs) {
            this.autoCompleteInstanse.goto(0);
            const suggestion = this.autoCompleteInstanse.suggestions[
                this.autoCompleteInstanse.index
            ];
            this.inputNode.value = suggestion.value;
            Awesomplete.$.fire(this.inputNode, 'awesomplete-selectcomplete', {
                text: suggestion
            });
        }
    }

    handleInput() {
        const isVetFocus = Utils.isVetFocus();
        if (isVetFocus && this.inputNode.type !== "search") return; 

        if (this.inputNode.value.length < this.minChairs || !this.autoCompleteEnabled) return;

        if (this.onKeyUpTimeOut) {
            clearTimeout(this.onKeyUpTimeOut);
        }
        const inputValue = this.isIE ? this.utfEightStringToBytes(this.inputNode.value) : this.inputNode.value;
        const searchQuery = {
            keyword: inputValue.replace(/ /g, '+')
        };
        const queryString = Utils.convertToQuery(searchQuery, this.endPoint);
        const url = `${this.endPoint}${queryString}`;

        this.onKeyUpTimeOut = setTimeout(() => {
            api.get(url).then((response) => {
                this.jsonData = response.data;
                this.preDefinedSuggestion =
                    this.jsonData.FeaturedItems && this.jsonData.FeaturedItems[0]
                        ? this.jsonData.FeaturedItems[0].Title
                        : 'undefined';
                this.setDataFromModel();

                let dataLayer = window.dataLayer = window.dataLayer || [];
                var totalResults = this.jsonData.FeaturedItems && this.jsonData.FeaturedItems[0]
                    ? this.jsonData.FeaturedItems[0].TotalResults : "";
                dataLayer.push({
                    'event': 'instantSearchResultDisplay',
                    'instantSearchResultDisplay': {
                        'query': searchQuery.keyword, //Query as written by the user
                        'results': totalResults
                    }
                })
                console.log("Event - instantSearchResultDisplay added");
            });
        }, this.loadingTime);
    }

    utfEightStringToBytes(str) {
        const numArr = this.textEncode(str);
        let bytes = [];

        for (let i = 0; i < numArr.length; i++) {
            bytes = bytes.concat([`%${Math.abs(numArr[i]).toString(16).toUpperCase()}`])
        }

        return bytes.join('');
    }

    textEncode(str) {
        var utf8 = unescape(encodeURIComponent(str));
        var result = new Uint8Array(utf8.length);
        for (var i = 0; i < utf8.length; i++) {
            result[i] = utf8.charCodeAt(i);
        }
        return result;
    }

    handleInputFill(e) {
        if (e.target.value.trim() !== '') {
            this.el.classList.add('is-filled');
        } else {
            this.el.classList.remove('is-filled');
        }
    }

    handleInputFocus() {
        this.el.classList.add('is-focused');
    }

    handleInputBlur() {
        this.el.classList.remove('is-focused');
    }

    resetInputValue() {
        this.inputNode.value = '';
        this.el.classList.remove('is-focused');
        this.el.classList.remove('is-filled');
    }

    handleAwesompleteSelectComplete(e) {
        this.isValueSelected = true;

        const url = this.autoCompleteInstanse._list.filter(function (x) {
            return x.Title === e.text.label
        })[0].Url;

        window.location.href = url;
    }

    onDestroy() {
        this.autoCompleteInstanse.destroy();
    }
}
var handleDataLayerSearchResultClick = function(listElements, element) {
    let dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'instantSearchResultClick',
        'instantSearchResultClick': {
            'name': element.innerText, //Article name, as written on the list
            'position': Array.prototype.indexOf.call(listElements, element) + 1 //Position of the article in the list
        }
    })
    console.log("Event - instantSearchResultClick added");
};

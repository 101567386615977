/* eslint import/no-unresolved: 0 */
/* eslint import/extensions: 0 */

// Styles
import React from "react";
import { autobind } from "../autobind";
import ArticleCard from "../components/article/card/index";
import MyAccountDropdown from "../components/my-account-dropdown/index";

import "$Foundation/src/scss/index.scss";
import "$Feature/Breeds/code/src/BreedSearchAndFilter.scss";
import "$Feature/GenericComponents/code/src/richtext-editor";
import "$Feature/GenericComponents/code/src/tags-container";
import "$Feature/GenericComponents/code/src/definition-list";
import "$Feature/GenericComponents/code/src/form-elements";
import "$Feature/GenericComponents/code/src/preloader";
import "$Feature/GenericComponents/code/src/list";
import "$Feature/GenericComponents/code/src/text-with-icon";
import "$Feature/GenericComponents/code/src/info-window";
import "$Feature/GenericComponents/code/src/blockquote";
import "$Feature/GenericComponents/code/src/purchase-block";
import "$Feature/GenericComponents/code/src/author-panel";
import ContentCards from "$Feature/GenericComponents/code/src/content-cards";
import "$Feature/GenericComponents/code/src/carousel";
import "$Feature/GenericComponents/code/src/content-block";
import "$Feature/GenericComponents/code/src/product-card";
import ProductBlock from "$Feature/GenericComponents/code/src/product-block";
import ParagraphsInTwoColumns from "$Feature/GenericComponents/code/src/paragraphs-in-two-columns";
import "$Feature/GenericComponents/code/src/autocomplete-places";
import "$Feature/GenericComponents/code/src/share-links";
import TabbedImage from "$Feature/GenericComponents/code/src/tabbed-image";
import "$Feature/Breeds/code/src/size-table";
import "$Feature/Footer/code/src";
import "$Feature/GenericComponents/code/src/hot-fixes";
import "$Feature/GenericComponents/code/src/wechat";
import ProductDetails from "$Feature/Products/code/src/product-details";
import "$Feature/GenericComponents/code/src/puppies-kittens/quicktips";
import SocialSharing from "$Feature/SocialSharing/code/src";
import StockistsLandingPage from "$Feature/Stockists/code/src";
import "$Feature/Navigation/code/src";
import "$Feature/Products/code/src/daily-portion-tool";
import "./daily-portion-tool";
import "$Feature/GenericComponents/code/src/plp-filters-column";

import PfRecommendations from "$Feature/ProductFinder/code/src/recommendations";

import "intersection-observer";

// Features

import LanguagePicker from "$Feature/LanguagePicker/Code/src/royal-canin";
import LanguagePickerQRCode from "$Feature/LanguagePicker/Code/src/royal-canin-QRCode";
import LanguagePickerEukanuba from "$Feature/LanguagePicker/Code/src/eukanuba";
import Filter from "$Feature/GenericComponents/code/src/filter";
import MobileSearchButton from "$Feature/GenericComponents/code/src/mobile-search-button";
import AutoComplete from "$Feature/GenericComponents/code/src/autocomplete";
import ButtonSwitch from "$Feature/GenericComponents/code/src/button-switch";
import ListingWithPagination from "$Feature/GenericComponents/code/src/listing-with-pagination";
import Timeline from "$Feature/Breeds/code/src/timeline";
import "$Feature/Articles/code/src/styles.scss";
import ViewAllArticles from "$Feature/Articles/code/src/view-all-articles";
import BackToTop from "$Feature/Articles/code/src/back-to-top";
import "$Feature/Articles/code/src/editorial-block";
import RelatedArticles from "$Feature/Articles/code/src/related-articles";

import "$Feature/Tips/code/src/tips";
import "$Feature/Tips/code/src/tip-page";

import ReadMore from "$Feature/GenericComponents/code/src/read-more";
import SearchResults from "$Feature/GenericComponents/code/src/search-results";
import {
    Form,
    Recaptcha,
    ShowRule,
    HiddenIf,
    ServerValidation,
} from "$Feature/Forms/code/src";

import Map from "$Feature/GenericComponents/code/src/map";
import ProductRating from "$Feature/Products/code/src/product-rating";
import WriteReview from "$Feature/Products/code/src/write-review";
import FiltersList from "$Feature/EukanubaProducts/code/src/filters-list";
import ReviewFeedback from "$Feature/EukanubaProducts/code/src/review-feedback";
import PSTracking from "$Feature/EukanubaProducts/code/src/ps-tracking";
import ArticleTabbedNavigation from "$Feature/Articles/code/src/article-tabbed-nav";
import ArticleTags from "$Feature/Articles/code/src/article-tags";

import ArticleReadingTime from "$Feature/Articles/code/src/article-reading-time";
import "$Feature/Articles/code/src/custom-author-panel-styles";
import "$Feature/Articles/code/src/article-hero-container";
import VideoPanel from "$Feature/GenericComponents/code/src/video-wrapper";

import CarouselEventTracker from "$Feature/GenericComponents/code/src/carousel-event-tracker";
import CtaEventTracker from "$Feature/GenericComponents/code/src/cta-event-tracker";
import SearchEventTracker from "$Feature/GenericComponents/code/src/search-event-tracker";
import NavigationEventTracker from "$Feature/GenericComponents/code/src/navigation-event-tracker";
import ContentTracker from "$Feature/GenericComponents/code/src/content-tracker";
import ContentAnimation from "$Feature/GenericComponents/code/src/content-animation";
import ProductTrack from "$Feature/Products/code/src/product-track";
import ArticleHeroPanel from "$Feature/GenericComponents/code/src/article-hero-panel";

import ProductListTrack from "$Feature/Products/code/src/product-list-track";
import AmpRedirectBuyWidget from "$Feature/Products/code/src/amp-redirect-buy-widget";
import LiveChat from "$Feature/GenericComponents/code/src/live-chat";
import LiveChatPopup from "$Feature/GenericComponents/code/src/live-chat-popup";
import TencentVideo from "$Feature/GenericComponents/code/src/tencent-video";

import BrowserRegion from "$Feature/RegionPicker/code/src/browser-region";
import ManualRegion from "$Feature/RegionPicker/code/src/manual-region";
import RegionLinks from "$Feature/RegionPicker/code/src/region-links";
import TimelineLink from "$Feature/GenericComponents/code/src/puppies-kittens/timeline";
import CommerceButton from "$Feature/GenericComponents/code/src/commerce-button";
import HeroPanel from "$Feature/GenericComponents/code/src/hero-panel";
import ModalNav from "$Feature/GenericComponents/code/src/modal-nav";
import VetFocusArticles from "$Feature/VetFocusArticles/code/src/";
import VetFocusIssueCard from "$Feature/VetFocusIssues/code/src/issue-card";
import VetFocusArticleIssueDetail from "$Feature/VetFocusIssues/code/src/article-issue-detail";
import VetFocusSocialLinks from "$src/Project/VetFocusMetadata/code/src/social-links";
import EnlargeImage from "$Feature/GenericComponents/code/src/enlarge-image";
import MyAccountRegisterStep from "$Feature/GenericComponents/code/src/my-account-register-step";
import MyAccount from "$Feature/GenericComponents/code/src/my-account";
import PromoItem from "$Feature/GenericComponents/code/src/promo-item";
import NavigationProductBlock from "$Feature/GenericComponents/code/src/navigation-product-block";
import NavigationContactDropdown from "$Feature/GenericComponents/code/src/navigation-contact-dropdown";
import NavigationItemsList from "$Feature/GenericComponents/code/src/navigation-items-list";
import HeaderBar from "$Feature/HeaderBar/code/src/";

import CopyLink from "$Feature/GenericComponents/code/src/copy-link";
import HomepageCarousel from "$Feature/GenericComponents/code/src/homepage-carousel";
import "$Feature/GenericComponents/code/src/mobile-filter-button";
import AccordionBreed from "$Feature/GenericComponents/code/src/accordion";
import MarketPicker from "$Feature/KittenZooplus/Code/src/market-picker";
import RoutineStep from "$Feature/GenericComponents/Code/src/routine-step";
// BaseCore

import "$Foundation/src/js/lazysizes";
import BaseCore from "$Foundation/src/js/BaseCore";

window.React = React;

const baseCore = new BaseCore();

baseCore.componentClasses = [
    PfRecommendations,
    LanguagePicker,
    Filter,
    MobileSearchButton,
    AutoComplete,
    ButtonSwitch,
    Timeline,
    ReadMore,
    ListingWithPagination,
    SearchResults,
    Form,
    Recaptcha,
    ShowRule,
    HiddenIf,
    ServerValidation,
    Map,
    ProductRating,
    WriteReview,
    FiltersList,
    ReviewFeedback,
    PSTracking,
    ArticleTabbedNavigation,
    ArticleTags,
    RelatedArticles,
    ArticleReadingTime,
    VideoPanel,
    ProductBlock,
    ContentCards,
    CarouselEventTracker,
    CtaEventTracker,
    SearchEventTracker,
    NavigationEventTracker,
    ContentTracker,
    ContentAnimation,
    ViewAllArticles,
    ProductTrack,
    ProductDetails,
    ProductListTrack,
    AmpRedirectBuyWidget,
    LiveChat,
    LiveChatPopup,
    TencentVideo,
    TabbedImage,
    BrowserRegion,
    ManualRegion,
    RegionLinks,
    TimelineLink,
    CommerceButton,
    HeroPanel,
    ModalNav,
    VetFocusArticles,
    VetFocusIssueCard,
    VetFocusArticleIssueDetail,
    VetFocusSocialLinks,
    VetFocusArticles,
    EnlargeImage,
    MyAccountRegisterStep,
    MyAccount,
    PromoItem,
    NavigationProductBlock,
    NavigationContactDropdown,
    NavigationItemsList,
    HeaderBar,
    StockistsLandingPage,
    LanguagePickerEukanuba,
    CopyLink,
    HomepageCarousel,
    ParagraphsInTwoColumns,
    MarketPicker,
    AccordionBreed,
    LanguagePickerQRCode,
    BackToTop,
    SocialSharing,
    RoutineStep,
    ArticleHeroPanel,
];
baseCore.init();

// SVG Sprite

const files = require.context("$Foundation/src/svg", true, /^\.\/.*\.svg/);
files.keys().forEach(files);

window.RC = {
    components: {
        "article-card": ArticleCard,
        "my-account-dropdown": MyAccountDropdown,
    },
};

autobind();

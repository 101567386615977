import { camelizeString } from '$Foundation/src/js/utils';

class GTM {
    push(category, action, label, event = 'eventTrack', breedId, specieId, age) {

        const siteId = document.body.dataset.siteId;
        const eventCamelized = camelizeString(event);
        const eventFull = siteId + eventCamelized;
        let pet = {};
        let isPetDataEmpty = true;

        const collectPetData = (breedIdValue, specieIdValue, ageValue) => {
            if (breedIdValue) {
                Object.assign( pet, { breedId: breedIdValue });
                isPetDataEmpty = false;
            }
            if (specieIdValue) {
                Object.assign( pet, { specieId: specieIdValue });
                isPetDataEmpty = false;
            }
            if (ageValue) {
                Object.assign( pet, { age: ageValue });
                isPetDataEmpty = false;
            }
        }     

        if (window.dataLayer) {

            const resultObject = {};
            collectPetData(breedId, specieId, age);
            if (!isPetDataEmpty) {
                Object.assign(resultObject, { pet });
            }

            Object.assign(resultObject,
                {
                    event: eventFull
                },
                {
                    interaction: {
                        category,
                        action,
                        label,
                        value: 1
                    }
                });

            window.dataLayer.push(resultObject);
        }
    }
}

export default new GTM();

import BaseComponent from '$Foundation/src/js/BaseComponent';
import Utils from '$Foundation/src/js/utils';

export default class PSTracking extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
        this.addBuyButtonsClickListeners = this.addBuyButtonsClickListeners
            .bind(this);
    }

    static get tagName() {
        return 'ps-tracking';
    }

    getBuyButtons() {
        return [
            ...document.querySelectorAll('.ps-online-buy-cell'),
            ...document.querySelectorAll('.ps-online-buy-button-mobile'),
            ...document.querySelectorAll('.ps-online-seller-cell'),
        ];
    }

    setVariables() {
        this.shopNowButtons = this.el.querySelectorAll('.ps-widget');
    }

    addListeners() {
        this.shopNowButtons.forEach(button => {
            button.addEventListener('click', () => setTimeout(
                this.addBuyButtonsClickListeners,
                1000
            ));
        });
    }

    addBuyButtonsClickListeners() {
        const buyButtons = this.getBuyButtons();

        buyButtons.forEach(button => {
            button.addEventListener('click', () => {
                const siteId = document.body.getAttribute('data-site-id');
                const productId = document.querySelector('h1').getAttribute('data-product-id');
                const productSize = document.querySelector('h1').getAttribute('data-checked-size');
                const shopName = button.parentNode.querySelector('.ps-online-seller-cell > img') &&
                    button.parentNode.querySelector('.ps-online-seller-cell > img').title;
                let dataLayer = window.dataLayer = window.dataLayer || [];

                if (Utils.isEukanuba()) {
                    dataLayer.push({
                        'event': siteId + 'dealerFinderEretailerClick',
                        product: {
                            'id': productId,
                            'variant': productSize,
                        },
                        interaction: {
                            'category': undefined,
                            'action': undefined,
                            'label': shopName,
                        }
                    });
                }
            });
        });
    }

    init() {
        this.setVariables();
        this.addListeners();
    }
}

// Validation errors messages for window.Parsley && window.Parsley
// Load this after window.Parsley && window.Parsley

window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('nl', {
    dateiso:  "Deze waarde moet een datum in het volgende formaat zijn: (YYYY-MM-DD).",
    date:     "Dit moet een geldige datum zijn in formaat %s.",
    datebeforenow: "Datum moet geldig zijn in het formaat %s en eerder zijn dan nu.",
    dateafternow: "Datum moet geldig zijn in het formaat %s en later zijn dan nu.",
    minwords: "Deze waarde moet minstens %s woorden bevatten.",
    maxwords: "Deze waarde mag maximaal %s woorden bevatten.",
    words:    "Deze waarde moet tussen de %s en %s woorden bevatten.",
    gt:       "Deze waarde moet groter dan %s zijn.",
    gte:      "Deze waarde moet groter zijn dan of gelijk zijn aan %s.",
    lt:       "Deze waarde moet kleiner dan %s zijn.",
    lte:      "Deze waarde moet kleiner zijn dan of gelijk zijn aan %s.",
    notequalto: "Deze waarde moet verschillen van %s."
  });
});

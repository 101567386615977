import MapGoogle from './implementations/mapGoogle';
import MapBaidu from './implementations/mapBaidu';
import MapKakao from './implementations/mapKakao';
import GenericMapBridge from '$Feature/GenericComponents/code/src/mapGeneric/mapBridge';

export default class MapBridge extends GenericMapBridge {
    defineImplementations() {
        this.mapClasses.Google = MapGoogle;
        this.mapClasses.Baidu = MapBaidu;
        this.mapClasses.Kakao = MapKakao;
    }

    initMap() {
        this._implementation.initMap();
    }

    afterMapLoaded(data) {
        this._implementation.afterMapLoaded(data);
    }

    updateData(data, applyBounds) {
        this._implementation.updateData(data, applyBounds);
    }

    setMarkerActive(id) {
        this._implementation.setMarkerActive(id);
    }

    openInfoWindow(pin) {
        this._implementation.openInfoWindow(pin);
    }

    getPinById(id) {
        this._implementation.getPinById(id);
    }
}

import FilterMapGoogle from './implementations/filterMapGoogle';
import FilterMapBaidu from './implementations/filterMapBaidu';
import FilterMapKakao from './implementations/filterMapKakao';
import GenericMapBridge from '$Feature/GenericComponents/code/src/mapGeneric/mapBridge';

export default class FilterWithMapBridge extends GenericMapBridge {
    defineImplementations() {
        this.mapClasses.Google = FilterMapGoogle;
        this.mapClasses.Baidu = FilterMapBaidu;
        this.mapClasses.Kakao = FilterMapKakao;
    }

    initMap() {
        this._implementation.initMap();
    }

    afterMapLoaded(data) {
        this._implementation.afterMapLoaded(data);
    }

    updateData(data, applyBounds, IsGeoPositionEnabled) {
        this._implementation.updateData(data, applyBounds, IsGeoPositionEnabled);
    }

    setMarkerActive(id) {
        this._implementation.setMarkerActive(id);
    }

    openInfoWindow(pin) {
        this._implementation.openInfoWindow(pin);
    }

    getPinById(id) {
        this._implementation.getPinById(id);
    }
}

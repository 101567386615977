import BaseComponent from '$Foundation/src/js/BaseComponent';
import './styles.scss';

export default class MyAccountRegisterStep extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'my-account-register';
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    setVariables() {
        this.continueButton = document.getElementsByClassName('vf-submit-button')[0];
        this.inputChecked = document.querySelector('[name="terms-conditions"]')

    }

    addListeners() {
        console.log(this.continueButton, 'continueButton');
        console.log('this.inputChecked', this.inputChecked);

        this.inputChecked.addEventListener("click", e => this.checkButtonStatus(e))



    }
    checkButtonStatus(e) {
        if(this.inputChecked.checked){
        console.log('event listener ')
        this.continueButton.disabled = false
        }
        else{
            this.continueButton.disabled = true
        }
    }
}   
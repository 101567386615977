import MoveTo from "moveto";
import api from "$Foundation/src/js/api";
import BaseComponent from "$Foundation/src/js/BaseComponent";
import dLScriptsObserver from "$Foundation/src/js/dLScriptsObserver";
import EventEmitter from "$Foundation/src/js/EventEmitter";
import Utils from "$Foundation/src/js/utils";
import "./styles.scss";

export default class ViewAllArticles extends BaseComponent {
    constructor(el) {
        super(el);
        dLScriptsObserver.onLoad(() => {
            this.addCarouselTracking = this.addCarouselTracking.bind(this);
            this.trackCarouselNavigationClick =
                this.trackCarouselNavigationClick.bind(this);
            this.trackCarouselSlideCTAClick =
                this.trackCarouselSlideCTAClick.bind(this);
            this.init();
        });
    }

    static get tagName() {
        return "view-all-articles";
    }

    setVariables() {
        this.isInited = true;
        this.endPointUrl = this.el.getAttribute("data-endpoint");
        this.requestFilter = this.el.getAttribute("data-request-filter");
        this.dataContainer = this.el.querySelector(
            '[data-ref="data-container"]'
        );
        this.selector = '[data-ref="data-container"]';
        this.viewAllButtons = [
            ...this.el.querySelectorAll('[data-ref="view-all-button"]'),
        ];
        this.preloader = this.el.querySelector('[data-ref="preloader"]');
        this.carouselInstances = [
            ...this.el.querySelectorAll("[data-js-carousel]"),
        ];

        this.searchQuery = {
            filter: this.requestFilter,
        };
    }

    addListeners() {
        this.viewAllButtons.forEach((button) => {
            this.addListener(button, "click", (e) =>
                this.handleViewAllBtnClick(e)
            );
        });

        const observer = new MutationObserver((mutations) =>
            this.handleCarouselUpdates(mutations)
        );
        const carouselTargets = document.querySelectorAll(
            ".rc-carousel.rc-carousel--cards"
        );
        carouselTargets.forEach((carouselTarget) =>
            observer.observe(carouselTarget, {
                attributes: true,
                childList: true,
                subtree: true,
            })
        );
    }

    handleCarouselUpdates(mutations) {
        if (mutations && mutations.length > 0) {
            const allCarouselsItemsUpdates =
                this.getAllCarouselsItemsUpdates(mutations);
            if (allCarouselsItemsUpdates.length > 0) {
                const targetCarousels = this.getParentCarousels(
                    allCarouselsItemsUpdates
                );
                targetCarousels.forEach((carouselContainer) =>
                    this.handleViewAllBtnVisibility(carouselContainer)
                );
            }
        }
    }

    getAllCarouselsItemsUpdates(mutations) {
        return mutations.filter(
            (x) =>
                x.type === "attributes" &&
                x.attributeName === "class" &&
                x.target.classList.contains("rc-card__link") &&
                x.target.classList.contains("tns-item")
        );
    }

    getParentCarousels(mutations) {
        const targetParents = mutations.map((x) => x.target.parentElement);
        return [...new Set(targetParents)];
    }

    handleViewAllBtnVisibility(carouselContainer) {
        const viewAllButton =
            carouselContainer.offsetParent.parentElement.querySelector(
                ".rc-styled-link--cta.rc-md-up"
            );
        const hiddenCarouselItems =
            carouselContainer.querySelectorAll('[tabindex="-1"]');
        if (hiddenCarouselItems && hiddenCarouselItems.length > 0) {
            this.displayBtn(viewAllButton);
        } else {
            this.hideBtn(viewAllButton);
        }
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.addCarouselTracking();
    }

    trackCarouselSlideCTAClick(btnEl) {
        const carouselTitle = this.el.querySelector(
            ".rc-carousel__header-title"
        );

        const card = btnEl.target.closest(".rc-card");
        const cardTitle = card.querySelector(".rc-card__title");

        const slideIndex = card ? card.getAttribute("id").substr(9) : undefined;

        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: "carouselInteraction",
            carouselInteraction: {
                name: "buttonClick",
                buttonLabel: btnEl.target.innerText,
                carousselType: "Article",
                carouselTitle:
                    carouselTitle && carouselTitle?.innerText
                        ? carouselTitle.innerText
                        : undefined,
                slideId: slideIndex,
                cardTitle: cardTitle.innerText,
            },
        });
    }

    trackCarouselNavigationClick() {
        const currentActiveSlideIndex = this.el.querySelector(
            ".tns-nav button.tns-nav-active"
        );
        const carouselTitle = this.el.querySelector(
            ".rc-carousel__header-title"
        );
        if (currentActiveSlideIndex) {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                event: "carouselInteraction",
                carouselInteraction: {
                    name: "slideLoad",
                    slideId:
                        parseInt(
                            currentActiveSlideIndex.getAttribute("data-nav")
                        ) + 1,
                    carouselTitle: carouselTitle
                        ? carouselTitle.innerText
                        : undefined,
                    carousselType: "Article",
                },
            });
        }
    }

    addCarouselTracking() {
        const navigationButtons = this.el.querySelectorAll(
            'button[data-controls="next"], button[data-controls="prev"], .tns-nav button'
        );
        const contentButtons = this.el.querySelectorAll(
            ".rc-card_footer__link"
        );
        for (let i = 0; i < navigationButtons.length; i++) {
            navigationButtons[i].addEventListener(
                "click",
                this.trackCarouselNavigationClick
            );
        }
        for (let i = 0; i < contentButtons.length; i++) {
            contentButtons[i].addEventListener(
                "click",
                this.trackCarouselSlideCTAClick
            );
        }
    }

    removeCarouselTracking() {
        const navigationButtons = document.querySelectorAll(
            '[data-component="view-all-articles"] [data-controls="next"], [data-component="view-all-articles"] [data-controls="prev"], [data-component="view-all-articles"] .tns-nav button'
        );
        const contentButtons = document.querySelectorAll(
            '[data-component="view-all-articles"] .rc-card_footer__link'
        );
        for (let i = 0; i < navigationButtons.length; i++) {
            navigationButtons[i].removeEventListener(
                "click",
                this.trackCarouselNavigationClick
            );
        }
        for (let i = 0; i < contentButtons.length; i++) {
            contentButtons[i].removeEventListener(
                "click",
                this.trackCarouselSlideCTAClick
            );
        }
    }

    handleViewAllBtnClick(e) {
        e.preventDefault();
        this.showPreloader();
        const queryString = Utils.convertToQuery(
            this.searchQuery,
            this.endPointUrl
        );
        const url = `${this.endPointUrl}${queryString}`;

        this.getData("GET", {}, url)
            .then((result) => {
                this.updateListing(result.data);
                this.hidePreloader();
            })
            .then(this.scrollElIntoView());
    }

    async getData(method, data, url) {
        try {
            return await api({
                method,
                url,
                data,
            });
        } catch (err) {
            this.hidePreloader();
            throw new Error(err);
        }
    }

    updateListing(data) {
        EventEmitter.emit("content:willRemove", this.dataContainer);

        if (this.carouselInstances.length > 0) {
            this.destroyCarousels();
        }

        const dummy = document.createElement("div");
        dummy.innerHTML = data;

        const container = dummy.querySelector(`${this.selector}`);

        const html = container ? container.innerHTML : "";
        this.dataContainer.innerHTML = html;
    }

    scrollElIntoView() {
        if (window.pageYOffset > this.el.offsetTop) {
            setTimeout(() => {
                const header = document.querySelector(
                    "[data-js-header-scroll]"
                );
                const headerOffset = header
                    ? header.getBoundingClientRect().height
                    : 0;

                const moveTo = new MoveTo({
                    tolerance: headerOffset,
                });
                const target = this.el;
                moveTo.move(target);
            }, 300);
        }
    }

    showPreloader() {
        const DLClass = Utils.getDLClass();
        this.preloader.classList.remove(`${DLClass}-hidden`);
    }

    hidePreloader() {
        const DLClass = Utils.getDLClass();
        this.preloader.classList.add(`${DLClass}-hidden`);
    }

    destroyCarousels() {
        const carouselInstancesWithinContainer = [
            ...this.el.querySelectorAll("[data-js-carousel]"),
        ];
        const carouselInstancesIds = carouselInstancesWithinContainer.map(
            (instance) => instance.getAttribute("id")
        );

        const DLOBject = Utils.getDLObject();
        const RCDLCarouselInstances = DLOBject.features.Carousel.instance;
        Object.keys(RCDLCarouselInstances).forEach((i) => {
            const instance = RCDLCarouselInstances[i];

            if (instance.isOn) {
                if (
                    carouselInstancesIds.includes(
                        instance.getInfo().container.id
                    )
                ) {
                    instance.destroy();
                }
            }
        });
    }

    onDestroy() {
        while (this.dataContainer.firstChild) {
            this.dataContainer.firstChild.remove();
        }
        this.preloader = null;
        this.dataContainer = null;
        this.viewAllButtons = null;
        this.el = null;
        this.removeCarouselTracking();
    }

    displayBtn(elem) {
        if (elem) {
            elem.classList.remove("rc-hidden");
        }
    }

    hideBtn(elem) {
        if (elem) {
            elem.classList.add("rc-hidden");
        }
    }
}

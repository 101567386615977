import './styles.scss';
import GTM from '$Foundation/src/js/gtm/gtmVetFocusIssueCard';
import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class VetFocusIssueCard extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'vetfocus-issue-card';
    }

    setVariables() {
        this.vetFocusIssueCardCTA = this.el.querySelector('[data-ref="issue-card-cta"]');
    }

    addListeners() {
        this.vetFocusIssueCardCTA && this.vetFocusIssueCardCTA.addEventListener('click', e => this.pushGTM(e));
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.initContentBlock();
    }

    initContentBlock() {
        console.log('Module inited: vetfocus-issue-card');
    }
    
    pushGTM(e) {
        const id = e.currentTarget.getAttribute('data-track-id');
        const publishDate = e.currentTarget.getAttribute('data-track-publish-date');
        const masterName = e.currentTarget.getAttribute('data-track-master-name');
        const format = e.currentTarget.getAttribute('data-track-format');
        const type = e.currentTarget.getAttribute('data-track-type');
        GTM.push('PDFs', 'Download', 'Issues', id, publishDate, masterName, format, type, 'downloadPDF');
    }
}

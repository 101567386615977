import './styles.scss';
import '$Feature/GenericComponents/code/src/autocomplete/styles.scss';
import EventEmitter from '$Foundation/src/js/EventEmitter';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import Awesomplete from 'awesomplete';

export default class ManualRegion extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'manual-region';
    }

    setVariables() {
        this.input = this.el.querySelector('[data-ref="input"]');
        this.cityLinks = [...this.el.querySelectorAll('[data-ref="city"]')];
        this.cityList = [];
        this.currentCityName = null;

        this.searchButtonNode = this.el.querySelector('[data-ref="search-button"]');
        this.searchWrapper = this.el.querySelector('[data-ref="search-wrapper"]');
        this.closeButton = this.el.querySelector('[data-modal-trigger="modal-region-picker"]');
        this.mainNavModal = document.querySelector('[data-js-modal-menu]');
        this.openModalLink = this.mainNavModal && this.mainNavModal.querySelector('[data-modal-trigger="modal-region-picker"]');
        this.minChars = 2;

        this.manualRegionCookieName = 'ManualRegion';
        this.browserRegionCookieName = 'BrowserRegion';
        this.serverRegionCookieName = 'ServerRegion';

        this.currentRegion = null;
        this.regionPickerLinks = [...document.querySelectorAll('[data-modal-trigger="modal-region-picker"]:not([data-ref="mrp-static-name"])')];
        this.regionPickerNames = [...document.querySelectorAll('[data-ref="region-picker-name"]')];
        this.regionPickerTooltipLink = document.querySelector('[data-tooltip="region-tooltip"]');
    }

    addListeners() {
        this.cityLinks.forEach((link) => {
            this.addListener(link, 'click', e => this.onCityLinkClick(e));
        });
        this.addListener(this.input, 'input', e => this.handleInputFill(e));
        this.addListener(this.input, 'awesomplete-selectcomplete', e =>
            this.handleAwesompleteSelectComplete(e));
        this.addListener(this.searchButtonNode, 'click', e => this.handleSearchButtonClick(e));
        this.addListener(this.openModalLink, 'click', e => this.handleOpenModalLink(e));
        EventEmitter.subscribe('region:get', this.updatePickerLinksName.bind(this));
        this.regionPickerLinks.forEach((link) => {
            this.addListener(link, 'click', e => this.handleRegionPickerLinkClick(e));
        });

        this.addListener(this.regionPickerTooltipLink, 'keyup', e => this.handleRegionPickerTooltipLinkKeyUp(e));
        this.addListener(this.regionPickerTooltipLink, 'click', e => this.handleRegionPickerTooltipLinkClick(e));
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.updatePickerLinksName();
        setTimeout(() => {
            this.initAutocomplete();
        }, 200);
    }

    updatePickerLinksName() {
        this.getCurrentRegion();
        this.regionPickerLinks = [...this.regionPickerLinks, ...this.regionPickerNames];

        if ( this.regionPickerLinks !== undefined && this.regionPickerLinks.length !== 0 && this.currentRegion) {
            this.regionPickerLinks.forEach((item) => {
                const itemRegionSpan = item.querySelector('[data-ref="region-picker-name"]');
                if (itemRegionSpan !== null ) {
                    itemRegionSpan.textContent = this.currentRegion;
                } else {
                    item.textContent = this.currentRegion;
                    item.title = this.currentRegion;
                }
            });
        }
    }

    initAutocomplete() {
        this.getCityList();
        this.currentCityName = this.currentRegion;
        this.autoCompleteInstanse = new Awesomplete(this.input, {
            list: this.cityList
        });
        this.autoCompleteInstanse.container.className += ' autocomplete';
        this.autoCompleteInstanse.ul.classList.add('autocomplete__list');
    }

    getCityList() {
        this.cityLinks.forEach((node) => {
            this.cityList.push(node.textContent);
        });
    }

    getCurrentRegion() {
        let currentRegion = '';
        const manualRegionCookie = this.getCookie(this.manualRegionCookieName);
        const browserRegionCookie = this.getCookie(this.browserRegionCookieName);
        const serverRegionCookie = this.getCookie(this.serverRegionCookieName);

        if (manualRegionCookie !== undefined) {
            currentRegion = decodeURIComponent(manualRegionCookie);
        } else if (browserRegionCookie !== undefined) {
            currentRegion = decodeURIComponent(browserRegionCookie);
        } else if (serverRegionCookie !== undefined) {
            currentRegion = decodeURIComponent(serverRegionCookie);
            currentRegion = currentRegion.replace(new RegExp('\\+', 'g'), ' ');
            if (currentRegion === 'Serverside location detection failed') {
                currentRegion = null;
            }
        }
        this.currentRegion = currentRegion;
    }

    getCookie(name) {
        let value = '; ' + document.cookie;
        let parts = value.split('; ' + name + '=');
        if (parts.length == 2) {
            return parts.pop().split(';').shift();
        }
    }


    onCityLinkClick(e) {
        e.preventDefault();
        const cityName = e.target.textContent;
        this.onCitySelect(cityName);
    }

    onCitySelect(cityName) {
        if (cityName !== this.currentCityName) {
            this.setCookie(this.manualRegionCookieName, cityName);
            this.closeAndReload();
        } else {
            this.close();
        }
    }
    close() {
        this.closeButton.click();
    }

    closeAndReload() {
        this.closeButton.click();
        location.reload(true);
    }

    setCookie(cookieName, cookievalue) {
        const CookieDate = new Date();
        CookieDate.setFullYear(CookieDate.getFullYear() + 1); // expire in 1 year
        document.cookie = `${cookieName}=${encodeURIComponent(cookievalue)}; expires=${CookieDate.toGMTString()};path=/`;
    }

    handleSearchButtonClick(e) {
      e.preventDefault();
    }

    handleAwesompleteSelectComplete(e) {
        this.isValueSelected = true;
        const cityName = e.text.value;
        this.onCitySelect(cityName);
    }

    handleInputFill(e) {
        if (e.target.value.trim() !== '') {
            this.searchWrapper.classList.add('is-filled');
        } else {
            this.searchWrapper.classList.remove('is-filled');
        }
    }

    handleOpenModalLink(e) { // close main nav if region picker is triggered from main nav
        this.mainNavModal.querySelector('[data-modal-trigger="main-nav-modal"]').click();
    }

    handleRegionPickerLinkClick(e) {
       e.preventDefault();
    }

    handleRegionPickerTooltipLinkClick(e) {
        e.preventDefault();
    }

    handleRegionPickerTooltipLinkKeyUp(e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            e.preventDefault();
            this.openModalLink.click();
            setTimeout(() => {
                this.input.focus();
            }, 200);
        }
    }

}

import './styles.scss';

import BaseComponent from '$Foundation/src/js/BaseComponent';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';
export default class SocialSharing extends BaseComponent {
    constructor(el) {
        super(el);
        dLScriptsObserver.onLoad(() => {
            this.init();
        });
    }

    static get tagName() {
        return 'social-sharing';
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    addListeners() {   
        this.addListener(this.copyToClipboardButton, 'click', e => this.handleCopyButtonClick(e));  
    }

    setVariables() {
        this.copyToClipboardButton = this.el.querySelector('.custom-copy-link');

    }

    handleCopyButtonClick(e) {
        e.preventDefault();

        let dummy = window.document.createElement('input');
        let text = window.location.href;

        window.document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        window.document.execCommand('copy');
        window.document.body.removeChild(dummy);
    }
}

import BaseComponent from '$Foundation/src/js/BaseComponent';
import GTM from '$Foundation/src/js/gtm/gtm';

export default class CtaEventTracker extends BaseComponent {
    constructor(el) {
        super(el);
        this.trackCategory = null;
        this.trackLabel = null;
        this.trackEvent = null;
        this.trackCustom = null;
        this.trackAction = null;
        this.elText = null;
        this.pageName = document.title;
        this.init();
    }

    static get tagName() {
        return 'cta-event-tracker';
    }

    init() {
        this.el.addEventListener('click', this.onClick.bind(this));
    }

    onClick() {
        const url = location.pathname || "";
    
        this.standardTracking();
        this.customTracking();
        this.definedCustomTracking();
        //Check for virtual-campus-tour page to enable campus tour specific analytics
        if (url.includes('virtual-campus-tour')) {
            this.campusTourTracking();
        }
    }

    campusTourTracking() {
        let dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event' : 'campusTourLoginButtonClick',
            'campusTourLoginButtonClickType' : this.elText
        });
    }

    definedCustomTracking() {
        const lang = location.pathname.split('/').length >= 2 ? location.pathname.split('/')[1] : "";
        let bannerTitle = this.el.getAttribute('data-track-banner-title') || "";       
        
        if (lang === 'fr') {
            let dataLayer = window.dataLayer = window.dataLayer || [];

            dataLayer.push({
                'event': 'bannerClick',
                component:{
                    'title': bannerTitle
                }
            });
        };
    }

    standardTracking() {
        this.elText = this.el.innerText || this.el.textContent;

        this.trackCategory = this.el.getAttribute('data-track-category')
            ? this.el.getAttribute('data-track-category')
            : this.pageName;

        this.trackLabel = this.el.getAttribute('data-track-label')
            ? this.el.getAttribute('data-track-label')
            : this.elText;

        this.trackEvent = this.trackCategory + ' ' + this.trackLabel;

        GTM.push(this.trackCategory, 'Click', this.trackLabel, this.trackEvent);
        
    }

    customTracking() {
    
        this.trackCustom = this.el.getAttribute('data-track-custom');
       
        if (!this.trackCustom) return;
        
        this.trackCategory = this.el.getAttribute('data-track-custom-category');
        this.trackAction = this.el.getAttribute('data-track-custom-action');
        this.trackLabel = this.el.getAttribute('data-track-custom-label').replace(/(^\w+:|^)\/\//, '');
        this.trackEvent = this.el.getAttribute('data-track-custom-event');

        GTM.push(this.trackCategory, this.trackAction, this.trackLabel, this.trackEvent);
        
    }

    onDestroy() {
        this.el = null;
    }
}

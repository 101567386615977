import BaseComponent from '$Foundation/src/js/BaseComponent';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';

export default class ButtonSwitch extends BaseComponent {
    constructor(el) {
        super(el);
        this.checkboxes = document.querySelectorAll('[button-switch]');
        this.isClickInit = null;
        dLScriptsObserver.onLoad(() => {
            this.init();
        });
    }

    static get tagName() {
        return 'button-switch';
    }

    init() {
        this.addListeners();
    }

    addListeners() {
        this.checkboxes.forEach((checkbox) => {
            this.addListener(checkbox, 'click', e => this.onCheckboxClick(e.target));
        });
    }

    onCheckboxClick(e) {
        const buttonId = e.getAttribute('button-switch');
        if (buttonId && buttonId.length) {
            let button = document.getElementById(buttonId);

            if (button == undefined) return;

            if (e.checked) {
                button.removeAttribute('disabled');
            } else {
                button.setAttribute('disabled','');
            }
        }
    }
}


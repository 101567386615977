import EventEmitter from '$Foundation/src/js/EventEmitter';

export default class Gmap {
    constructor(el, options) {
        this.el = el;
        this.options = options;
        this.gmap = null;
        this.marker = null;
    }

    initApi() {
        this.apiReadyPromise = new Promise((apiResolve) => {
            if (typeof window.gmapInitialize === 'undefined' || !Gmap.isGmapReady) {
                Gmap.loadScript(apiResolve);
            } else {
                apiResolve();
            }
        });
        return this.apiReadyPromise;
    }

    triggerResize() {
        window.google.maps.event.trigger(this.gmap, 'resize');
    }

    setZoom(zoom) {
        this.gmap.setZoom(zoom);
    }

    static loadScript(callback) {
        Gmap.callbacks.push(callback);
        if (Gmap.isScriptInserted === true) {
            return;
        }

        Gmap.isScriptInserted = true;

        const gmapApiKey =
            window.initialWebsiteConfig && window.initialWebsiteConfig.googleMapApiKey
                ? window.initialWebsiteConfig.googleMapApiKey
                : '';

        const mapscript = document.createElement('script');
        mapscript.type = 'text/javascript';
        mapscript.async = true;
        mapscript.src = `//maps.googleapis.com/maps/api/js?key=${gmapApiKey}&callback=gmapInitialize&libraries=places`;

        document.body.appendChild(mapscript);
        window.gmapInitialize = Gmap.onScriptLoaded;
    }

    static onScriptLoaded() {
        for (let i = 0, l = Gmap.callbacks.length; i < l; i++) {
            Gmap.callbacks[i]();
        }
        Gmap.isGmapReady = true;
        window.mapIsInitialized = true;
        EventEmitter.emit('map:initialized');
    }
}

Gmap.isScriptInserted = false;
Gmap.isGmapReady = false;
Gmap.callbacks = [];

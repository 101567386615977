import Modernizr from "modernizr";
import EventEmitter from "$Foundation/src/js/EventEmitter";

export const DEVICE_TYPE_DESKTOP = "desktop";
export const DEVICE_TYPE_TABLET = "tablet";
export const DEVICE_TYPE_MOBILE = "mobile";

export const EVENT_DEVICE_TYPE_CHANGED = "device-type-changed";
export const EVENT_RESIZE_FINISHED = "resize:finished";

class ViewportInfo {
  constructor() {
    this.resized = false;
    this.timeout = 150;
    this.timeoutId = null;
    this.init();
  }

  init() {
    this.setGlobals();
    this.setVariables();
    window.addEventListener("resize", this.handleResize.bind(this));
    window.addEventListener("load", this.handleOnload.bind(this));
  }

  setGlobals() {
    this.isTouch = Modernizr.touchevents;
    this.viewport = {
      width: window.outerWidth,
      height: window.outerHeight,
      orientation:
        typeof window.orientation === "undefined" ||
        Math.abs(window.orientation) === 90
          ? "landscape"
          : "portrait",
    };

    this.isDesktop = Modernizr.mq("only screen and (min-width:769px)");

    if (this.isDesktop) {
      this.isTablet = true;
    } else {
      this.isTablet = Modernizr.mq("only screen and (min-width:768px)");
    }

    if (this.isDesktop) {
      this.deviceTypeByViewport = DEVICE_TYPE_DESKTOP;
    } else if (this.isTablet) {
      this.deviceTypeByViewport = DEVICE_TYPE_TABLET;
    } else {
      this.deviceTypeByViewport = DEVICE_TYPE_MOBILE;
    }
  }

  setVariables() {
    this.connectedPackSustainability = document.querySelector(
      '[name="ConnectedPackSustainability"]'
    );
    this.connectedPackRecyclability = document.querySelector(
      '[name="ConnectedPackRecyclability"]'
    );
  }

  handleResize() {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }

    this.resized = true;
    this.timeoutId = setTimeout(this.onResized.bind(this), this.timeout);
  }

  handleOnload() {
    const hash = location.hash.substr(1);
    setTimeout(() => {
      if (hash === "ConnectedPackSustainability") {
        window.scroll(0, this.connectedPackSustainability.offsetTop);
      } else if (hash === "ConnectedPackRecyclability") {
        window.scroll(0, this.connectedPackRecyclability.offsetTop);
      }
    }, this.timeout);
  }

  onResized() {
    const oldDeviceType = this.deviceTypeByViewport;
    this.setGlobals();
    EventEmitter.emit(EVENT_RESIZE_FINISHED);
    if (oldDeviceType !== this.deviceTypeByViewport) {
      EventEmitter.emit(EVENT_DEVICE_TYPE_CHANGED, {
        oldDeviceType,
        devicetype: this.deviceTypeByViewport,
      });
    }
  }
}

const instance = new ViewportInfo();

export default instance;

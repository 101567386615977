import BaseComponent from "$Foundation/src/js/BaseComponent";
import Utils from "$Foundation/src/js/utils";
import { config } from "../../index";
import MediatorEvents from "../enums/mediatorEvents";
import MediatorEventModel from "../models/mediatorEventModel";

export default class FilterStockistsControl extends BaseComponent {
  constructor(el) {
    super(el);
    this.init();
  }
  static get tagName() {
    return "stockists-filter-panel";
  }

  static get constructorName() {
    return "filterStockistsControl";
  }

  init() {
    this.setVariables();
    this.addListeners();
    setTimeout(() => {
      this.initFilters();
    }, 1000);
  }

  setVariables() {
    this.mediator = null;
    this.IsFilterApplied = false;
    //stockist filters
    this.filters = {};

    this.filterButton = this.el.querySelector(
      '[data-ref="stockist-filter-button"]'
    );

    this.filterResultBox = this.el.querySelector(
      '[data-ref="stockist-filter-result"]'
    );

    this.filterCloseButton = this.el.querySelector(
      '[data-ref="stockist-close-filter-button"]'
    );
    this.filterDiscardButton = this.el.querySelector(
      '[data-ref="stockist-discard-filter-button"]'
    );
    this.filterClearButton = this.el.querySelectorAll(
      '[data-ref="stockist-clear-filter-button"]'
    );
    this.filterApplyButton = this.el.querySelector(
      '[data-ref="stockist-apply-filter-button"]'
    );
    this.filterActiveState = this.el.querySelector(
      '[data-ref="stockist-filters-active"]'
    );
    this.filterActiveNum = this.el.querySelector(
      '[data-ref="stockist-filters-active-filters-num"]'
    );
    this.filterEditActive = this.el.querySelector(
      '[data-ref="stockist-edit-filter-button"]'
    );
    this.filterClearAllActive = this.el.querySelector(
      '[data-ref="stockist-clear-all-filters-button"]'
    );
    this.checkboxes = [
      ...this.el.querySelectorAll('[data-ref="stockist-filter-checkbox"]'),
    ];
    this.categories = [
      ...this.el.querySelectorAll('[data-ref="filter-category"]'),
    ];

    this.isHistoryApiEnabled = !!(window.history && window.history.pushState);
  }

  setMediator(mediator) {
    this.mediator = mediator;
  }

  addListeners() {
    this.addListener(this.filterEditActive, "click", (e) =>
      this.handleEditActiveFilters(e)
    );
    this.addListener(this.filterClearAllActive, "click", (e) =>
      this.handleClearAllActiveFilters(e)
    );
    this.addListener(this.filterApplyButton, "click", (e) =>
      this.handleApplyFilters(e)
    );
    this.addListener(this.filterButton, "click", () =>
      this.handleFilterButtonClick()
    );
    this.addListener(this.filterCloseButton, "click", () =>
      this.handleFilterCloseButtonClick()
    );
    this.addListener(this.filterDiscardButton, "click", () =>
      this.handleFilterDiscardButtonClick()
    );
    this.checkboxes.forEach((item) => {
      this.addListener(item, "change", (e) =>
        item.checked
          ? this.handleCheckboxChecked(e)
          : this.handleCheckboxUncheck(e)
      );
    });
    this.filterClearButton.forEach((button) => {
      this.addListener(button, "click", (e) =>
        this.handleFilterCategoryClearButtonClick(e)
      );
    });
  }

  handleFilterButtonClick() {
    this.filterButton.classList.toggle("rc-hidden");
    this.filterResultBox.classList.toggle("is-opened");
  }

  handleFilterCloseButtonClick() {
    this.filterResultBox.classList.toggle("is-opened");
    this.getFiltersFromUrl();
    if (Object.keys(this.filters).length !== 0) {
      this.filterActiveState.classList.toggle("rc-hidden");
    } else {
      this.filterButton.classList.toggle("rc-hidden");
    }
    this.handleDatalayerEvent("Close Filter");
  }

  handleEditActiveFilters() {
    this.filterResultBox.classList.toggle("is-opened");
    this.filterActiveState.classList.toggle("rc-hidden");
  }

  handleFilterCategoryClearButtonClick(e) {
    const filterCategoryNode = Utils.getClosestNodes(
      e.target,
      '[data-ref="filter-category"]'
    )[0];
    const filterCategoryId = filterCategoryNode
      .getAttribute("data-filter-category")
      .toLowerCase();

    filterCategoryNode
      .querySelectorAll('[data-ref="stockist-filter-checkbox"]')
      .forEach((item) => (item.checked = false));

    delete this.filters[filterCategoryId];
  }

  handleClearAllActiveFilters() {
    this.filterButton.classList.toggle("rc-hidden");
    this.filterActiveState.classList.toggle("rc-hidden");
    this.handleClearFilters();
  }

  handleClearFilters() {
    this.clearFilters();
    this.clearCheckboxes();
    this.IsFilterApplied = false;
    this.handleMediatorEvent();
    this.handleDatalayerEvent("Clear all");
  }

  handleFilterDiscardButtonClick() {
    this.filterButton.classList.toggle("rc-hidden");
    this.filterResultBox.classList.toggle("is-opened");
    this.handleClearFilters();
  }

  handleApplyFilters() {
    this.handleFilterActiveState();
    this.IsFilterApplied = (this.checkboxes.filter((item) => item.checked).length > 0);
    this.handleMediatorEvent();
    this.handleDatalayerEvent("Apply filters");
  }

  handleMediatorEvent() {
    const mediatorEvent = new MediatorEventModel();
    const searchQuery = config.filterPanelControl.getSearchQuery();
    const urlParams = new URLSearchParams(window.location.search);
    const contactTypes =
      urlParams.get("contacttypes") && urlParams.get("contacttypes");
    const sortResultsBy =
      urlParams.get("sortresultsby") && urlParams.get("sortresultsby");

    Object.assign(searchQuery, ...config.requestParameters);

    if (config.filterSearchControl) {
      searchQuery.searchQuery = config.filterSearchControl.getInputValue();
    }

    mediatorEvent.searchQuery = searchQuery;
    const bounds = config.state.map.bounds;
    if (Array.isArray(bounds)) Object.assign(searchQuery, ...bounds);
    searchQuery.latitude = config.state.map.latitude;
    searchQuery.longitude = config.state.map.longitude;
    searchQuery.currentLatitude = config.state.map.currentLatitude;
    searchQuery.currentLongitude = config.state.map.currentLongitude;
    searchQuery.page = urlParams.get("page");

    if (contactTypes) {
      searchQuery.contacttypes = contactTypes;
    }

    if (sortResultsBy) {
      searchQuery.sortresultsby = sortResultsBy;
    }

    searchQuery.isLocal =
      searchQuery.searchQuery != null && searchQuery.searchQuery.length > 0
        ? false
        : true;

    for (const [key, value] of Object.entries(this.filters)) {
      searchQuery[key] = value;
    }

    if (this.IsFilterApplied) searchQuery.IsFilterApplied = true;

    const queryString = Utils.convertToQuery(searchQuery);

    mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
    mediatorEvent.eventType = MediatorEvents.filterChanged;

    if (config.mediator) {
      config.mediator.stateChanged(mediatorEvent);
    }
  }

  createMediatorEventModel(eventType, data) {
    const mediatorEvent = new MediatorEventModel();
    const searchQuery = config.filterPanelControl.getSearchQuery();
    Object.assign(searchQuery, ...config.requestParameters);
    searchQuery.searchQuery = config.filterSearchControl.getInputValue();

    if (data && data.viewAll) {
      searchQuery.viewAll = data.viewAll;
    }
    mediatorEvent.searchQuery = searchQuery;

    const queryString = Utils.convertToQuery(searchQuery);
    mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
    mediatorEvent.eventType = MediatorEvents[`${eventType}`];

    return mediatorEvent;
  }

  handleFilterActiveState() {
    this.filterActiveNum.innerHTML = `(${
      this.checkboxes.filter((item) => item.checked).length
    })`;

    this.filterButton.classList.toggle("rc-hidden");
    this.filterActiveState.classList.toggle("rc-hidden");
  }

  handleCheckboxChecked(e) {
    const filterCategoryId = Utils.getClosestNodes(
      e.target,
      '[data-ref="filter-category"]'
    )[0]
      .getAttribute("data-filter-category")
      .toLowerCase();
    if (this.filters[filterCategoryId] === undefined)
      this.filters[filterCategoryId] = [];
    this.filters[filterCategoryId].push(e.target.value);
  }

  handleCheckboxUncheck(e) {
    const filterCategoryId = Utils.getClosestNodes(
      e.target,
      '[data-ref="filter-category"]'
    )[0]
      .getAttribute("data-filter-category")
      .toLowerCase();
    if (this.filters[filterCategoryId] === undefined)
      this.filters[filterCategoryId] = [];
    if (this.filters[filterCategoryId].indexOf(e.target.value) > -1) {
      this.filters[filterCategoryId].splice(
        this.filters[filterCategoryId].indexOf(e.target.value),
        1
      );
    }
    if (this.filters[filterCategoryId].length === 0) {
      delete this.filters[filterCategoryId];
    }
  }

  initFilters() {
    this.getFiltersFromUrl();
    if (Object.keys(this.filters).length !== 0) {
      this.handleFilterActiveState();
    }
  }

  getFiltersFromUrl() {
    const params = new URLSearchParams(document.location.search.substring(1));
    const allCategories = this.getAllCategories();
    this.clearCheckboxes();

    allCategories.forEach((category) => {
      if (this.filters[category] === undefined) this.filters[category] = [];

      if (params.get(category) !== null) {
        params
          .get(category)
          .split(",")
          .forEach((item) => {
            if (this.filters[category].indexOf(item) === -1)
              this.filters[category].push(item);
            this.checkboxes.forEach((checkbox) => {
              if (checkbox.value === item) checkbox.checked = true;
            });
          });
      } else {
        delete this.filters[category];
      }
    });
  }

  getAllCategories() {
    return this.categories.map((item) =>
      item.getAttribute("data-filter-category").toLowerCase()
    );
  }

  clearCheckboxes() {
    this.checkboxes.forEach((item) => (item.checked = false));
  }

  clearFilters() {
    this.filters = {};
  }

  getAllFilters() {
    return this.filters;
  }

  handleDatalayerEvent(event) {
    let dataLayer = (window.dataLayer = window.dataLayer || []);
    dataLayer.push({
      event: "RCGlobalMC1dealerFinderFilterInteraction",
      dealerFinderFilterInteraction: event, //'Close Filter', 'Clear all', 'Apply filters', depending on clicked button
    });
  }
}

import BaseComponent from "$Foundation/src/js/BaseComponent";

export default class NavigationItemsList extends BaseComponent {
  constructor(el) {
    super(el);
    this.init();
    this.isInited = true;
  }

  static get tagName() {
    return "navigation-items-list";
  }

  init() {
    const list = this.el.querySelector("ul");

    if (list.childElementCount > 3) {
      this.el.style.alignItems = "flex-start";
    } else {
      this.el.style.alignItems = "center";
    }
  }
}

import MapGenericGoogle from '$Feature/GenericComponents/code/src/mapGeneric/implementations/mapGenericGoogle';

export default class MapGoogle extends MapGenericGoogle {
    initMap() {
        const latLng = new google.maps.LatLng(this.options.center);

        const gmapOptions = {
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
            zoom: this.options.zoom,
            draggable: true
        };

        this.gmap = new window.google.maps.Map(this.el, gmapOptions);
        this.marker = new google.maps.Marker({ position: this.options.center, map: this.gmap });
    }
}

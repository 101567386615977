import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class TencentVideo extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'tencent-video';
    }

    setVariables() {
        this.video = this.el.querySelector('[data-ref="video"]');
        this.poster = this.el.querySelector('[data-ref="poster"]');
        this.playButton = this.el.querySelector('[data-ref="video-play-button"]');
    }

    addListeners() {        
        this.addListener(this.playButton, 'click', e => this.handlePlayButtonClick(e));
    }

    init() {           
        this.setVariables();
        this.addListeners();
    }
   
    handlePlayButtonClick(e) {
        e.preventDefault();
        this.poster.classList.add('rc-hidden');
        this.video.src += "&autoplay=true";        
    };
}

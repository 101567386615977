// Validation errors messages for window.Parsley && window.Parsley
// Load this after window.Parsley && window.Parsley

window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('uk', {
    dateiso:  "Це значення має бути коректною датою (РРРР-ММ-ДД).",
    minwords: "Це значення повинно містити не менше %s слів.",
    maxwords: "Це значення повинно містити не більше %s слів.",
    words:    "Це значення повинно містити від %s до %s слів."
  });
});

import BaseComponent from "$Foundation/src/js/BaseComponent";
import dLScriptsObserver from "$Foundation/src/js/dLScriptsObserver";

import "./styles.scss";

export default class BackToTop extends BaseComponent {
    constructor(el) {
        super(el);
        dLScriptsObserver.onLoad(() => {
            this.init();
        });
    }

    static get tagName() {
        return "back-to-top";
    }

    setVariables() {
        this.scrollLimit = 700;
        this.scrollStopHeight = 0;
    }

    addListeners() {
        this.addListener(window, "scroll", () => this.handleButtonBehaviour());
        this.addListener(this.el, "click", () => this.handleClick());
    }

    init() {
        this.setVariables();
        this.addListeners();

        if (window.scrollY > this.scrollLimit) {
            this.el.classList.add("visible");
        }

        this.el.parentElement.style.position = "relative";
    }

    handleButtonBehaviour() {
        this.handleButtonVisibility();
        this.handleButtonPosition();
    }

    handleButtonVisibility() {
        if (window.scrollY > this.scrollLimit) {
            this.el.classList.add("visible");
        } else {
            this.el.classList.remove("visible");
        }
    }

    handleButtonPosition() {
        const buttonPosition = this.el.getBoundingClientRect();
        const parentPosition = this.el.parentElement.getBoundingClientRect();
        const bottomLimit = parentPosition.bottom - 50;

        if (buttonPosition.bottom > bottomLimit) {
            this.el.classList.add("bottom-limit");
            this.scrollStopHeight = window.scrollY;
        } else if (this.scrollStopHeight > window.scrollY) {
            this.el.classList.remove("bottom-limit");
        }
    }

    handleClick() {
        // Bugs fix for ios safary
        const to = setInterval(() => {
            document.body.scrollTop = -1;

            // clear timeout after 250 ms and reset scrollTop to 0
            if (i++ > 25) {
                clearInterval(to);
                document.body.scrollTop = 0;
            }
        }, 10);

        this.handleAnalitycs();
    }

    handleAnalitycs() {
        let dataLayer = (window.dataLayer = window.dataLayer || []);
        dataLayer.push({
            event: "backToTopWidget",
        });
    }
}

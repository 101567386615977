import 'js-marker-clusterer';
import MapGenericGoogle from '$Feature/GenericComponents/code/src/mapGeneric/implementations/mapGenericGoogle';
import Utils from '$Foundation/src/js/utils';
import ViewportInfo from '$Foundation/src/js/ViewportInfo';
import { config } from '../../../../index';
import infoWindowTemplate from '../../../../templates/infoWindow.hbs';
import eukaInfoWindowTemplate from '../../../../templates/eukaInfoWindow.hbs';
import MediatorEvents from '../../../enums/mediatorEvents';
import MediatorEventModel from '../../../models/mediatorEventModel';
import GTM from '$Foundation/src/js/gtm/gtmStockistDetails';

export default class FilterMapGoogle extends MapGenericGoogle {
    initMap() {
        const latLng = new google.maps.LatLng(this.options.center);

        const gmapOptions = {
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
            zoom: this.options.zoom
        };

        this.gmap = new window.google.maps.Map(this.el, gmapOptions);

        this.cluster = null;
        this.markers = [];
        this.locations = [];
        this.isBoundsSetted = false;
        this.isGeoPositionEnabled = false;
        this.currentPositionCoords = null;
        this.afterIdleTimeout = null;
        this.defaultZoom = 13;
        this.loadingTime = 200;

        this.infoWindowTemplate = Utils.isEukanuba() ? eukaInfoWindowTemplate : infoWindowTemplate;

        this.infoWindow = new window.google.maps.InfoWindow({
            content: ''
        });

        this.attachEvents();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => this.handleSuccessGetPosition(position),
                error => this.handleErrorGetPosition(error)
            );
        } else {
            console.log('Geolocation is not supported by this browser');
        }
    }

    handleSuccessGetPosition(position) {
        this.isGeoPositionEnabled = true;
        this.currentPositionCoords = position;
        const { latitude, longitude } = position.coords;

        const mediatorEvent = new MediatorEventModel();

        mediatorEvent.eventType = MediatorEvents.getCurrentPosition;

        const searchQuery = config.filterPanelControl.getSearchQuery();
        Object.assign(searchQuery, ...config.requestParameters);
        searchQuery.searchQuery = config.filterSearchControl.getInputValue();
        searchQuery.currentLatitude = latitude;
        searchQuery.currentLongitude = longitude;
        config.state.map.currentLatitude = latitude;
        config.state.map.currentLongitude = longitude;
        searchQuery.isLocal = true;
        mediatorEvent.searchQuery = searchQuery;
        const queryString = Utils.convertToQuery(searchQuery);
        mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
        mediatorEvent.position = position;
        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    handleErrorGetPosition(error) {
        console.log(`ERROR(${error.code}): ${error.message}`);
    }

    attachEvents() {
        this.gmap.addListener('click', () => this.closeInfoWindow());

        window.google.maps.event.addListener(this.infoWindow, 'closeclick', () =>
            this.closeInfoWindow());

        window.google.maps.event.addListener(this.gmap, 'idle', () => this.onIdle());
    }

    afterMapLoaded(data) {
        this.locations = data.stockists;
        this.translations = data.translations;
        this.updateMarkers();
        this.isBoundsSetted = true;
        this.applyBounds(this.markersList);
        this.renderMarkers();
    }

    updateDisabledMarkers(locations) {
        if (!Utils.isEukanuba()) {
            this.markersList = [];
            locations.forEach((location) => {
                location.marker = this.generateDisabledMarkers(location);
                this.markersList.push(location.marker);
            });

            if (this.isGeoPositionEnabled) {
                const currentPosMarker = this.generateCurrentPositionMarker(this.currentPositionCoords);
                this.markersList.push(currentPosMarker);
            }

            this.attachEventsToMarkers(locations);
        }
    }

    generateDisabledMarkers(pin) {
        if (!pin.location.lat || !pin.location.lng) return false;

        const icon = this.getDisabledMarkerIconOptions(pin);

        if (icon.gicon !== null) {
            return false;
        }

        icon.gicon = {
            url: icon.url,
            size: new google.maps.Size(Math.round(icon.width / 2), Math.round(icon.height / 2)),
            scaledSize: new google.maps.Size(
                Math.round(icon.width / 2),
                Math.round(icon.height / 2)
            )
        };

        const markerOptions = {
            position: new google.maps.LatLng(pin.location.lat, pin.location.lng),
            title: pin.name,
            icon: icon.gicon,
            id: pin.id
        };

        return new google.maps.Marker(markerOptions);
    }

    getDisabledMarkerIconOptions(pin) {
        const iconOptions = {
            url: pin.clicked ? '/dist/images/map/map-marker.png' : '/dist/images/map/map-marker-gray.png',
            width: 64,
            height: 85,
            gicon: null
        };
        return iconOptions;
    }

    updateMarkers() {
        this.markersList = [];
        this.locations.forEach((location) => {
            location.marker = this.generateMarker(location);
            this.markersList.push(location.marker);
        });

        if (this.isGeoPositionEnabled) {
            const currentPosMarker = this.generateCurrentPositionMarker(this.currentPositionCoords);
            this.markersList.push(currentPosMarker);
        }

        this.attachEventsToMarkers(this.locations);
    }

    attachEventsToMarkers(locations) {
        locations.forEach((pin) => {
            if (pin.marker) {
                pin.marker.addListener('click', () => this.handleMarkerClick(pin));
            }
        });
    }

    generateMarker(pin) {
        if (!pin.location.lat || !pin.location.lng) return false;

        const icon = this.getMarkerIconOptions();

        if (icon.gicon !== null) {
            return false;
        }

        icon.gicon = {
            url: icon.url,
            size: new google.maps.Size(Math.round(icon.width / 2), Math.round(icon.height / 2)),
            scaledSize: new google.maps.Size(
                Math.round(icon.width / 2),
                Math.round(icon.height / 2)
            )
        };

        const markerOptions = {
            position: new google.maps.LatLng(pin.location.lat, pin.location.lng),
            title: pin.name,
            icon: icon.gicon,
            id: pin.id
        };

        return new google.maps.Marker(markerOptions);
    }

    generateCurrentPositionMarker(position) {
        const isEukanuba = Utils.isEukanuba();
        const icon = {
            url: isEukanuba ? '/dist/images/map/eukanuba/map-default-marker.png' : '/dist/images/map/map-default-marker.png',
            width: 64,
            height: 64,
            gicon: null
        };

        if (icon.gicon !== null) {
            return false;
        }

        icon.gicon = {
            url: icon.url,
            size: new google.maps.Size(Math.round(icon.width / 2), Math.round(icon.height / 2)),
            scaledSize: new google.maps.Size(
                Math.round(icon.width / 2),
                Math.round(icon.height / 2)
            )
        };

        const markerOptions = {
            position: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
            icon: icon.gicon,
            map: this.gmap
        };

        return new google.maps.Marker(markerOptions);
    }

    renderDisabledMarkers(locations) {
        this.clearCluster();
        this.generateCluster(locations);
        let pin = locations.find(location => location.clicked);
        this.openInfoWindow(pin);
    }

    renderMarkers() {
        this.clearCluster();
        this.generateCluster(this.locations);
    }

    generateCluster(locations) {
        if (!locations) return;

        const markers = [];

        locations.forEach((pin) => {
            markers.push(pin.marker);
        });

        this.updateCluster(markers);
    }

    applyBounds(markers) {
        const bounds = this.calcBounds(markers);

        google.maps.event.trigger(this.gmap, 'resize');

        if (bounds && this.markersList.length > 1) {
            this.gmap.fitBounds(bounds);
        } else if (bounds && this.markersList.length === 1) {
            this.gmap.setCenter(bounds.getCenter());
            this.gmap.setZoom(this.defaultZoom);
        }
    }

    calcBounds(markers) {
        if (!markers || !markers.length) return false;

        let latLngBounds;

        for (let i = 0, imax = markers.length; i < imax; i++) {
            if (markers[i]) {
                if (latLngBounds) {
                    latLngBounds.extend(markers[i].getPosition());
                } else {
                    latLngBounds = new google.maps.LatLngBounds(markers[i].getPosition());
                }
            }
        }
        return latLngBounds;
    }

    clearCluster() {
        if (this.cluster) {
            this.cluster.clearMarkers();
        }
    }

    updateCluster(markers) {
        if (this.cluster) {
            this.cluster.clearMarkers();
            this.cluster.addMarkers(markers);
        } else {
            const clusterOptions = this.getClusterOptions();
            /* eslint-disable */
            this.cluster = new MarkerClusterer(this.gmap, markers, clusterOptions);
            /* eslint-enable */
        }
    }

    updateData(data, applyBounds, IsGeoPositionEnabled) {
        const dummy = document.createElement('div');
        dummy.innerHTML = data;
        const jsonScriptNode = dummy.querySelector('[data-ref="json-map-data"]');
        const jsonData = jsonScriptNode ? JSON.parse(jsonScriptNode.innerHTML).stockists : null;

        if (!jsonData) {
            return;
        }

        config.state.pins.data = jsonData;
        this.locations = jsonData;

        this.updateMarkers();
        this.renderMarkers();

        if (applyBounds) {
            this.isBoundsSetted = true;
            this.applyBounds(this.markersList);
        }

        if (IsGeoPositionEnabled) {
            const bounds = this.getFormattedBounds();
            config.state.map.bounds = [bounds];
        }
    }

    closeInfoWindow() {
        this.infoWindow.close();
        const mediatorEvent = new MediatorEventModel();
        mediatorEvent.eventType = MediatorEvents.mapClicked;
        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
        if (!Utils.isEukanuba()) {
            this.updateMarkers();
            this.renderMarkers();
            this.revertStockistsHide();
        }
    }

    onIdle() {
        if (this.isBoundsSetted) {
            this.isBoundsSetted = false;
            return;
        }

        if (this.afterIdleTimeout) {
            clearTimeout(this.afterIdleTimeout);
        }

        if (!Utils.isEukanuba()) {
            this.revertStockistsHide();
        }

        this.afterIdleTimeout = setTimeout(() => {
            const mediatorEvent = new MediatorEventModel();
            const urlParams = new URLSearchParams(window.location.search);
            const contactTypes = urlParams.get('contacttypes') && urlParams.get('contacttypes');
            const sortResultsBy = urlParams.get('sortresultsby') && urlParams.get('sortresultsby');
            mediatorEvent.eventType = MediatorEvents.mapIdleFinished;
            const searchQuery = config.filterPanelControl.getSearchQuery();
            const bounds = [this.getFormattedBounds()];
            Object.assign(searchQuery, ...config.requestParameters);
            Object.assign(searchQuery, ...bounds);
            config.filterSearchControl.resetInputValue();

            if (urlParams.get('searchQuery')) {
                searchQuery.searchQuery = urlParams.get('searchQuery');
            }

            if (config.state.map.latitude != undefined) {
                config.state.map.latitude = '';
            }
            if (config.state.map.longitude != undefined) {
                config.state.map.longitude = '';
            }

            if (Utils.isEukanuba()) {
                if (this.isGeoPositionEnabled) {
                    searchQuery.currentLatitude = this.currentPositionCoords.coords.latitude;
                    searchQuery.currentLongitude = this.currentPositionCoords.coords.longitude;
                }
            } else {
                if (this.isGeoPositionEnabled) {
                    searchQuery.currentLatitude = this.currentPositionCoords.coords.latitude;
                    searchQuery.currentLongitude = this.currentPositionCoords.coords.longitude;
                } else {
                    return;
                }
            }

            if (contactTypes) {
                searchQuery.contacttypes = contactTypes;
            }
            if (sortResultsBy) {
                searchQuery.sortresultsby = sortResultsBy;
            }

            mediatorEvent.searchQuery = searchQuery;

            const queryString = Utils.convertToQuery(searchQuery);
            mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
            config.state.map.bounds = bounds;
            this.setRefreshButton(mediatorEvent);
        }, this.loadingTime);
    }

    hideStockistBasedOnPinSelection(pin) {
        if (!Utils.isEukanuba()) {
            const stockists = document.querySelectorAll('[data-ref="filter-listing-item"]');
            const pagination = document.querySelector('[data-ref="pagination-section"]');
            const copiedLocations = [...this.locations];
            stockists && stockists.forEach(stockist => {
                if (stockist.getAttribute('data-id') === pin.id) {
                    stockist.classList.remove('rc-hidden');
                    stockist.classList.add('rc-stockist-list__list-item--active')
                    copiedLocations.forEach(location => {
                        if (location.id === pin.id) {
                            location.clicked = true;
                        } else {
                            location.clicked = false;
                        }
                    })
                } else {
                    stockist.classList.add('rc-hidden');
                    stockist.classList.remove('rc-stockist-list__list-item--active')
                }
            });
            pagination && pagination.classList.add('rc-hidden');
            this.changePinColorBasedOnSelection(copiedLocations);
        }
    }

    changePinColorBasedOnSelection(locations) {
        this.updateDisabledMarkers(locations);
        this.renderDisabledMarkers(locations);
    }

    revertStockistsHide() {
        if (!Utils.isEukanuba()) {
            const stockists = document.querySelectorAll('[data-ref="filter-listing-item"]');
            const pagination = document.querySelector('[data-ref="pagination-section"]');

            stockists && stockists.forEach(stockist => {
                stockist.classList.remove('rc-hidden');
                stockist.classList.remove('rc-stockist-list__list-item--active');
            });
            pagination && pagination.classList.remove('rc-hidden');
        }
    }

    handleMarkerClick(pin) {
        this.isBoundsSetted = true;
        const mediatorEvent = new MediatorEventModel();
        mediatorEvent.eventType = MediatorEvents.mapMarkerClicked;
        mediatorEvent.eventData = {
            pin
        };
        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
        // for RC
        this.hideStockistBasedOnPinSelection(pin);
        this.pushGoogleTracking(pin, 'dealerFinderClickMap', 'Map');
    }

    openInfoWindow(pin) {
        const mapType = { mapTypeGoogle: true };

        pin = { ...pin, ...this.translations, ...mapType };
        const marker = pin.marker;
        const infoWindowContent = this.infoWindowTemplate(pin);

        if (!ViewportInfo.isTouch) {
            this.gmap.panTo(marker.getPosition());
        }

        this.infoWindow.setContent(infoWindowContent);
        this.infoWindow.open(this.gmap, marker);
        this.attachGetDirectionClickEvent(pin);
        this.attachVisitWebsiteClickEvent(pin);
    }

    attachGetDirectionClickEvent(pin) {
        setTimeout(() => {
            const infoWindowHtml = document.querySelector('.info-window');
            if (!infoWindowHtml) return;
            const ctaGetDirection = infoWindowHtml.querySelector('[data-component="get-directions-track"]');
            if (!ctaGetDirection) return;

            ctaGetDirection.addEventListener('click', () => { this.pushGoogleTracking(pin, 'dealerFinderClickDirections', 'Get directions'); });
        }, 0);
    }

    attachVisitWebsiteClickEvent(pin) {
        setTimeout(() => {
            const infoWindowHtml = document.querySelector('.info-window');
            if (!infoWindowHtml) return;
            const ctaVisitWebsite = infoWindowHtml.querySelector('[data-component="visit-website-track"]');
            if (!ctaVisitWebsite) return;
            ctaVisitWebsite.addEventListener('click', () => { this.pushGoogleTracking(pin, 'dealerFinderEretailerClick', 'Online Retailer'); });
        })
    }

    pushGoogleTracking(pin, event, label) {
        const trackCategory = config.tracking.category || '';
        const trackLabel = label;
        const trackEvent = event;
        const trackAction = 'Click';
        const trackCustomerType = pin.contactTypes.toString();
        const trackCustomerName = pin.companyName;
        GTM.push(trackCategory, trackAction, trackLabel, trackEvent, trackCustomerType, trackCustomerName);
    }

    setMarkerActive(id) {
        const pin = this.getPinById(id);
        if (pin === null) {
            return;
        }

        this.infoWindow.close();

        this.gmap.setZoom(this.defaultZoom);
        this.gmap.setCenter(pin.marker.getPosition());
        this.openInfoWindow(pin);
        this.gmap.panBy(0, -0.001);

        this.isBoundsSetted = true;
    }

    setRefreshButton(mediatorEvent) {
        const refreshElement = document.getElementById('refresh-button');
        refreshElement.classList.remove('map-view-display');
        refreshElement.addEventListener('click', () => {
            if (config.mediator) {
                config.mediator.stateChanged(mediatorEvent);
                const dataLayer = window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event' : 'RCGlobalMC1dealerFinderRefreshMap'
                });
            }
        }, { once: true, passive: true, capture: true });
    }

    getMapBounds() {
        const bounds = this.gmap.getBounds();

        if (!bounds) return false;

        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();

        return {
            northeast: {
                latitude: northEast.lat(),
                longitude: northEast.lng()
            },
            southwest: {
                latitude: southWest.lat(),
                longitude: southWest.lng()
            }
        };
    }

    getFormattedBounds() {
        const bounds = this.getMapBounds();

        return {
            neLatitude: bounds.northeast.latitude,
            neLongitude: bounds.northeast.longitude,
            swLatitude: bounds.southwest.latitude,
            swLongitude: bounds.southwest.longitude
        };
    }

    getPinById(id) {
        return this.locations.filter(x => x.id === id)[0];
    }

    getClusterOptions() {
        const isEukanuba = Utils.isEukanuba();
        const clusterStyleOptions = {
            styles: [
                {
                    url: isEukanuba ? '/dist/images/map/eukanuba/map-cluster.png' : '/dist/images/map/map-cluster.png',
                    width: 64,
                    height: 64,
                    textColor: 'white',
                    textSize: 16,
                    anchorIcon: [32, 32]
                }
            ],
            enableRetinaIcons: true,
            clusterClass: 'cluster-icon'
        };

        return clusterStyleOptions;
    }

    getMarkerIconOptions() {
        const isEukanuba = Utils.isEukanuba();
        const iconOptions = {
            url: isEukanuba ? '/dist/images/map/eukanuba/map-marker.png' : '/dist/images/map/map-marker.png',
            width: 64,
            height: 85,
            gicon: null
        };
        return iconOptions;
    }
}

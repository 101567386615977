import './styles.scss';
// dlHeaderScript is immediatly invoked
import dlHeaderScript from './dlHeaderScript';
import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class HeaderBar extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'rc-header-bar';
    }

    init() {
        this.setVariables();
        let searchbarButtons = this.el.querySelectorAll("[aria-label='Search']");
        if (searchbarButtons != null)
        {
            searchbarButtons.forEach(button => {
                button.onclick = function () {
                    let dataLayer = window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'instantSearchFieldClick'
                    })
                    console.log("Event - instantSearchFieldClick added");
                }
            });
        }
        window.addEventListener('load', () => {
            this.displayMenu();
          });
        window.addEventListener('resize', this.displayMenu());
    }
    setVariables() {
        this.menuText =this.el.getElementsByClassName("menu-text")[0]
    }

    displayMenu() {
        if (window.matchMedia('(min-width: 767px)').matches) {
            this.menuText.classList.remove('display-none')
        }
    }
}

import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import MapBridge from './mapBridge';

export default class Map extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'map';
    }

    static get constructorName() {
        return 'mapControl';
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.initMap();
    }

    setVariables() {
        this.isInited = true;
        this.mediator = null;
        this.mapBridge = null;
        this.options = {};
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    addListeners() {}

    initMap() {
        const latitude = this.el.getAttribute('data-latitude');
        const longitude = this.el.getAttribute('data-longitude');
        const zoom = this.el.getAttribute('data-zoom')
            ? parseInt(this.el.getAttribute('data-zoom'), 10)
            : 10;
        this.options = {
            center: {
                lat: window.parseFloat(latitude),
                lng: window.parseFloat(longitude)
            },
            zoom
        };

        this.mapBridge = new MapBridge(this.el, this.options);

        this.mapBridge.initApi().then(() => {
            this.mapBridge.initMap();
        });
    }
}

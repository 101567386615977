import BaseComponent from '$Foundation/src/js/BaseComponent';


export default class EnlargeImage extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'vetfocus-enlarge-image';
    }
    
    init() {
        this.setVariables();
        this.addListeners();
    }

    setVariables() {
        this.enlargeButtons = document.querySelectorAll('[data-modal-trigger="full-modal-image"].rc-expand--xs');
        this.enlargeModals = document.querySelectorAll('[ data-modal-target="full-modal-image"]');
        this.enlargeCloseButtons = document.querySelectorAll('[ data-modal-trigger="full-modal-image"].rc-modal__close')
        this.shadowBoxes = document.getElementsByClassName('shadow-box');

    }

    addListeners() {
        this.enlargeButtons.forEach((button, i) => {
            this.addListener(button, 'click', e => this.openModal(e, i));
        });
        this.enlargeCloseButtons.forEach((button, i) => {
            this.addListener(button, 'click', e => this.closeModal(e, i));

        });
    }

    openModal(e, i) {
        if (this.enlargeModals[i].classList.contains("rc-hidden")) {
            this.enlargeModals[i].classList.remove("rc-hidden");
            this.shadowBoxes[i].classList.add('rc-shade');
        }
    }

    closeModal(e, i) {
        this.enlargeModals[i].classList.add("rc-hidden");
        this.shadowBoxes[i].classList.remove('rc-shade');
    }
}   

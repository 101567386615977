import styled from "styled-components";

const Wrapper = styled.a`
  text-decoration: none;
  margin-top: 10px;
  display: inline-block;
  .article-card__inner {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #d7d7d7;
    border-radius: 3px;
    display: flex;
    flex-direction: ${({ isVertical }) => (isVertical ? "column" : "row")};
    max-width: 860px;

    .article-card__image-wrapper {
      max-width: ${({ isVertical }) => (isVertical ? "100%" : "410px")};
      max-height: 300px

      img {
        max-width: 100%;
        height: 100%;
        max-height: 250px;
        min-width: 400px;
        object-fit: cover;
    
        @media(max-width: 920px){
            min-width: unset;
        }
      }
    }

    .article-card__content-wrapper {
      ${({ isVertical }) => (isVertical ? "100%" : "446px")};
      padding: 31px 36px;

      .article-card__content-wrapper__editorial-format {
        font-size: 14px;
        color: #666;
      }

      .article-card__content-wrapper__editorial-heading {
        margin-top: 4px;
        font-size: 26px;
        color: #e2001a;
      }

      .article-card__content-wrapper__editorial-summary {
        margin-top: 12px;
        font-size: 16px;
        color: #666;
      }
    }
  }
`;

export default Wrapper;

import GTM from '$Foundation/src/js/gtm/gtmPetDetails';
import { config } from '../index';

class FilterTrackingService {
    static getTrackingCategory() {
        return 'category' in config.tracking ? config.tracking.category : null;
    }

    static isTrackingEnabled() {
        return this.getTrackingCategory() !== null;
    }

    static triggerTracking(action, label, event, breedId, specieId, age, category) {
        GTM.push(
            category || this.getTrackingCategory(),
            action,
            label,
            event,
            breedId,
            specieId,
            age
        );
    }
}

export default FilterTrackingService;

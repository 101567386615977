import BaseComponent from "$Foundation/src/js/BaseComponent";
import "./styles.scss";

export default class NavigationProductBlock extends BaseComponent {
  constructor(el) {
    super(el);
    this.init();
    this.isInited = true;
  }

  static get tagName() {
    return "navigation-product-block";
  }

  init() {
    const pItems = this.el.querySelectorAll("p.rc-meta");

    for (let i = 0; i < pItems.length; i++) {
      if (pItems[i].offsetHeight > 45) {
        pItems[i].innerHTML = `${pItems[i].innerHTML.substr(0, 120)}...`;
      }
    }
  }
}

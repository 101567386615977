// This is included with the window.Parsley && window.Parsley library itself,
// thus there is no use in adding it to your project.

window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('en', {
    defaultMessage: "This value seems to be invalid.",
    type: {
      email:        "This value should be a valid email.",
      url:          "This value should be a valid url.",
      number:       "This value should be a valid number.",
      integer:      "This value should be a valid integer.",
      digits:       "This value should be digits.",
      alphanum:     "This value should be alphanumeric."
    },
    notblank:       "This value should not be blank.",
    required:       "This value is required.",
    pattern:        "This value seems to be invalid.",
    min:            "This value should be greater than or equal to %s.",
    max:            "This value should be lower than or equal to %s.",
    range:          "This value should be between %s and %s.",
    minlength:      "This value is too short. It should have %s characters or more.",
    maxlength:      "This value is too long. It should have %s characters or fewer.",
    length:         "This value length is invalid. It should be between %s and %s characters long.",
    mincheck:       "You must select at least %s choices.",
    maxcheck:       "You must select %s choices or fewer.",
    check:          "You must select between %s and %s choices.",
    equalto:        "This value should be the same.",
    euvatin:        "It's not a valid VAT Identification Number.",
  });

  window.Parsley && window.Parsley.setLocale('en');
});

import BaseComponent from "$Foundation/src/js/BaseComponent";
import "./styles.scss";

export default class NavigationEventTracker extends BaseComponent {
  constructor(el) {
    super(el);
    this.items = [...this.el.querySelectorAll('[data-ref="nav-link"]')];
    this.init();
  }

  static get tagName() {
    return "nav-track";
  }

  // We have to set data-ref="nav-link" to all top navigation clickable elements

  init() {
    if (
      this.el.classList.contains("rc-header__nav--new") &&
      this.el.classList.contains("rc-header__nav--secondary")
    ) {
      // 371981 - Implement new tagging
      this.items.forEach((link) => {
        link.addEventListener("click", this.onClick.bind(this));
      });

      // 371894 - Update aria-expanded attribute when the according menu item dropdown is open or not
      const headerItems = this.el.querySelectorAll(
        ".rc-header__nav--secondary__list > .rc-list__item"
      );
      if (headerItems) {
        headerItems.forEach((headerItem) => {
          headerItem.addEventListener("mouseover", () => {
            headerItem.firstElementChild.setAttribute("aria-expanded", "true");
          });

          headerItem.addEventListener("mouseout", () => {
            headerItem.firstElementChild.setAttribute("aria-expanded", "false");
          });
        });
      }

      // 372877 - Update in real-time the nav-menu top position
      const topNavSearchBar = document.querySelector(".rc-search_top_menu");
      if (topNavSearchBar) {
        const observer = new MutationObserver((mutationList) => {
          mutationList.forEach((mutation) => {
            if (
              mutation.type === "attributes" &&
              mutation.attributeName === "style"
            ) {
              if (mutation.target.style.display === "block") {
                this.el.classList.add("top-navigation-search-bar-visible");
              } else {
                this.el.classList.remove("top-navigation-search-bar-visible");
              }
            }
          });
        });
        observer.observe(topNavSearchBar, { attributes: true });
      }
    }
  }

  onClick(e) {
    const dataLayer = (window.dataLayer = window.dataLayer || []);
    dataLayer.push({
      event: "topNavClick",
      topNavClick: {
        name: e.target.textContent.trim(),
      },
    });
  }
}

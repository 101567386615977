import Utils from '$Foundation/src/js/utils';
import { config } from '../../index';
import MediatorEvents from '../enums/mediatorEvents';
import MediatorEventModel from '../models/mediatorEventModel';
import AbstractFilterControl from './AbstractFilterControl';
import ViewportInfo, { DEVICE_TYPE_DESKTOP } from '$Foundation/src/js/ViewportInfo';
import GTM from '$Foundation/src/js/gtm/gtmPetDetails';

export default class FilterRangeControl extends AbstractFilterControl {
    constructor(el, id) {
        super(el, id);
        this.init();
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.createRange();
    }

    setVariables() {
        this.isInited = true;
        this.mediator = null;
        this.hiddenInputNode = this.el.querySelector('[data-ref="filter-input-range"]');
        this.rangeOptions = JSON.parse(this.el.querySelector('[data-ref="filter-range-options-json"]').innerHTML);
        this.isExcluded = this.el.getAttribute('data-exclude-filter')
            ? this.el.getAttribute('data-exclude-filter') === 'true'
            : false;
        this.trackCategory = this.hiddenInputNode.getAttribute('data-track-category');
        this.trackLabel = this.hiddenInputNode.getAttribute('data-track-label');
        this.trackEvent = this.hiddenInputNode.getAttribute('data-track-event');
        this.trackAction = '';
        this.trackBreedId = this.hiddenInputNode.getAttribute('data-track-breed-id');
        this.trackSpecieId = this.hiddenInputNode.getAttribute('data-track-specie-id');
        this.trackAge = {};
    }

    clear() {
        this.el.noUiSlider.set(0);
        this.hiddenInputNode.value = 0;
    }

    isActive() {
        return parseFloat(this.el.noUiSlider.get()) > 0;
    }

    getCategory() {
        const parentNode = Utils.getClosestNodes(this.el, '[data-ref="filter-category"]');

        const category = parentNode[0].getAttribute('data-filter-category').toLowerCase();
        return category;
    }

    getLabel() {
        if (this.hiddenInputNode.value !== '') {
            const value = parseFloat(this.hiddenInputNode.value);
            const label = this.rangeOptions.filter(x => x.value === value)[0].title;
            return label;
        }
        return '';
    }

    getValue() {
        return parseFloat(this.el.noUiSlider.get());
    }

    setValue(value) {
        this.el.noUiSlider.set(value);
    }

    shouldBeExcluded() {
        return this.isExcluded;
    }

    createRange() {
        const step = 100 / (this.rangeOptions.length - 1);

        const settings = {};

        this.rangeOptions.forEach((option, index) => {
            if (index + 1 === this.rangeOptions.length) return;
            settings[step * index] = option.value;
        });

        import('nouislider').then((noUiSlider) => {
            noUiSlider.create(this.el, {
                start: this.hiddenInputNode.value || 0,
                snap: true,
                tooltips: [
                    {
                        to: value => this.rangeOptions.filter(x => x.value === value)[0].title
                    }
                ],
                connect: [true, false],
                range: Object.assign({}, { min: this.rangeOptions[0].value }, settings, {
                    max: this.rangeOptions.slice(-1)[0].value
                }),
                direction: RCDL.config.documentDirection
            });

            this.el.noUiSlider.on('change', (value) => {
                this.handleRangeChange(parseFloat(value));
            });
        });
    }

    handleRangeChange(values) {
        this.hiddenInputNode.value = values;

        if (
            ViewportInfo.deviceTypeByViewport !== DEVICE_TYPE_DESKTOP &&
            ViewportInfo.isTouch &&
            !this.isExcluded
        ) {
            return;
        }

        const mediatorEvent = new MediatorEventModel();

        const searchQuery = config.filterPanelControl.getSearchQuery();

        Object.assign(searchQuery, ...config.requestParameters);

        // searchQuery.searchQuery = config.filterSearchControl.getInputValue();

        mediatorEvent.searchQuery = searchQuery;

        const queryString = Utils.convertToQuery(searchQuery);

        mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
        mediatorEvent.eventType = MediatorEvents.filterChanged;

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }

        this.trackAction = this.getLabel();
        this.trackAge = this.getLabel();

        // Google Tracking
        GTM.push(
            this.trackCategory,
            this.trackAction,
            this.trackLabel,
            this.trackEvent,
            this.trackBreedId,
            this.trackSpecieId,
            this.trackAge
        );
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    addListeners() {}
}

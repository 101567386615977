import BaseComponent from "$Foundation/src/js/BaseComponent";

export default class NavigationContactDropdown extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return "navigation-contact-dropdown";
    }

    init() {
        const title = this.el.querySelector(
            ".contact-dropdown-title > .rc-delta"
        );
        if (title && title.offsetHeight > 90) {
            const limit =
                window.innerWidth > 768
                    ? 220
                    : Math.round((title.offsetWidth / 10) * 2);
            title.innerHTML = `${title.innerHTML.substr(0, limit)}...`;
        }

        const texts = this.el.querySelectorAll(
            ".contact-dropdown-title > p:not(.rc-delta)"
        );
        if (texts) {
            texts.forEach((text) => {
                if (text && text.offsetHeight > 65) {
                    const limit =
                        window.innerWidth > 768
                            ? 270
                            : Math.round((text.offsetWidth / 8) * 2);
                    text.innerHTML = `${text.innerHTML.substr(0, limit)}...`;
                }
            });
        }

        const phone = this.el.querySelector(".contact-dropdown-tel > a");
        if (phone && phone.offsetHeight > 70) {
            phone.innerHTML = `${phone.innerHTML.substr(0, 55)}...`;
        }

        const footer = this.el.querySelector(".contact-dropdown-footer > p");
        if (footer && footer.offsetHeight > 70) {
            const limit =
                window.innerWidth > 768
                    ? 290
                    : Math.round((footer.offsetWidth / 7) * 3);
            footer.innerHTML = `${footer.innerHTML.substr(0, limit)}...`;
        }

        const titleLinks = this.el.querySelectorAll(
            ".contact-dropdown-title a"
        );
        if (titleLinks) {
            titleLinks.forEach((link) => {
                link.classList.add("rc-styled-link");
            });
        }
    }
}

import EventEmitter from '$Foundation/src/js/EventEmitter';

class MapScriptObserver {
    constructor() {
        this._isLoaded = null;
        this.subscribers = [];
        this.onMapApiDone = this.onMapApiDone.bind(this);
    }

    onMapApiDone() {
        this._isLoaded = true;
        this.subscribers.forEach(cb => cb());
    }

    isLoaded() {
        if (this._isLoaded === null) {
            if (window.mapIsInitialized) {
                this._isLoaded = true;
            } else {
                this._isLoaded = false;
                EventEmitter.subscribe('map:initialized', this.onMapApiDone.bind(this));
            }
        }

        return this._isLoaded;
    }

    onLoad(cb) {
        if (this.isLoaded()) {
            cb();
        } else {
            this.subscribers.push(cb);
        }
    }
}

const instance = new MapScriptObserver();

export default instance;

import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class ShowRule extends BaseComponent {
    /**
     * example config
     * {
     *  elements: "id1,id2:false,id3:concreteValue"
     *  condition: 'and'
        action: 'show' //hide, class
        class: 'example-class' // if action is class
     * }
     * Add to dependent field 'data-rule="checkbox-id" data-component="show-rule"'
     * <div class="form-item clearfix" data-rule="checkbox-id" data-component="show-rule">
     *
     * Add to main field  data-rule-id="checkbox-id"
     *
     * @param el
     */
    constructor(el) {
        super(el);
        this.idAttributeName = 'data-rule-id';
        this.config = {
            action: 'show',
            condition: 'and'
        };
        this.elementsValues = {};
        this.lastResult = undefined;
        this.init();
    }

    static get tagName() {
        return 'show-rule';
    }

    init() {
        const configString = this.el.getAttribute('data-rule');
        let config = {};
        try {
            config = JSON.parse(configString);
        } catch (e) {
            // maybe we pass single string to set elements only
            config = { elements: configString };
        }

        const elementsString = config.elements;
        if (elementsString) {
            const elements = [];
            const elementsConditions = {};
            config.elements.split(',').forEach((elementCondition) => {
                const [elementId, elementValue] = elementCondition.trim().split(':');
                const elementsById = document.querySelectorAll(`[${this.idAttributeName}="${elementId}"]`);
                const elementsCount = elementsById.length;

                Array.prototype.forEach.call(elementsById, (element) => {
                    if (
                        element &&
                        (element.tagName === 'INPUT' ||
                            element.tagName === 'SELECT' ||
                            element.tagName === 'TEXTAREA')
                    ) {
                        elements.push(element);
                        if (
                            elementValue === undefined ||
                            elementValue === 'true' ||
                            elementValue === 'false'
                        ) {                            
                            elementsConditions[elementId] = !!(
                                elementValue === undefined || elementValue === 'true'
                            );
                        } else if (element.value === elementValue || elementsCount === 1) {
                            elementsConditions[elementId] = elementValue;
                        }
                    }
                });
            });
            config.elementsConditions = elementsConditions;
            if (elements.length) {
                this.bootstrap(config, elements);
            }
        }
    }

    getElementId(element) {
        return element.getAttribute(this.idAttributeName);
    }

    bootstrap(configPart, elements) {
        Object.assign(this.config, configPart);
        this.elementsValues = elements.reduce((result, element) => {
            if (element.type === 'radio') {
                if (!result[this.getElementId(element)] ) {    
                result[this.getElementId(element)] = this.getElementValue(element);
                }
            } else {
                result[this.getElementId(element)] = this.getElementValue(element);
            }            
            return result;
        }, {});
        this.elements = elements.reduce((result, element) => {
            result[this.getElementId(element)] = element;
            return result;
        }, {});
        
        elements.forEach((element) => {
            if (this.isTextControl(element)) {
                element.addEventListener('input', this.onElementChange.bind(this));
            } else {
                element.addEventListener('change', this.onElementChange.bind(this));
            }
        });
        this.manage();
    }

    getElementValue(element) {
        switch (element.type) {
            case 'checkbox':
            case 'radio':
                return element.checked ? element.value : false;
            case 'select-multiple':
                return [...element.selectedOptions].map(x => x.value);
            default:
                return element.value;
        }
    }

    isTextControl(element) {
        return (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' &&
                (element.type === 'text' ||
                    element.type === 'email' ||
                    element.type === 'number' ||
                    element.type === 'password'))
        );
    }

    onElementChange(e) {
        const element = e.target;
        this.elementsValues[this.getElementId(element)] = this.getElementValue(element);
        this.manage();
    }

    manage() {
        let result = false;
        
        const checkFunction = (id) => {            
            const element = this.elements[id];
            if (
                (this.isTextControl(element) || element.tagName === 'SELECT') &&
                this.config.elementsConditions[id] === true
            ) {
                return this.elementsValues[id] !== '';
            }

            if (
                (element.type === 'checkbox' || element.type === 'radio') &&
                this.config.elementsConditions[id] === true
            ) {
                return this.elementsValues[id] !== false;
            }

            if (element.tagName === 'SELECT' && element.multiple) {
                return this.elementsValues[id].includes(this.config.elementsConditions[id]);
            }

            return this.elementsValues[id] === this.config.elementsConditions[id];
        };        

        if (this.config.condition === 'and') {
            result = Object.keys(this.elementsValues).every(checkFunction);
        } else {
            result = Object.keys(this.elementsValues).some(checkFunction);
        }
        if (result !== this.lastResult) {
            this.update(result);
        }
    }

    update(result) {
        this.lastResult = result;
        switch (this.config.action) {
            case 'show':
                this.updateShow(result);
                break;
            case 'hide':
                this.updateShow(!result);
                break;
            case 'class':
                this.updateClass(result);
                break;
            default:
                throw new Error('Unknown action');
        }
    }

    updateShow(result) {
        if (result) {
            this.el.style.display = 'block';
        } else {
            this.el.style.display = 'none';
        }
    }

    updateClass(result) {
        if (result) {
            this.el.classList.add(this.config.class);
        } else {
            this.el.classList.remove(this.config.class);
        }
    }
}

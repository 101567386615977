import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class TestComponent extends BaseComponent {
    constructor(el) {
        super(el);
        this.smallRules = undefined;
        this.empty = true;
        this.state = null;
        this.ruleAttribute = 'hidden-if-rule';
        this.idAttribute = 'hidden-if-id';
        this.init();
    }

    static get tagName() {
        return 'hidden-if';
    }

    init() {

        if (this.smallRules === undefined) {            
            this.allSmallRules = this.getAllSmallRules();
        }

        const elements = [...document.querySelectorAll(`[${this.idAttribute}]`)];        
        elements.forEach((element) => {          
            this.state = this.getElementValue(element);
            const result = this.getResultFromSmallRules(this.allSmallRules);
            this.changeSelf(result);
        });
    }

    update(e) {
        if (e !== undefined) {
            this.state = this.getElementValue(e.target);
        }

        const result = this.getResultFromSmallRules(this.allSmallRules);
        this.changeSelf(result);
    }

    getResultFromSmallRules(smallRules) {
        let result = false;
        smallRules.forEach((rule) => {
            const [elementUniqueKey, elementValue] = rule.split(':');

            if (this.empty) {
                this.empty = false;
                this.setEventListeners(elementUniqueKey);
            }
            result = result || elementValue === this.state;
        });
        return result;
    }

    getAllSmallRules() {
        const mainRule = this.el.getAttribute(this.ruleAttribute);
        const orRules = this.splitRulesWithOr(mainRule);
        return orRules;
    }

    splitRulesWithOr(longRule) {
        return longRule.split('|');
    }

    setEventListeners(elementUniqueKey) {
        const elements = [...document.querySelectorAll(`[${this.idAttribute}="${elementUniqueKey}"]`)];
        elements.forEach(x => x.addEventListener('change', this.update.bind(this)));
    }
    
    getElementValue(element) {
        switch (element.type) {
            case 'checkbox':
            case 'radio':
                return element.checked ? element.value : false;
            case 'select-multiple':
                return [...element.selectedOptions].map(x => x.value);
            default:
                return element.value;
        }
    }

    changeSelf(flag) {
        if (flag) {
            this.el.style.display = 'block';
        } else {
            this.el.style.display = 'none';
        }
    }
}

import BaseComponent from '$Foundation/src/js/BaseComponent';
import { styler, tween } from 'popmotion';
import Utils from '$Foundation/src/js/utils';
import { config } from '../../index';
import tagTemplate from '../../templates/filter-tag.hbs';
import MediatorEvents from '../enums/mediatorEvents';
import MediatorEventModel from '../models/mediatorEventModel';
import ViewportInfo, { DEVICE_TYPE_DESKTOP } from '$Foundation/src/js/ViewportInfo';

export default class FilterTagsControl extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'filter-tags';
    }

    static get constructorName() {
        return 'filterTagsControl';
    }

    init() {
        this.setVariables();
        this.addListeners();
        setTimeout(() => {
            this.setUpPreselectedTags();
        }, 500);
    }

    setVariables() {
        this.isInited = true;
        this.mediator = null;
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    addListeners() {
        this.addListener(this.el, 'click', e => this.handleRemoveTag(e));
    }

    handleRemoveTag(e) {
        e.preventDefault();
        if (!e.target.getAttribute('data-ref')) {
            return;
        }

        const tagNode = Utils.getClosestNodes(e.target, '[data-ref="filter-tag"]')[0];

        const tagId = tagNode.getAttribute('data-id');

        const mediatorEvent = new MediatorEventModel();
        mediatorEvent.eventType = MediatorEvents.tagRemoved;
        mediatorEvent.eventData = {
            id: tagId
        };

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }

        this.removeTag(tagId);
    }

    updateTags() {
        const filters = config.filterPanelControl.getAllFilters();

        filters.forEach((filter) => {
            if (filter.isActive() && !filter.shouldBeExcluded()) {
                this.addTag(filter.id, filter.getLabel());
            } else {
                this.removeTag(filter.id);
            }
        });
    }

    setUpPreselectedTags() {
        const queryString = window.location.search;

        if (queryString.length > 0) {
            this.updateTags();
            config.filterPanelControl.updateClearBtnVisibility();
            config.filterPanelControl.updateClearAllLinkVisibility();
        }
    }

    addTag(id, label) {
        const paramsObj = {
            id,
            text: label,
            DLClass: Utils.getDLClass(),
        };

        const el = this.el.querySelector(`[data-id="${id}"]`);
        const from = this.el.getBoundingClientRect().height;

        if (el) {
            this.el.removeChild(el);
        }

        const tagHtml = tagTemplate(paramsObj);

        this.el.insertAdjacentHTML('beforeend', tagHtml);

        const to = this.el.getBoundingClientRect().height;

        this.animateHeight(from, to);
    }

    removeTag(id) {
        const el = this.el.querySelector(`[data-id="${id}"]`);
        const from = this.el.getBoundingClientRect().height;

        if (el) {
            this.el.removeChild(el);
        }

        const to = this.el.getBoundingClientRect().height;

        this.animateHeight(from, to);
    }

    animateHeight(from, to) {
        const divStyler = styler(this.el);

        if (from !== to) {
            const tweenInstance = tween({
                from,
                to: { height: to },
                duration: 300
            });

            tweenInstance.start({
                update: divStyler.set,
                complete: () => this.resetContainerStyles(this.el)
            });
        }
    }

    resetContainerStyles(el) {
        setTimeout(() => {
            el.style.cssText = '';
        }, 0);
    }

    updateVisibility(shouldBeHidden) {
        const isDesktop = ViewportInfo.deviceTypeByViewport === DEVICE_TYPE_DESKTOP;

        if (shouldBeHidden && isDesktop) {
            this.el.classList.add('is-hidden');
        } else {
            this.el.classList.remove('is-hidden');
        }
    }
}

import BaseComponent from '$Foundation/src/js/BaseComponent';
import MoveTo from 'moveto';

export default class TimelineLink extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'timeline-link';
    }

    setVariables() {
        this.hash = this.el.hash;
        this.anchor = null;
        this.point = null;
    }

    addListeners() {
        this.addListener(this.el, 'click', e => this.handleTimelineLinkClick(e));
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    scrollElIntoView(element) {
        if (window.pageYOffset >= this.el.offsetTop) {
            setTimeout(() => {
                const header = document.querySelector('[data-js-header-scroll]');
                const headerOffset = header ? header.getBoundingClientRect().height : 0;

                const moveTo = new MoveTo({
                    tolerance: headerOffset
                });
                const target = element;
                moveTo.move(target);
            }, 300);
        }
    }

    handleTimelineLinkClick(e) {
        e.preventDefault();

        if (!this.hash) return;

        this.anchor = this.hash.substring(1);
        this.point = document.getElementById(this.anchor);

        if (this.point) {
            this.scrollElIntoView(this.point);
        }
    }
}

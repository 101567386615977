import './styles.scss';
import GTM from '$Foundation/src/js/gtm/gtm';
import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class VetFocusArticleIssueDetail extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'authors-list';
    }

    setVariables() {
        this.authorCards = this.el.querySelectorAll('[data-ref="author-card"]');
    }

    addListeners() {}

    init() {
        this.setVariables();
        this.addListeners();
        this.initContentBlock();
        this.authorCards.forEach(author => {
            this.pushGTM(author);
        });
    }

    initContentBlock() {
        console.log(`Module inited: authors-list`);
    }
    
    pushGTM(e) {
        const category = e.getAttribute('data-track-category');
        const action = e.getAttribute('data-track-action');
        const label = e.getAttribute('data-track-label');
        GTM.push(category, action, label, 'author');
    }
}

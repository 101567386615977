// Validation errors messages for window.Parsley && window.Parsley
// Load this after window.Parsley && window.Parsley

window.addEventListener('load', () => {
  window.Parsley && window.Parsley.addMessages('ru', {
    defaultMessage: "Некорректное значение.",
    type: {
      email:        "Введите адрес электронной почты.",
      url:          "Введите URL адрес.",
      number:       "Введите число.",
      integer:      "Введите целое число.",
      digits:       "Введите только цифры.",
      alphanum:     "Введите буквенно-цифровое значение."
    },
    notblank:       "Это поле должно быть заполнено.",
    required:       "Обязательное поле.",
    pattern:        "Это значение некорректно.",
    min:            "Это значение должно быть не менее чем %s.",
    max:            "Это значение должно быть не более чем %s.",
    range:          "Это значение должно быть от %s до %s.",
    minlength:      "Это значение должно содержать не менее %s символов.",
    maxlength:      "Это значение должно содержать не более %s символов.",
    length:         "Это значение должно содержать от %s до %s символов.",
    mincheck:       "Выберите не менее %s значений.",
    maxcheck:       "Выберите не более %s значений.",
    check:          "Выберите от %s до %s значений.",
    equalto:        "Это значение должно совпадать."
  });

  window.Parsley && window.Parsley.setLocale('ru');
});

import { camelizeString } from '$Foundation/src/js/utils';

class GTM {
    push(category, action, label, event = 'eventTrack') {
        const siteId = document.body.dataset.siteId;
        const eventCamelized = camelizeString(event);
        const eventFull = siteId + eventCamelized;

        if (window.dataLayer) {            
            window.dataLayer.push(Object.assign(
                {
                    event: eventFull
                },
                {
                    interaction: {
                        category: category,
                        action: action,
                        label: label
                    }
                }
            ));
        }
    }
}

export default new GTM();

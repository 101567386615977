import Utils from '$Foundation/src/js/utils';
import AutoComplete from '../../../autocomplete';
import { config } from '../../index';
import MediatorEvents from '../enums/mediatorEvents';
import MediatorEventModel from '../models/mediatorEventModel';
import FilterTrackingService from '../filterTrackingService';

export default class FilterSearchControl extends AutoComplete {
    static get tagName() {
        return 'filter-search';
    }

    static get constructorName() {
        return 'filterSearchControl';
    }

    setVariables() {
        super.setVariables();
        this.mediator = null;
        this.searchButtonNode = this.el.querySelector('[data-ref="filter-search-button"]');

        this.inputSearchString = this.el.querySelector('[data-ref="input"]');
        this.trackCategory = this.inputSearchString.getAttribute('data-track-category') || '';
        this.trackLabel = this.inputSearchString.getAttribute('data-track-label') || '';
        this.trackEvent = this.inputSearchString.getAttribute('data-track-event') || '';
        this.trackAction = this.inputSearchString.getAttribute('data-track-action') || '';
        this.trackBreedId = this.inputSearchString.getAttribute('data-track-breed-id') || '';
        this.trackSpecieId = this.inputSearchString.getAttribute('data-track-specie-id') || '';
        this.trackAge = this.inputSearchString.getAttribute('data-track-age') || '';
    }

    addListeners() {
        super.addListeners();
        this.addListener(this.inputNode, 'keyup', e => this.handleInputKeyUp(e));
        this.addListener(this.searchButtonNode, 'click', () => this.handleSearchButtonClick());
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    handleInputKeyUp(e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            if (this.isValueSelected) {
                this.isValueSelected = false;
                return;
            }

            if (this.autoCompleteEnabled) {
                this.autoCompleteInstanse.close();
            }
            if (this.inputNode.value.length < this.minChairs || !this.autoCompleteEnabled) {
                const mediatorEvent = new MediatorEventModel();
                mediatorEvent.eventType = MediatorEvents.search;

                const searchQuery = config.filterPanelControl.getSearchQuery();
                Object.assign(searchQuery, ...config.requestParameters);
                searchQuery.searchQuery = '';
                mediatorEvent.searchQuery = searchQuery;
                const queryString = Utils.convertToQuery(searchQuery);
                mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;

                if (config.mediator) {
                    config.mediator.stateChanged(mediatorEvent);
                }
            }
            else {
                const mediatorEvent = new MediatorEventModel();
                mediatorEvent.eventType = MediatorEvents.search;
    
                const searchQuery = config.filterPanelControl.getSearchQuery();
                Object.assign(searchQuery, ...config.requestParameters);
                searchQuery.searchQuery = this.getInputValue();
                mediatorEvent.searchQuery = searchQuery;
                const queryString = Utils.convertToQuery(searchQuery);
                mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
    
                if (config.mediator) {
                    config.mediator.stateChanged(mediatorEvent);
                }
            }  
        }
    }

    handleSearchButtonClick() {
        if (this.inputNode.value.length < this.minChairs || !this.autoCompleteEnabled) {
            const mediatorEvent = new MediatorEventModel();
            mediatorEvent.eventType = MediatorEvents.search;

            const searchQuery = config.filterPanelControl.getSearchQuery();
            Object.assign(searchQuery, ...config.requestParameters);
            searchQuery.searchQuery = '';
            config.state.map.bounds = {};
            mediatorEvent.searchQuery = searchQuery;
            const queryString = Utils.convertToQuery(searchQuery);
            mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;

            mediatorEvent.trackDetails = this.getTrackDetails();

            if (config.mediator) {
                config.mediator.stateChanged(mediatorEvent);
            }
        }
        else {
            const mediatorEvent = new MediatorEventModel();
            mediatorEvent.eventType = MediatorEvents.search;
    
            const searchQuery = config.filterPanelControl.getSearchQuery();
            Object.assign(searchQuery, ...config.requestParameters);
            searchQuery.searchQuery = this.getInputValue();
            config.state.map.bounds = {};
            mediatorEvent.searchQuery = searchQuery;
            const queryString = Utils.convertToQuery(searchQuery);
            mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
    
            mediatorEvent.trackDetails = this.getTrackDetails();
    
            if (config.mediator) {
                config.mediator.stateChanged(mediatorEvent);
            }
        }
    
    }

    handleAwesompleteSelectComplete(e) {
        this.isValueSelected = true;
        const suggestionText = e.target.value;
        if (this.type === 'places-search') {
            const mediatorEvent = new MediatorEventModel();
            mediatorEvent.eventType = MediatorEvents.search;

            const searchQuery = config.filterPanelControl.getSearchQuery();
            Object.assign(searchQuery, ...config.requestParameters);
            config.state.map.bounds = {};
            searchQuery.searchQuery = this.getInputValue();
            mediatorEvent.searchQuery = searchQuery;
            const queryString = Utils.convertToQuery(searchQuery);
            mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;

            if (config.mediator) {
                config.mediator.stateChanged(mediatorEvent);
            }
        } else {
            if (FilterTrackingService.isTrackingEnabled()) {
                FilterTrackingService.triggerTracking(
                    'Predictive search suggestion click',
                    `${this.inputNodeValue} > ${suggestionText}`
                );
            }
            const url = this.autoCompleteInstanse._list.find(x => x.Title === e.text.label).Url;
            window.location.href = url;
        }
    }

    handleGetCurrentPosition() {
        if (Utils.isEukanuba()) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => this.handleSuccessPosition(position),
                    error => this.handleErrorPosition(error)
                );
            } else {
                throw new Error('Geolocation is not supported by this browser');
            }
        }
    }
 
    handleSuccessPosition(position) {
        const { latitude, longitude } = position.coords;
 
        const mediatorEvent = new MediatorEventModel();
        this.resetInputValue();
        mediatorEvent.eventType = MediatorEvents.getCurrentPosition;
 
        const searchQuery = config.filterPanelControl.getSearchQuery();
        Object.assign(searchQuery, ...config.requestParameters);
        const bounds = config.state.map.bounds;
        if (Array.isArray(bounds)) Object.assign(searchQuery, ...bounds);
        searchQuery.latitude = latitude;
        searchQuery.longitude = longitude;
        searchQuery.isLocal = true;
 
        mediatorEvent.searchQuery = searchQuery;
        const queryString = Utils.convertToQuery(searchQuery);
        mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
        mediatorEvent.position = position;
 
        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }
 
    handleErrorPosition(error) {
        throw new Error(`ERROR(${error.code}): ${error.message}`);
    }

    handleErrorPosition(error) {
        throw new Error(`ERROR(${error.code}): ${error.message}`);
    }

    getInputValue() {
        return this.inputNode.value;
    }

    getTrackDetails() {
        const trackDetails = {
            category: this.trackCategory,
            label: this.trackLabel,
            event: this.trackEvent,
            breedId: this.trackBreedId,
            specieId: this.trackSpecieId,
            age: this.trackAge
        };
        return trackDetails;
    }
}

import BaseComponent from '$Foundation/src/js/BaseComponent';
import Utils from '$Foundation/src/js/utils';
import { config } from '../../index';
import MediatorEvents from '../enums/mediatorEvents';
import MediatorEventModel from '../models/mediatorEventModel';
import EventEmitter from '$Foundation/src/js/EventEmitter';

export default class FilterPaginationControl extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return 'filter-pagination';
    }

    static get constructorName() {
        return 'filterPaginationControl';
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    setVariables() {
        this.isInited = true;
        this.mediator = null;
        this.paginationButtons = [
            ...this.el.querySelectorAll('[data-ref="filter-pagination-btn"]')
        ];
        this.paginationInputs = [
            ...this.el.querySelectorAll('[data-ref="filter-pagination-input"]')
        ];
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    addListeners() {
        this.paginationButtons.forEach((button) => {
            this.addListener(button, 'click', e => this.handlePaginationBtnClick(e));
        });

        this.paginationInputs.forEach((input) => {
            this.addListener(input, 'keypress', e => this.handleInputKeyPress(e));
        });
    }

    handlePaginationBtnClick(e) {
        e.preventDefault();

        const mediatorEvent = new MediatorEventModel();
        mediatorEvent.eventType = MediatorEvents.paginationButtonClicked;
        const urlParams = new URLSearchParams(window.location.search);
        const map = config.state.map;
        const urlCurrentLatitude = urlParams.get('currentLatitude') && urlParams.get('currentLatitude');
        const urlCurrentLongitude = urlParams.get('currentLongitude') && urlParams.get('currentLongitude');
        const contactTypes = urlParams.get('contacttypes') && urlParams.get('contacttypes');
        const sortResultsBy = urlParams.get('sortresultsby') && urlParams.get('sortresultsby');
        const urlLatitude = urlParams.get('latitude') && urlParams.get('latitude');
        const urlLongitude = urlParams.get('longitude') && urlParams.get('longitude');

        const searchQuery = config.filterPanelControl.getSearchQuery();
        Object.assign(searchQuery, ...config.requestParameters);
        // searchQuery.searchQuery = config.filterSearchControl.getInputValue(); (I don't know why it is here but this was breaking the pagination)
        const bounds = config.state.map.bounds;
        if (Array.isArray(bounds)) Object.assign(searchQuery, ...bounds);
        searchQuery.page = e.target.getAttribute('data-page');

        if (map.latitude && map.longitude) {
            searchQuery.latitude = map.latitude;
            searchQuery.longitude = map.longitude;
        } else {
            searchQuery.latitude = urlLatitude;
            searchQuery.longitude = urlLongitude;
        }

        if (urlParams.get('searchQuery')) {
            searchQuery.searchQuery = urlParams.get('searchQuery');
        }

        if (map.currentLatitude && map.currentLongitude) {
            searchQuery.currentLatitude = map.currentLatitude;
            searchQuery.currentLongitude = map.currentLongitude;
        } else {
            searchQuery.currentLatitude = urlCurrentLatitude;
            searchQuery.currentLongitude = urlCurrentLongitude;
        }

        if (contactTypes) {
            searchQuery.contacttypes = contactTypes;
        }

        if (sortResultsBy) {
            searchQuery.sortresultsby = sortResultsBy;
        }
        if (config.filterStockistsControl) {
            for (const [key, value] of Object.entries(
                config.filterStockistsControl.getAllFilters()
            )) {
                searchQuery[key] = value;
            }
        }

        searchQuery.IsFilterApplied = urlParams.get("IsFilterApplied") && urlParams.get("IsFilterApplied");

        mediatorEvent.searchQuery = searchQuery;
        const queryString = Utils.convertToQuery(searchQuery);

        mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
            EventEmitter.emit('pagination:change');
        }
    }

    handleInputKeyPress(e) {
        const regExp = /^\d+$/;
        const char = String.fromCharCode(e.which);
        const urlParams = new URLSearchParams(window.location.search);
        const map = config.state.map;
        const urlCurrentLatitude = urlParams.get('currentLatitude') && urlParams.get('currentLatitude');
        const urlCurrentLongitude = urlParams.get('currentLongitude') && urlParams.get('currentLongitude');
        const contactTypes = urlParams.get('contacttypes') && urlParams.get('contacttypes');
        const sortResultsBy = urlParams.get('sortresultsby') && urlParams.get('sortresultsby');
        const urlLatitude = urlParams.get('latitude') && urlParams.get('latitude');
        const urlLongitude = urlParams.get('longitude') && urlParams.get('longitude');

        if (!regExp.test(char)) {
            e.preventDefault();
        }

        const inputValue = e.target.value;

        if (e.keyCode === 13) {
            const mediatorEvent = new MediatorEventModel();
            mediatorEvent.eventType = MediatorEvents.paginationButtonClicked;

            const searchQuery = config.filterPanelControl.getSearchQuery();
            Object.assign(searchQuery, ...config.requestParameters);
            // searchQuery.searchQuery = config.filterSearchControl.getInputValue();
            const bounds = config.state.map.bounds;
            if (Array.isArray(bounds)) Object.assign(searchQuery, ...bounds);
            searchQuery.page = inputValue;

            if (map.latitude && map.longitude) {
                searchQuery.latitude = map.latitude;
                searchQuery.longitude = map.longitude;
            } else {
                searchQuery.latitude = urlLatitude;
                searchQuery.longitude = urlLongitude;
            }

            if (urlParams.get('searchQuery')) {
                searchQuery.searchQuery = urlParams.get('searchQuery');
            }
            if (config.filterStockistsControl) {

                for (const [key, value] of Object.entries(
                    config.filterStockistsControl.getAllFilters()
                )) {
                    searchQuery[key] = value;
                }
            }

            searchQuery.IsFilterApplied = urlParams.get("IsFilterApplied") && urlParams.get("IsFilterApplied");
    
            if (map.currentLatitude && map.currentLongitude) {
                searchQuery.currentLatitude = map.currentLatitude;
                searchQuery.currentLongitude = map.currentLongitude;
            } else {
                searchQuery.currentLatitude = urlCurrentLatitude;
                searchQuery.currentLongitude = urlCurrentLongitude;
            }
    
            if (contactTypes) {
                searchQuery.contacttypes = contactTypes;
            }
    
            if (sortResultsBy) {
                searchQuery.sortresultsby = sortResultsBy;
            }
			
            mediatorEvent.searchQuery = searchQuery;
            const queryString = Utils.convertToQuery(searchQuery);

            mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;

            if (config.mediator) {
                config.mediator.stateChanged(mediatorEvent);
                EventEmitter.emit('pagination:change');
            }
        }
    }

    onDestroy() {
        this.paginationButtons = null;
        this.paginationInputs = null;
        this.el = null;
    }
}

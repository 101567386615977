import BaseComponent from "$Foundation/src/js/BaseComponent";
import "./styles.scss";

export default class ProductBlock extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return "product-block";
    }

    init() {
        const firstDot = this.el.querySelector(
            "#product-block-controls-first-dot"
        );
        const lastDot = this.el.querySelector(
            "#product-block-controls-last-dot"
        );

        const container = this.el.querySelector(".product-block-container");

        const productBlocksWrapper = this.el.querySelector(
            ".product-block-container > div"
        );

        const productBlocks = this.el.querySelectorAll(
            ".product-block-container  .product-block"
        );

        productBlocks.forEach((pb) => {
            pb.addEventListener("click", (e) => {
                const dataLayer = window.dataLayer || [];
                dataLayer.push({
                    event: "productBlockClick",
                    productBlockClick: {
                        itemName: pb.lastElementChild.innerText,
                    },
                });
            });
        });

        firstDot.addEventListener("click", () => {
            container.scroll({
                left: 0,
                behavior: "auto",
            });
        });

        lastDot.addEventListener("click", () => {
            container.scroll({
                left: container.offsetWidth,
                behavior: "auto",
            });
        });

        container.addEventListener("scroll", () => {
            const percent =
                (container.scrollLeft /
                    (productBlocksWrapper.offsetWidth -
                        container.offsetWidth)) *
                100;

            if (percent <= 50) {
                firstDot.classList.add("active");
                lastDot.classList.remove("active");
            } else {
                firstDot.classList.remove("active");
                lastDot.classList.add("active");
            }
        });
    }
}

import MapGenericKakao from '$Feature/GenericComponents/code/src/mapGeneric/implementations/mapGenericKakao';

export default class MapKakao extends MapGenericKakao {
    initMap() {
        const latLng = new window.kakao.maps.LatLng(this.options.center.lat, this.options.center.lng);        
        const gmapOptions = {
            center: latLng,
            level: this.options.zoom
        };
        this.map = new window.kakao.maps.Map(this.el, gmapOptions);

        const markerOptions = { // revised but icons are not custom
            position: new window.kakao.maps.LatLng(this.options.center.lat, this.options.center.lng),
            map: this.map
        };

        this.zoomControl = new window.kakao.maps.ZoomControl();
        this.map.addControl(this.zoomControl, window.kakao.maps.ControlPosition.RIGHT);  
        this.marker = new window.kakao.maps.Marker(markerOptions);
    }
}

export default class MediatorEvents {
    static get applyFilters() {
        return 'APPLY_FILTERS';
    }

    static get clearAllFilters() {
        return 'CLEAR_ALL_FILTERS';
    }

    static get checkboxChanged() {
        return 'CHECKBOX_CHANGED';
    }

    static get filterChanged() {
        return 'FILTER_CHANGED';
    }

    static get clickFilterButton() {
        return 'CLICK_FILTER_BUTTON';
    }

    static get closeFilters() {
        return 'CLOSE_FILTERS';
    }

    static get tagRemoved() {
        return 'TAG_REMOVED';
    }

    static get search() {
        return 'SEARCH';
    }

    static get viewAllBtnClicked() {
        return 'VIEW_ALL_BTN_CLICKED';
    }

    static get paginationButtonClicked() {
        return 'PAGINATION_BUTTON_CLICKED';
    }

    static get mapMarkerClicked() {
        return 'MAP_MARKER_CLICKED';
    }

    static get mapClicked() {
        return 'MAP_CLICKED';
    }

    static get filterItemСlicked() {
        return 'FILTER_ITEM_CLICKED';
    }

    static get filterSelectChanged() {
        return 'FILTER_SELECT_CHANGED';
    }

    static get mapIdleFinished() {
        return 'MAP_IDLE_FINISHED';
    }

    static get getCurrentPosition() {
        return 'GET_CURRENT_POSITION';
    }
}

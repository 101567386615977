import { camelizeString } from '$Foundation/src/js/utils';

class GTM {
    push(category, action, label, event = 'eventTrack', userCity, userType) {
        const siteId = document.body.dataset.siteId;
        const eventCamelized = camelizeString(event);
        const eventFull = siteId + eventCamelized;
        let user = {};
        let isUserDataEmpty = true;

        const collectuserData = (userCityValue, userTypeValue) => {
            if (userCityValue) {
                Object.assign(user, { city: userCity });
                isUserDataEmpty = false;
            }
            if (userTypeValue) {
                Object.assign(user, { type: userType });
                isUserDataEmpty = false;
            }
        }     

        if (window.dataLayer) {

            const resultObject = {};

            Object.assign(resultObject,
                {
                    event: eventFull
                },
                {
                    interaction: {
                        category,
                        action,
                        label,
                        value: undefined
                    }
                }
            );

            collectuserData(userCity, userType);
            if (!isUserDataEmpty) {
                Object.assign(resultObject, { user });
            }
            window.dataLayer.push(resultObject);
        }
    }
}

export default new GTM();

import api from '$Foundation/src/js/api';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import EventEmitter from '$Foundation/src/js/EventEmitter';
import Utils from '$Foundation/src/js/utils';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';
import './styles.scss';

export default class SearchResults extends BaseComponent {
    constructor(el) {
        super(el);
        dLScriptsObserver.onLoad(() => {
            this.init();
            this.isInited = true;
        });
    }

    static get tagName() {
        return 'search-results';
    }

    setVariables() {
        this.endPointUrl = this.el.getAttribute('data-endpoint');
        this.requestFilter = this.el.getAttribute('data-request-filter');
        this.searchField = this.el.querySelector('[data-ref="sr-search-field"]');
        this.searchButtonNode = this.el.querySelector('[data-ref="search-button"]');
        this.inputValue = encodeURIComponent(this.searchField.value);
        this.listingContainer = this.el.querySelector('[data-ref="sr-listing-container"]');
        this.selector = '[data-ref="sr-listing-container"]';
        this.preloader = this.el.querySelector('[data-ref="sr-preloader"]');
        this.tabButtons = undefined;
        this.minChars = 2;
        this.isHistoryApiEnabled = !!(window.history && window.history.pushState);
    }

    addListeners() {
        this.addListener(this.searchField, 'keyup', e => this.handleInputKeyUp(e));
        this.addListener(this.searchButtonNode, 'click', () => this.handleSearchButtonClick());
        EventEmitter.subscribe('viewAll:click', data => this.handleTabActivate(data));
        EventEmitter.subscribe('search-field:get', () => this.handleReturnSearchQuery());
    }

    init() {
        this.setVariables();
        this.addListeners();
        EventEmitter.emit('search-field:updated', { value: this.inputValue });

        const location = this.getWindowLocation();
        const searchQueryObj = Utils.getSearchFromLocation(location);

        this.handleTabActivate(searchQueryObj);
    }

    handleTabActivate(data) {
        const category = data.category;
        if (!category) {
            return;
        }
        this.tabButtons = [...this.el.querySelectorAll('[data-ref="tab-btn"]')];
        const activeBtn = this.tabButtons.filter(btn => btn.getAttribute('data-category') === category)[0];
        if (activeBtn) {
            const timer = setInterval(() => {
                if (activeBtn.hasAttribute('data-active')) {
                    const activeBtnTopOffset = activeBtn.getBoundingClientRect().top;
                    activeBtn.scrollIntoView();
                    window.scrollBy(0, -activeBtnTopOffset);
                    activeBtn.click();
                    clearInterval(timer);
                }
            }, 100);
        }
    }

    handleReturnSearchQuery() {
        EventEmitter.emit('search-field:set', { value: this.inputValue });
    }

    handleInputKeyUp(e) {
        this.inputValue = e.target.value;

        if (e.keyCode === 13 && this.inputValue.length >= this.minChars) {
            this.showPreloader();

            const searchQuery = {
                searchQuery: encodeURIComponent(this.inputValue),
                filter: this.requestFilter
            };

            const queryString = Utils.convertToQuery(searchQuery);
            const url = `${this.endPointUrl}${queryString}`;
            this.getData('GET', {}, url).then((result) => {
                this.updateListing(result.data);
                this.hidePreloader();
            });
            this.setUrl(queryString);
        }
    }

    handleSearchButtonClick() {

        if (this.inputValue.length < this.minChars) return;
        this.showPreloader();

        const searchQuery = {
            searchQuery: encodeURIComponent(this.inputValue),
            filter: this.requestFilter
        };

        const queryString = Utils.convertToQuery(searchQuery);
        const url = `${this.endPointUrl}${queryString}`;
        this.getData('GET', {}, url).then((result) => {
            this.updateListing(result.data);
            this.hidePreloader();
        });
        this.setUrl(queryString);
    }

    async getData(method, data, url) {
        try {
            return await api({
                method,
                url,
                data
            });
        } catch (err) {
            this.hidePreloader();
            throw new Error(err);
        }
    }

    updateListing(data, typeOfInsertingContent) {
        EventEmitter.emit('content:willRemove', this.listingContainer);
        const dummy = document.createElement('div');
        dummy.innerHTML = data;

        const container = dummy.querySelector(`${this.selector}`);
        const html = container ? container.innerHTML : '';

        switch (typeOfInsertingContent) {
            case 'append':
                this.listingContainer.innerHTML += html;
                break;

            case 'replace':
                this.listingContainer.innerHTML = html;
                break;

            default:
                this.listingContainer.innerHTML = html;
        }

        EventEmitter.emit('content:updated', this.listingContainer);
        EventEmitter.emit('search-field:updated', { value: this.inputValue });
        this.initTabs();
    }

    initTabs() {
        const tabsInstances = [...this.el.querySelectorAll('[data-toggle-group]')];
        const DLObject = Utils.getDLObject();
        if (DLObject.features.ToggleGroup && tabsInstances.length > 0) {
            tabsInstances.map((instance) => {
                DLObject.features.ToggleGroup.init('[data-toggle-group]', instance);
            });
        }
    }

    setUrl(url) {
        if (!this.isHistoryApiEnabled) {
            return false;
        }

        if (url) {
            window.history.replaceState({}, '', url);
        } else {
            const cleanUrl =
                window.location.protocol + '//' + window.location.host + window.location.pathname;
            window.history.replaceState({}, '', cleanUrl);
        }
        return true;
    }

    getWindowLocation() {
        return window.location;
    }

    showPreloader() {
        const DLClass = Utils.getDLClass();
        this.preloader.classList.remove(`${DLClass}-hidden`);
    }

    hidePreloader() {
        const DLClass = Utils.getDLClass();
        this.preloader.classList.add(`${DLClass}-hidden`);
    }
}

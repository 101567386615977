import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class WriteReview extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'write-review';
    }

    setVariables() {
        this.scriptUrl = this.el.dataset.jsSource;
        this.productId = this.el.dataset.productId;
        this.isScriptInserted = false;
    }

    addListeners() {
        this.addListener(this.el, 'click', e => this.handleClick(e));
    }

    init() {
        this.setVariables();
        this.loadScript();
        this.addListeners();
    }

    loadScript() {
        if (this.isScriptInserted === true) {
            return;
        }
        this.isScriptInserted = true;

        const codescript = document.createElement('script');
        codescript.type = 'text/javascript';
        codescript.async = true;
        codescript.src = this.scriptUrl;

        document.body.appendChild(codescript);
    }

    handleClick(e) {
        e.preventDefault();
        $BV.ui('rr', 'submit_review', { /* eslint-disable-line */
            productId: this.productId
        });
    }
}

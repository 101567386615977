import { setTimeout } from "core-js";
import BaseComponent from "$Foundation/src/js/BaseComponent";

export default class ArticleHeroPanel extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return "article-hero-panel";
    }

    init() {
        setTimeout(() => {
            const column1 = this.el.querySelector(".column-1");
            const column2Title = this.el.querySelector(
                ".column-2 .hero-panel_article-title"
            );

            if (column2Title.offsetHeight > 130) {
                column1.style.top = "200px";
                this.el.style.paddingBottom = "80px";
            } else {
                column1.style.top = "120px";
            }
        }, 1000);
    }
}

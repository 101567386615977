import BaseComponent from '$Foundation/src/js/BaseComponent';
import GTM from '$Foundation/src/js/gtm/gtmLiveChat';
import Utils from '$Foundation/src/js/utils';

export default class LiveChatPopup extends BaseComponent {
    constructor(el) {
        super(el);
        this.openLiveChat = this.openLiveChat.bind(this);
        this.hidePreloader = this.hidePreloader.bind(this);
        this.isChatLoaded = this.isChatLoaded.bind(this);
        this.showChat = this.showChat.bind(this);
        this.closeButton = null; 
        this.formContent = null;
        this.startChatButton = null;
        this.trackCategory = 'Live-Chat Engagement / Button';
        this.trackLabel = null;
        this.trackEvent = null;
        this.advisorAvailability = null;
        this.setTrackingProperties = this.setTrackingProperties.bind(this);
        this.sendGtmEvent = this.sendGtmEvent.bind(this);
        this.init();
    }

    static get tagName() {
        return 'live-chat-popup';
    }

    setVariables() {
        this.preloader = this.el.querySelector('[data-ref="preloader"]');
    }

    addListeners() {
    }
 
    init() {
        this.setVariables();
        this.addListeners();
        this.showPreloader();
        setTimeout(this.openLiveChat.bind(this), 2000);
    }

    openLiveChat() {        
        const liveChatRibbon = document.getElementsByClassName('helpButtonEnabled');
        if (typeof liveChatRibbon === undefined || liveChatRibbon[0] === undefined) {
            return false;
        }
        liveChatRibbon[0].click();
        this.isChatLoaded();
        return false;
    }

    isChatLoaded() {         
        this.timer = setInterval(() => this.showChat(), 100);
        // wait component initialisation
        setTimeout(() => { // stop waiting in 20 seconds
            if (this.timerOn) {
                clearInterval(this.timer);
                }
            },20000);
    }
        
    showChat() {
        this.closeButton = document.querySelector('.closeButton');
        if (!this.closeButton) return false;
        clearInterval(this.timer);
        this.timerOn = false;
        setTimeout(this.hidePreloader, 2000);    

        this.formContent = document.querySelector('.formContent');
        this.startChatButton = document.querySelector('.uiButton.embeddedServiceSidebarButton');
        if (this.startChatButton) {
            this.advisorAvailability = (!this.formContent) ? "Offline" : "Online";
            this.startChatButton.addEventListener('click', () => {
                this.sendGtmEvent('Live-Chat embedded button');
            });
        }

        this.closeButton.addEventListener('click', () => {
            this.sendGtmEvent('Close popup');
            window.close();
        });

        return false;
    }

    showPreloader() {
        const DLClass = Utils.getDLClass();
        this.preloader.classList.remove(`${DLClass}-hidden`);  
    }

    hidePreloader() {
        const DLClass = Utils.getDLClass();
        this.preloader.classList.add(`${DLClass}-hidden`);
    }

    setTrackingProperties(label) {
        this.trackLabel = label;
        this.trackEvent = this.trackCategory + ' ' + this.trackLabel;
    }

    sendGtmEvent(label) {
        this.setTrackingProperties(label);
        GTM.push(this.trackCategory, 'Click', this.trackLabel, this.trackEvent, this.advisorAvailability);
    }
}

import BaseComponent from '$Foundation/src/js/BaseComponent';
import MoveTo from 'moveto';

export default class ProductRatingLink extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'product-rating-link';
    }

    setVariables() {
        this.target = document.querySelector('[data-review-header]');
    }

    addListeners() {
        this.addListener(this.el, 'click', e => this.handleClick(e));
    }

    handleClick() {
        if (!this.target) return;
        const header = document.querySelector('[data-js-header-scroll]');
        const headerOffset = header ? header.getBoundingClientRect().height : 0;
        const moveTo = new MoveTo({
            tolerance: headerOffset,
        });

        moveTo.move(this.target);
    }
    init() {
        this.setVariables();
        this.addListeners();

        if (this.target) {
            this.el.classList.add('rating--link');
        }
    }
}

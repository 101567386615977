import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class AmpRedirectBuyWidget extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'amp-redirect-buy-widget';
    }

    init() {
		if(this.getUrlParam('findLocations', 'Empty') !='Empty'){
			this.waitForBuyWidget();
		}
    }

	getUrlParam(parameter, defaultvalue){
		var urlparameter = defaultvalue;
		if(window.location.href.indexOf(parameter) > -1){
			urlparameter = this.getUrlVars()[parameter];
       }
    return urlparameter;
	}

 getUrlVars() {
		var vars = {};
		var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

    waitForBuyWidget() {
       var interval = setInterval(function() {
	   var buyWidget = document.querySelector(".ps-widget")

       if (!buyWidget || buyWidget.style.display == "none") {
                            return;
        } else {
            buyWidget.click();
              clearInterval(interval);
        }
       }, 1500);
	}
}
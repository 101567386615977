import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class VetFocusArticles extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'VetFocusArticles';
    }

    setVariables() {
        this.snippetEls = document.getElementsByClassName('reference-snippet');
        this.texts = Array.from(document.getElementsByClassName('reference-list-item'))
            .map((element)=>element.innerText);

    }

    addListeners() {
        this.snippetEls.forEach(snippetEl=>{
            this.addListener(snippetEl,"click",e=>this.openSnippet(e.target));
        })

        window.addEventListener("click",e=>{
            if(!Array.from(e.target.classList).includes("reference-snippet")){
                this.closeAllSnippets();
            }
        })
    }

    init() {
        this.setVariables();
        this.addListeners();
        // Add hidden text to all snippets
        this.snippetEls.forEach(snippetEl=>{
            const index = +snippetEl.innerHTML;
            if(index >= 1 && index <= this.texts.length) {
                const text = this.texts[index - 1];
                const node = document.createElement('div');
                node.classList.add('snippet-container');
                const textNode = document.createTextNode(text);
                node.appendChild(textNode);
                node.style.display = "none";
                snippetEl.appendChild(node);
            }
            
        })
    }


    closeAllSnippets(){
        this.snippetEls.forEach(snippetEl=>{
            const snippetContentEl = snippetEl.querySelector(".snippet-container");
            if(snippetContentEl) snippetContentEl.style.display = "none";
        })
    }

    openSnippet(snippetEl){
        this.closeAllSnippets();
        const snippetContentEl = snippetEl.querySelector(".snippet-container");
        if(snippetContentEl) snippetContentEl.style.display = "block";
    }
}

import Utils from '$Foundation/src/js/utils';
import { config } from '../../index';
import MediatorEvents from '../enums/mediatorEvents';
import MediatorEventModel from '../models/mediatorEventModel';
import AbstractFilterControl from './AbstractFilterControl';
import ViewportInfo, { DEVICE_TYPE_DESKTOP } from '$Foundation/src/js/ViewportInfo';
import GTM from '$Foundation/src/js/gtm/gtmPetDetails';

export default class FilterCheckboxControl extends AbstractFilterControl {
    constructor(el, id) {
        super(el, id);
        this.trackCategory = null;
        this.trackLabel = null;
        this.init();
    }

    init() {
        this.setVariables();
        this.addListheners();
    }

    addListheners() {
        this.addListener(this.el, 'change', e => this.handleCheckboxChange(e));
    }

    setVariables() {
        this.isInited = true;

        this.isExcluded = this.el.getAttribute('data-exclude-filter')
            ? this.el.getAttribute('data-exclude-filter') === 'true'
            : false;

        this.trackCategory = this.el.getAttribute('data-track-category') || '';
        this.trackLabel = this.el.getAttribute('data-track-label') || '';
        this.trackEvent = this.el.getAttribute('data-track-event') || '';
        this.trackAction = this.el.getAttribute('data-track-action') || '';
        this.trackBreedId = this.el.getAttribute('data-track-breed-id') || '';
        this.trackSpecieId = this.el.getAttribute('data-track-specie-id') || '';
        this.trackAge = this.el.getAttribute('data-track-age') || '';
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    clear() {
        this.el.checked = false;
    }

    isActive() {
        return this.el.checked;
    }

    getCategory() {
        const parentNode = Utils.getClosestNodes(this.el, '[data-ref="filter-category"]');

        const category = parentNode[0].getAttribute('data-filter-category').toLowerCase();
        return category;
    }

    getLabel() {
        return this.el.getAttribute('data-label');
    }

    getValue() {
        return this.el.value;
    }

    setValue(value) {
        this.el.checked = this.el.value === value;
    }

    shouldBeExcluded() {
        return this.isExcluded;
    }

    handleCheckboxChange() {
        if (
            ViewportInfo.deviceTypeByViewport !== DEVICE_TYPE_DESKTOP &&
            ViewportInfo.isTouch &&
            !this.isExcluded
        ) {
            return;
        }
        const mediatorEvent = new MediatorEventModel();

        const searchQuery = config.filterPanelControl.getSearchQuery();
        Object.assign(searchQuery, ...config.requestParameters);
        // searchQuery.searchQuery = config.filterSearchControl.getInputValue(); (I don't know why it is here but this was breaking filtering)
        const bounds = config.state.map.bounds;
        if (Array.isArray(bounds)) Object.assign(searchQuery, ...bounds);
        searchQuery.latitude = config.state.map.latitude;
        searchQuery.longitude = config.state.map.longitude;
        searchQuery.currentLatitude = config.state.map.currentLatitude;
        searchQuery.currentLongitude = config.state.map.currentLongitude;
        mediatorEvent.searchQuery = searchQuery;

        const queryString = Utils.convertToQuery(searchQuery);
        mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
        mediatorEvent.eventType = MediatorEvents.filterChanged;

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }

        // Google Tracking
        // const trackLabel = this.el.checked
        //     ? this.trackLabel + ' checked'
        //     : this.trackLabel + ' unchecked';

        GTM.push(this.trackCategory, this.trackAction, this.trackLabel, this.trackEvent, this.trackBreedId, this.trackSpecieId, this.trackAge);
    }
}

import api from '$Foundation/src/js/api';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import Utils from '$Foundation/src/js/utils';
import './styles.scss';
import EventEmitter from '$Foundation/src/js/EventEmitter';
import MoveTo from 'moveto';

export default class ListingWithPagination extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'listing-with-pagination';
    }

    setVariables() {
        this.endPointUrl = this.el.getAttribute('data-endpoint');
        this.requestFilter = this.el.getAttribute('data-request-filter');
        this.requestCategory = this.el.getAttribute('data-request-category');
        this.viewAllBtns = [...this.el.querySelectorAll('[data-ref="view-all-btn"]')];
        this.paginationButtons = [
            ...this.el.querySelectorAll('[data-ref="filter-pagination-btn"]')
        ];
        this.paginationInputs = [
            ...this.el.querySelectorAll('[data-ref="filter-pagination-input"]')
        ];
        this.dataContainer = this.el.querySelector('[data-ref="data-container"]');
        this.selector = '[data-ref="data-container"]';
        this.preloader = this.el.querySelector('[data-ref="preloader"]');
        this.filterSelect = this.el.querySelector('[data-ref="filter-select"]');
        this.retailerLink = [
            ...this.el.querySelectorAll('[data-ref="retailer-link"]')
        ]
        this.searchQuery = {
            filter: this.requestFilter,
            category: this.requestCategory,
            sortby: this.filterSelect
                ? this.filterSelect.options[this.filterSelect.selectedIndex].value.toLowerCase()
                : null
        };
    }

    addListeners() {
        this.paginationButtons.forEach((button) => {
            this.addListener(button, 'click', e => this.handlePaginationBtnClick(e));
        });

        this.viewAllBtns.forEach((button) => {
            this.addListener(button, 'click', e => this.handleViewAllClick(e));
        });

        this.paginationInputs.forEach((input) => {
            this.addListener(input, 'keypress', e => this.handleInputKeyPress(e));
        });
        this.retailerLink.forEach((link) => {
            this.addListener(link, 'click', e => this.handleRetailerClick(e));
        });
        this.addListener(this.filterSelect, 'change', e => this.handleSelectChange(e));

        EventEmitter.subscribe('search-field:updated', data => this.handleSetSearchQuery(data));
        EventEmitter.subscribe('search-field:set', data => this.handleSetSearchQuery(data));
    }

    init() {
        this.setVariables();
        this.addListeners();
        EventEmitter.emit('search-field:get');
    }

    handlePaginationBtnClick(e) {
        this.showPreloader();
        this.searchQuery.page = e.target.getAttribute('data-page');
        const queryString = Utils.convertToQuery(this.searchQuery, this.endPointUrl);
        const url = `${this.endPointUrl}${queryString}`;
        this.scrollElIntoView();
        this.getData('GET', {}, url).then((result) => {
            this.updateListing(result.data);
            this.hidePreloader();
        });
    }

    handleInputKeyPress(e) {
        const regExp = /^\d+$/;
        const char = String.fromCharCode(e.which);

        if (!regExp.test(char)) {
            e.preventDefault();
        }

        const inputValue = e.target.value;

        if (e.keyCode === 13) {
            this.showPreloader();
            this.searchQuery.page = inputValue;
            const queryString = Utils.convertToQuery(this.searchQuery, this.endPointUrl);
            const url = `${this.endPointUrl}${queryString}`;
            this.scrollElIntoView();
            this.getData('GET', {}, url).then((result) => {
                this.updateListing(result.data);
                this.hidePreloader();
            });
        }
    }

    handleViewAllClick(e) {
        e.preventDefault();
        const category = e.target.getAttribute('data-category');
        const data = {
            category
        };
        EventEmitter.emit('viewAll:click', data);
    }

    handleSelectChange(e) {
        this.showPreloader();
        const data = { sortby: e.target.options[e.target.selectedIndex].value.toLowerCase() };
        this.searchQuery = { ...this.searchQuery, ...data };
        const queryString = Utils.convertToQuery(this.searchQuery, this.endPointUrl);
        const url = `${this.endPointUrl}${queryString}`;
        this.getData('GET', {}, url).then((result) => {
            this.updateListing(result.data);
            this.hidePreloader();
        });
    }

    handleSetSearchQuery(data) {
        const searchQuery = data.value;
        this.searchQuery = { ...this.searchQuery, ...{ searchQuery } };
    }

    async getData(method, data, url) {
        try {
            return await api({
                method,
                url,
                data
            });
        } catch (err) {
            this.hidePreloader();
            throw new Error(err);
        }
    }

    updateListing(data, typeOfInsertingContent) {
        EventEmitter.emit('content:willRemove', this.dataContainer);

        const dummy = document.createElement('div');
        dummy.innerHTML = data;

        const container = dummy.querySelector(`${this.selector}`);

        const html = container ? container.innerHTML : '';

        switch (typeOfInsertingContent) {
            case 'append':
                this.dataContainer.innerHTML += html;
                break;

            case 'replace':
                this.dataContainer.innerHTML = html;
                break;

            default:
                this.dataContainer.innerHTML = html;
        }
        EventEmitter.emit('content:updated', this.dataContainer);
        this.init();
        this.initSelects();
    }

    initSelects() {
        const selectInstances = [
            ...this.el.querySelectorAll('[data-js-select]')
        ];
        const DLObject = Utils.getDLObject();

        if (DLObject.features.Selects && selectInstances.length > 0) {
            selectInstances.map((instance) => {
                DLObject.features.Selects.init('[data-js-select]', instance);
            });
        }
    }

    showPreloader() {
        const DLClass = Utils.getDLClass();
        this.preloader.classList.remove(`${DLClass}-hidden`);
    }
    hidePreloader() {
        const DLClass = Utils.getDLClass();
        this.preloader.classList.add(`${DLClass}-hidden`);
    }

    scrollElIntoView() {
        if (window.pageYOffset > this.el.offsetTop) {
            setTimeout(() => {
                const header = document.querySelector('[data-js-header-scroll]');
                const headerOffset = header.getBoundingClientRect().height;

                const moveTo = new MoveTo({
                    tolerance: headerOffset
                });
                const target = this.el;
                moveTo.move(target);
            }, 100);
        }
    }

    handleRetailerClick(e) {
        const title = e.target.getAttribute('data-retailer-name') || "";

        let dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'onlineRetailerClick',
            retailer: {
                'name': title
            }
        });
    }

    onDestroy() {
        while (this.dataContainer.firstChild) {

            if (this.dataContainer.firstChild.remove) {
                this.dataContainer.firstChild.remove();
            } else {
                this.dataContainer.removeChild(this.dataContainer.firstChild);
            }
        }
        this.preloader = null;
        this.dataContainer = null;
        this.paginationButtons = null;
        this.paginationInputs = null;
        this.viewAllBtns = null;
        this.el = null;
    }
}

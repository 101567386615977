import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';

export default class RelatedArticles extends BaseComponent {
    constructor(el) {
        super(el);
        dLScriptsObserver.onLoad(() => {
            this.init();
        });
    }

    static get tagName() {
        return 'related-articles';
    }

    setVariables() {
        this.relatedArticleLinks = this.el.querySelectorAll('a');
    }

    addListeners() {
        this.relatedArticleLinks.forEach(relatedArticleLink => {
            this.addListener(relatedArticleLink, 'click', e => this.onRelatedArticleLinkClick(e, relatedArticleLink));
        });
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    onRelatedArticleLinkClick(e, relatedArticleLink) {
        let dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event' : 'readMoreBlockClick',
            'readMoreBlockClick' : {
                'name' : relatedArticleLink.parentElement.dataset.name,
                'position' : relatedArticleLink.parentElement.dataset.position
                }
            }
        );
    }
}

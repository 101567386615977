import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class Calendar extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.slideIndex = 1;
    }

    static get tagName() {
        return 'form-calendar';
    }

    setVariables() {
        this.isInited = true;
        this.calendarButton = this.el.querySelector('[data-ref="calendar-button"]');
        this.previousButton = this.el.querySelector('[data-ref="previous-button"]');
        this.nextButton = this.el.querySelector('[data-ref="next-button"]');
        this.dayButtons = this.el.querySelectorAll('[data-ref="day-button"]');
        this.slides = this.el.querySelectorAll('[data-calendar-slide]');
        this.hourSection = this.el.querySelector('[data-ref="hour-section"]');
        this.hourButtons = this.el.querySelectorAll('[data-ref="hour-button"]');
        this.calendarInputHidden = document.querySelector('.calendar-input-hidden');
        this.hourSectionList = this.el.querySelector('[data-ref="hour-section-ul"]');
    }

    addListeners() {
        this.dayButtons.forEach((button) => {
            this.addListener(button, 'click', e => this.handleDay(e));
        });
        this.hourButtons.forEach((button) => {
            this.addListener(button, 'click', e => this.handleHour(e));
        });
        this.addListener(this.calendarButton, 'click', () => this.showSlides(1));
        this.addListener(this.previousButton, 'click', () => this.handleSwitch(-1));
        this.addListener(this.nextButton, 'click', () => this.handleSwitch(1));
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    setBookableHours(day) {
        const params = {
            method: 'GET',
            headers: {}
        };
        fetch(`${location.origin + '/' + location.pathname.split('/')[1]}/api/calendar/getcalendar`, params).then(response => response.json())
            .then((response) => {
                response.Months.map(x =>
                    x.CalendarDays.map((y) => {
                        if (y.FullName === day) {
                            this.hourSectionList.innerHTML =
                        `
                            <span data-ref="booking-slot-header" class="calendar-section-hour-header">${day}</span>
                            ${y.Hours.map(z => `<li data-value="${z.FullDate}" data-ref="hour-button" class="calendar-section-hour-item">${z.Hour}</li>`).join('')}
                        `;
                        }
                    }));
                this.el.querySelectorAll('[data-ref="hour-button"]').forEach((button) => {
                    this.addListener(button, 'click', e => this.handleHour(e));
                });
            });
    }

    handleSwitch(n) {
        this.showSlides(this.slideIndex += n);
        this.hourSection.style.display = 'none';
        this.dayButtons.forEach(x => x.classList.remove('active-day'));
        this.calendarInputHidden.value = null;
    }

    handleDay(e) {
        const selectedDay = e.currentTarget.getAttribute('data-value');
        this.hourSection.style.display = 'flex';
        this.dayButtons.forEach(x => x.classList.remove('active-day'));
        e.currentTarget.classList.add('active-day');
        this.setBookableHours(selectedDay);
    }

    handleHour(e) {
        this.el.querySelectorAll('[data-ref="hour-button"]').forEach(x => x.classList.remove('active-hour'));
        e.currentTarget.classList.add('active-hour');
        this.calendarInputHidden.value = e.currentTarget.getAttribute('data-value');
    }

    showSlides(n) {
        let i;
        if (n > this.slides.length) { this.slideIndex = 1; }
        if (n < 1) { this.slideIndex = this.slides.length; }
        for (i = 0; i < this.slides.length; i++) {
            this.slides[i].style.display = 'none';
        }
        this.slides[this.slideIndex - 1].style.display = 'block';
        this.previousButton.style.display = 'block';
        this.nextButton.style.display = 'block';
    }
}

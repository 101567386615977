import BaseComponent from '$Foundation/src/js/BaseComponent';
import Utils from '$Foundation/src/js/utils';

export default class ModalNav extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'modal-nav';
    }

    init() {
        this.addClassIfAccessedFromiOSDevice();
    }

    addClassIfAccessedFromiOSDevice() {
        try {
            const supportsBigInt = typeof BigInt('1') == "bigint";
        } catch (e) {
            if (navigator.platform === 'iPhone') {
                this.el.classList.add('before-ios-14');
            }
        }
    }
}

import './styles.scss';

import BaseComponent from '$Foundation/src/js/BaseComponent';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';

export default class ArticleTags extends BaseComponent {
    constructor(el) {
        super(el);
        dLScriptsObserver.onLoad(() => {
            this.init();
        });
    }

    static get tagName() {
        return 'article-tags';
    }

    setVariables() {
        this.viewAllTagsLink = this.el.querySelector('.view-all-tags-link');
        this.tagLinks = this.el.querySelectorAll('.tag-link');
    }

    addListeners() {
        this.addListener(this.viewAllTagsLink, 'click', e => this.onViewAllTagsLinkClick(e));
        this.tagLinks.forEach(tagLink => {
            this.addListener(tagLink, 'click', e => this.onTagLinkClick(e, tagLink));
        });
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    onViewAllTagsLinkClick(e) {
        e.preventDefault();
        this.el.classList.add('show-all-tags');
    }

    onTagLinkClick(e, tagLink) {
        e.preventDefault();
        let dataLayer = window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event' : 'articleTagsClick',
            'articleTagsClick' : {
                'name' : tagLink.getAttribute('aria-label'),
                'position' : tagLink.dataset.position
                }
            }
        );
    }
}

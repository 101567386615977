var Handlebars = require("D:\\a\\1\\s\\node_modules\\handlebars\\runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li class=\""
    + alias4(((helper = (helper = helpers.DLClass || (depth0 != null ? depth0.DLClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DLClass","hash":{},"data":data}) : helper)))
    + "-tag\" data-ref=\"filter-tag\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\r\n    "
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\r\n    <button class=\""
    + alias4(((helper = (helper = helpers.DLClass || (depth0 != null ? depth0.DLClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DLClass","hash":{},"data":data}) : helper)))
    + "-tag__close "
    + alias4(((helper = (helper = helpers.DLClass || (depth0 != null ? depth0.DLClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DLClass","hash":{},"data":data}) : helper)))
    + "-btn "
    + alias4(((helper = (helper = helpers.DLClass || (depth0 != null ? depth0.DLClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DLClass","hash":{},"data":data}) : helper)))
    + "-btn--icon "
    + alias4(((helper = (helper = helpers.DLClass || (depth0 != null ? depth0.DLClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DLClass","hash":{},"data":data}) : helper)))
    + "-icon "
    + alias4(((helper = (helper = helpers.DLClass || (depth0 != null ? depth0.DLClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DLClass","hash":{},"data":data}) : helper)))
    + "-close--xs "
    + alias4(((helper = (helper = helpers.DLClass || (depth0 != null ? depth0.DLClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DLClass","hash":{},"data":data}) : helper)))
    + "-iconography "
    + alias4(((helper = (helper = helpers.DLClass || (depth0 != null ? depth0.DLClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DLClass","hash":{},"data":data}) : helper)))
    + "-interactive\" data-ref=\"remove-tag-btn\"\r\n        aria-label=\"Close\" type=\"button\">\r\n        <span class=\""
    + alias4(((helper = (helper = helpers.DLClass || (depth0 != null ? depth0.DLClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DLClass","hash":{},"data":data}) : helper)))
    + "-screen-reader-text\">Close tag</span>\r\n    </button>\r\n</li>\r\n";
},"useData":true});
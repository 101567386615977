import BaseComponent from '$Foundation/src/js/BaseComponent';
import Utils from '$Foundation/src/js/utils';

export default class ContentTracker extends BaseComponent {
    constructor(el) {
        super(el);
        this.elementText = null;
        this.wordCount = null;
        this.wordCountRounded = null;
        this.init();
    }

    static get tagName() {
        return 'content-tracker';
    }

    init() {
        this.elementText = this.el.innerText;
        this.wordCount = this.elementText.trim().split(/[\s.,;]+/).length;
        // round words length
        if (this.wordCount <= 100) {
            this.wordCountRounded = this.wordCount;
        } else {
            this.wordCountRounded = Math.round(this.wordCount / 100) * 100;
        }
        if (window.dataLayer) {
		const isVetFocus = Utils.isVetFocus();
		if (isVetFocus) {
			const dataLayerLoadedEvent = dataLayer.find(o => o.event === 'datalayerLoaded');
			dataLayerLoadedEvent.page && Object.assign(dataLayerLoadedEvent.page, { wordCount: this.wordCountRounded });
		} else {
			window.dataLayer.push({ page: { articleLength: this.wordCountRounded } });
		}
	}
    }
}

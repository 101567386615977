// list of all available localizations 

import './parsley-localization/i18n/al'
import './parsley-localization/i18n/al';
import './parsley-localization/i18n/ar';
import './parsley-localization/i18n/bg';
import './parsley-localization/i18n/ca';
import './parsley-localization/i18n/cs.extra';
import './parsley-localization/i18n/cs';
import './parsley-localization/i18n/da';
import './parsley-localization/i18n/de.extra';
import './parsley-localization/i18n/de';
import './parsley-localization/i18n/el.extra';
import './parsley-localization/i18n/el';
import './parsley-localization/i18n/en.extra';
import './parsley-localization/i18n/en';
import './parsley-localization/i18n/es';
import './parsley-localization/i18n/et';
import './parsley-localization/i18n/eu';
import './parsley-localization/i18n/fa';
import './parsley-localization/i18n/fi.extra';
import './parsley-localization/i18n/fi';
import './parsley-localization/i18n/fr.extra';
import './parsley-localization/i18n/fr';
import './parsley-localization/i18n/he.extra';
import './parsley-localization/i18n/he';
import './parsley-localization/i18n/hr.extra';
import './parsley-localization/i18n/hr';
import './parsley-localization/i18n/hu.extra';
import './parsley-localization/i18n/hu';
import './parsley-localization/i18n/id.extra';
import './parsley-localization/i18n/id';
import './parsley-localization/i18n/it.extra';
import './parsley-localization/i18n/it';
import './parsley-localization/i18n/ja.extra';
import './parsley-localization/i18n/ja';
import './parsley-localization/i18n/ko';
import './parsley-localization/i18n/lt.extra';
import './parsley-localization/i18n/lt';
import './parsley-localization/i18n/lv.extra';
import './parsley-localization/i18n/lv';
import './parsley-localization/i18n/ms.extra';
import './parsley-localization/i18n/ms';
import './parsley-localization/i18n/nl.extra';
import './parsley-localization/i18n/nl';
import './parsley-localization/i18n/no';
import './parsley-localization/i18n/nb';
import './parsley-localization/i18n/pl';
import './parsley-localization/i18n/pt-br';
import './parsley-localization/i18n/pt-pt';
import './parsley-localization/i18n/ro.extra';
import './parsley-localization/i18n/ro';
import './parsley-localization/i18n/ru.extra';
import './parsley-localization/i18n/ru';
import './parsley-localization/i18n/sk.extra';
import './parsley-localization/i18n/sk';
import './parsley-localization/i18n/sl.extra';
import './parsley-localization/i18n/sl';
import './parsley-localization/i18n/sq';
import './parsley-localization/i18n/sr.extra';
import './parsley-localization/i18n/sr';
import './parsley-localization/i18n/sv.extra';
import './parsley-localization/i18n/sv';
import './parsley-localization/i18n/th';
import './parsley-localization/i18n/tk';
import './parsley-localization/i18n/tr';
import './parsley-localization/i18n/ua.extra';
import './parsley-localization/i18n/ua';
import './parsley-localization/i18n/uk.extra';
import './parsley-localization/i18n/uk';
import './parsley-localization/i18n/zh_cn.extra';
import './parsley-localization/i18n/zh_cn';
import './parsley-localization/i18n/zh_tw';

const localeList = ['nl', 'ru', 'no', 'al', 'ar', 'bg', 'ca', 'cs',
    'da', 'de', 'el', 'en', 'es', 'et', 'eu', 'fa', 'fi', 'fr', 'he', 'hr',
    'hu', 'id', 'it', 'ja', 'ko', 'lt', 'lv', 'ms', 'nl', 'no', 'nb', 'pl', 'pt-pt',
    'pt-br', 'ro', 'ru', 'sk', 'sl', 'sq', 'sr', 'sv', 'th', 'tk', 'tr', 'ua', 
    'uk'];
    
export { localeList };

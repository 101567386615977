import BaseComponent from '$Foundation/src/js/BaseComponent';
import api from '$Foundation/src/js/api';

export default class ReviewFeedback extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'review-feedback';
    }

    setVariables() {
        this.scriptUrl = this.el.dataset.jsSource;
        this.productId = this.el.dataset.productId;
        this.reviewId = this.el.dataset.reviewId;
        this.isScriptInserted = false;
        this.feedbackButtons = this.el.querySelectorAll('button.review-button');
    }

    addListeners() {
        this.feedbackButtons.forEach(button => {
          button.addEventListener('click', e => this.handleClick(
            e,
            button.dataset.feedbackType,
            button.dataset.feedbackVote
          ));
        });
    }

    disableButtonsForAlreadySubmittedFeedback() {
      this.feedbackButtons.forEach(button => {
        if (
          localStorage.getItem(
            `BV-review-${this.reviewId}-${button.dataset.feedbackType}`
          ) === 'submitted'
        ) {
          button.setAttribute('disabled', '');
        }
      });
    }

    init() {
        this.setVariables();
        this.loadScript();
        this.addListeners();
        this.disableButtonsForAlreadySubmittedFeedback();
    }

    loadScript() {
        if (this.isScriptInserted === true) {
            return;
        }
        this.isScriptInserted = true;

        const codescript = document.createElement('script');
        codescript.type = 'text/javascript';
        codescript.async = true;
        codescript.src = this.scriptUrl;
        document.body.appendChild(codescript);
    }

    handleFeedbackSubmitSuccess(feedbackType, reviewId) {
      this.feedbackButtons.forEach(button => {
        if (button.dataset.feedbackType === feedbackType) {
          button.setAttribute('disabled', '');
        }
      });

      localStorage.setItem(`BV-review-${reviewId}-${feedbackType}`, 'submitted');
    }

    isOnStaging() {
      return ['deletedev', 'deletestaging'].includes(
        window.location.host.split('.')[1]
      );
    }

    handleClick(e, feedbackType, feedbackVote) {
      const { reviewId } = this;

      let params = {
        feedbackType: feedbackType,
        contentType: 'review',
        contentId: reviewId,
        apiVersion: '5.4',
        passkey: this.isOnStaging() ?
          '7gbjo0u78s64lbnituf33sh2k' :
          '3dycvni8fq2cozoxjs42dxdbb',
      };

      if (feedbackType === 'helpfulness') {
        params.vote = feedbackVote;
      }

      api.post(
        `https://${this.isOnStaging() ? 'stg.' : ''}api.bazaarvoice.com/data/submitfeedback.json`,
        null,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          params,
        }
      ).then(res => {
        if (!res.data.HasErrors) {
          this.handleFeedbackSubmitSuccess(feedbackType, reviewId);
        }
      });
    }
}

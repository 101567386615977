export const getClosestNodes = (elem, selector) => {
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                const matches = (this.document || this.ownerDocument).querySelectorAll(s);
                const i = matches.length;
                return i > -1;
            };
    }

    const parents = [];

    for (; elem && elem !== document; elem = elem.parentNode) {
        if (selector) {
            if (elem.matches(selector)) {
                parents.push(elem);
            }
        } else {
            parents.push(elem);
        }
    }

    return parents;
};

export const convertToQuery = (data, endPointUrl) => {
    const isUrlWithParameters = endPointUrl && endPointUrl.indexOf('?') > -1;
    const query = Object.keys(data)
        .filter(key => data[key] !== '' && data[key] !== null)
        .map(key => key + '=' + data[key])
        .join('&');
    const queryString = isUrlWithParameters ? '&' + query : '?' + query;
    return query.length > 0 ? queryString : '';
};

export const createEvent = (name) => {
    if (typeof Event === 'function') {
        return new Event(name, { bubbles: true });
    }
    const event = document.createEvent('Event');
    event.initEvent(name, true, true);
    return event;
};

export const createElement = (htmlString) => {
    if (htmlString.indexOf('<html') !== -1) {
        throw new Error('Trying to create element from the complete html page. Partial html is required');
    }

    const template = document.createElement('template');
    template.innerHTML = htmlString.trim();
    const element = template.content ? template.content.firstChild : template.firstChild;
    // move element to current document to avoid bugs later when we insert it to DOM
    document.adoptNode(element);
    return element;
};

export const getParamsFromQueryString = (queryString) => {
    const str = queryString.replace(/\/$/, '') || '';
    const obj = {};
    let key;
    let value;

    str.split('&').forEach((keyValue) => {
        if (keyValue) {
            value = keyValue.split('=');
            key = decodeURIComponent(value[0]);
            obj[key] = value[1] ? decodeURIComponent(value[1]) : '';
        }
    });
    return obj;
};

export const getSearchFromLocation = location => getParamsFromQueryString(location.search.substring(1));

export const camelizeString = (str) => {
    const stringResult = str.replace(/\W+(.)/g, (match, chr) => {
        return chr.toUpperCase();
    });
    return stringResult.charAt(0).toLowerCase() + stringResult.slice(1);
};


export const classWatcher = (targetNode, classToWatch, classAddedCallback, classRemovedCallback) => {

    let observer = null;
    let lastClassState = targetNode.classList.contains(classToWatch);

    const mutationCallback = (mutationsList) => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                let currentClassState = mutation.target.classList.contains(classToWatch);
                if(lastClassState !== currentClassState) {
                   lastClassState = currentClassState;
                    if(currentClassState && (typeof classAddedCallback === "function")) {
                        classAddedCallback();
                    }
                    else if (typeof classRemovedCallback === "function") {
                        classRemovedCallback();
                    }
                }
            }
        }
    }
    observer = new MutationObserver(mutationCallback);
    observer.observe(targetNode, { attributes: true });
    const disconnect = () => {
        observer.disconnect();
    }
}

export const getDLObject = () => {
    if (window.location &&
        window.location.host &&
        (window.location.host.split('.').some(part => part.includes('eukanuba')) || window.location.host.split('.').some(part => part.includes('sportingdog')))
    ) {
        return window.EUKA
    } else {
        return window.RCDL
    }
};

export const getDLClass = () => {
    if (window.location &&
        window.location.host &&
        (window.location.host.split('.').some(part => part.includes('eukanuba')) || window.location.host.split('.').some(part => part.includes('sportingdog')))
    ) {
        return 'euka'
    } else {
        return 'rc'
    }
};

export const isEukanuba = () => {
    if (window.location &&
        window.location.host &&
        (window.location.host.split('.').some(part => part.includes('eukanuba')) || window.location.host.split('.').some(part => part.includes('sportingdog')))
    ) {
        return true;
    } else {
        return false;
    }
}

export const isVetFocus = () => {
    if (window.location && window.location.host && window.location.host.split('.')[0].includes('vetfocus') === true) {
        return true;
    } else {
        return false;
    }
}

export default {
    getClosestNodes,
    convertToQuery,
    createEvent,
    createElement,
    getParamsFromQueryString,
    getSearchFromLocation,
    camelizeString,
    classWatcher,
    getDLObject,
    getDLClass,
    isEukanuba,
    isVetFocus
};

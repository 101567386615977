import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class CopyLink extends BaseComponent {
    constructor(el) {
        super(el);
        this.el = el;
        this.init();
    }

    static get tagName() {
        return 'copy-link';
    }

    init() {
        this.addListeners();
    }

    addListeners() {
        this.addListener(this.el, 'click', this.onClick);
    }

    onClick(e) {
        e.preventDefault();

        const shareUrl = event.target.getAttribute("data-url");

        navigator.clipboard.writeText(shareUrl)
            .then(function () {
                alert("URL was copied to clipboard");
            });
    }
}


import BaseComponent from '$Foundation/src/js/BaseComponent';
import EventEmitter from '$Foundation/src/js/EventEmitter';
import Utils from '$Foundation/src/js/utils';
import { config } from '../../index';
import MediatorEvents from '../enums/mediatorEvents';
import MediatorEventModel from '../models/mediatorEventModel';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';
import GTM from '$Foundation/src/js/gtm/gtmStockistDetails';

export default class FilterListingControl extends BaseComponent {
    constructor(el) {
        super(el);
        dLScriptsObserver.onLoad(() => {
            this.init();
        });
    }

    init() {
        this.setVariables();
        this.addListeners();
        this.firefoxFixImageSize();
    }

    static get tagName() {
        return 'filter-listing';
    }

    static get constructorName() {
        return 'filterListingControl';
    }

    setVariables() {
        this.isInited = true;
        this.mediator = null;
        this.preloader = this.el.querySelector('[data-ref="preloader"]');
        this.dataContainer = this.el.querySelector('[data-ref="data-container"]');
        this.carouselInstances = [...this.el.querySelectorAll('[data-js-carousel]')];
        this.selector = '[data-ref="data-container"]';
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }


    addListeners() {
        this.addListener(this.dataContainer, 'click', e => this.handleContainerClick(e));
    }
    firefoxFixImageSize() {
        if (navigator.userAgent.indexOf("Firefox") != -1) {
            let imagArray = document.getElementsByClassName('rc-card__bagshot')
            let imgArrayList = [...imagArray]
            imgArrayList.forEach(imag => {
                imag.attributes.sizes.value = '200px';
            })
        }
    }

    handleContainerClick(e) {
        const filterItemNode = Utils.getClosestNodes(
            e.target,
            '[data-ref="filter-listing-item"]'
        )[0];

        if (!filterItemNode) {
            return;
        }

        this.pushGoogleTracking(filterItemNode);

        const pinId = filterItemNode.getAttribute('data-id');
        const mediatorEvent = new MediatorEventModel();

        const targetPin = config.state.pins.data.filter(x => x.id === pinId)[0];

        mediatorEvent.eventData = {
            pinId,
            targetPin
        };

        mediatorEvent.eventType = MediatorEvents.filterItemclicked;

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }

    pushGoogleTracking(item) {
        const trackCategory = item.getAttribute('data-track-category') || '';
        const trackLabel = item.getAttribute('data-track-label') || '';
        const trackEvent = item.getAttribute('data-track-event') || '';
        const trackAction = item.getAttribute('data-track-action') || '';
        const trackCustomerType = item.getAttribute('data-track-customer-type') || '';
        const trackCustomerName = item.getAttribute('data-track-customer-name') || '';
        GTM.push(trackCategory, trackAction, trackLabel, trackEvent, trackCustomerType, trackCustomerName);
    }

    updateListing(data, typeOfInsertingContent) {
        EventEmitter.emit('content:willRemove', this.dataContainer);


        if (this.carouselInstances.length > 0) {
            this.destroyCarousels();
        }

        const dummy = document.createElement('div');
        dummy.innerHTML = data;

        const container = dummy.querySelector(`${this.selector}`);

        const html = container ? container.innerHTML : '';

        switch (typeOfInsertingContent) {
            case 'append':
                this.dataContainer.innerHTML += html;

                break;

            case 'replace':
                this.dataContainer.innerHTML = html;
                break;

            default:
                this.dataContainer.innerHTML = html;
        }


        this.initCarousels();

        const dataObj = {
            facets: this.getFacetsData()
        };

        EventEmitter.emit('content:updated', this.dataContainer);
        EventEmitter.emit('facets:updated', dataObj);
    }

    destroyCarousels() {
        const carouselInstancesWithinContainer = [
            ...this.el.querySelectorAll('[data-js-carousel]')
        ];
        const carouselInstancesIds = carouselInstancesWithinContainer.map(instance =>
            instance.getAttribute('id'));

        const DLObject = Utils.getDLObject();
        const RCDLCarouselInstances = DLObject.features.Carousel.instance;
        Object.keys(RCDLCarouselInstances).forEach((i) => {
            const instance = RCDLCarouselInstances[i];

            if (instance != null && instance.isOn) {
                if (carouselInstancesIds.includes(instance.getInfo().container.id)) {
                    instance.destroy();
                }
            }
        });
    }

    initCarousels() {
        const carouselInstancesWithinContainer = [
            ...this.el.querySelectorAll('[data-js-carousel]')
        ];

        const DLObject = Utils.getDLObject();
        if (DLObject.features.Carousel) {
            carouselInstancesWithinContainer.map((instance) => {
                DLObject.features.Carousel.init('[data-js-carousel]', instance);
            }
            );
        }
    }

    onDestroy() {
        while (this.dataContainer.firstChild) {
            this.dataContainer.firstChild.remove();
        }
    }

    getFacetsData() {
        const jsonScriptNode = this.el.querySelector('[data-ref="filter-facets-json"]');
        const jsonData = jsonScriptNode ? JSON.parse(jsonScriptNode.innerHTML) : null;
        return jsonData;
    }

    setActiveItem(target) {
        const items = [...this.el.querySelectorAll('[data-ref="filter-listing-item"]')];
        items.forEach((item) => {
            item.classList.add('is-hidden');
        });
        const targetEl = this.el.querySelector(`[data-id="${target.id}"]`);
        if (targetEl) {
            targetEl.classList.remove('is-hidden');
        }
    }

    resetActiveItems() {
        const items = [...this.el.querySelectorAll('[data-ref="filter-listing-item"]')];
        items.forEach((item) => {
            item.classList.remove('is-hidden');
        });
    }

    showPreloader() {
        const DLClass = Utils.getDLClass();
        if (this.preloader) this.preloader.classList.remove(`${DLClass}-hidden`);
    }

    hidePreloader() {
        const DLClass = Utils.getDLClass();
        this.preloader.classList.add(`${DLClass}-hidden`);
    }
}

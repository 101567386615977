export default class BaiduMap {
    constructor(el, options) {
        this.el = el;
        this.options = options;
    }

    initApi() {
        this.apiReadyPromise = new Promise((apiResolve) => {
            if (!BaiduMap.isApiReady) {
                BaiduMap.loadScript(apiResolve);
            } else {
                apiResolve();
            }
        });
        return this.apiReadyPromise;
    }

    triggerResize() {}

    static loadScript(callback) {
        BaiduMap.callbacks.push(callback);
        if (BaiduMap.isScriptInserted === true) {
            return;
        }

        BaiduMap.isScriptInserted = true;

        const mapscript = document.createElement('script');
        mapscript.type = 'text/javascript';
        mapscript.async = false;
        mapscript.src = '//api.map.baidu.com/api?v=3.0&callback=baiduLoadCallback';

        window.baiduLoadCallback = BaiduMap.onScriptLoaded;
        document.body.appendChild(mapscript);
    }

    static loadInfoboxScript(callback) {
        if (BaiduMap.isInfoboxScriptLoaded) {
            callback();
        } else {
            BaiduMap.infoboxCallbacks.push(callback);
            if (BaiduMap.isInfoboxScriptInserted === true) {
                return;
            }

            BaiduMap.isInfoboxScriptInserted = true;

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = false;
            script.src = '//api.map.baidu.com/library/InfoBox/1.2/src/InfoBox.js';

            script.onload = () => {
                BaiduMap.isInfoboxScriptLoaded = true;
                BaiduMap.infoboxCallbacks.forEach((cb) => {
                    cb();
                });
            };
            document.body.appendChild(script);
        }
    }

    static onScriptLoaded() {
        BaiduMap.isApiReady = true;
        for (let i = 0, l = BaiduMap.callbacks.length; i < l; i++) {
            BaiduMap.callbacks[i]();
        }
    }

    static getConvertedZoom(googleMapZoom) {
        return googleMapZoom + 1;
    }
}

BaiduMap.isScriptInserted = false;
BaiduMap.isInfoboxScriptInserted = false;
BaiduMap.isInfoboxScriptLoaded = false;
BaiduMap.isApiReady = false;
BaiduMap.callbacks = [];
BaiduMap.infoboxCallbacks = [];

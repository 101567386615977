import BaseComponent from "$Foundation/src/js/BaseComponent";
import "./styles.scss";

export default class PromotItem extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
    }

    static get tagName() {
        return "promo-item";
    }

    init() {
        const p = this.el.querySelector("p.rc-body");

        if (p.offsetHeight > 80) {
            const limit =
                window.innerWidth > 768
                    ? 100
                    : Math.round((p.offsetWidth / 8) * 3);
            p.innerHTML = `${p.innerHTML.substr(0, limit)}...`;
        }
    }
}

import './styles.scss';

import BaseComponent from '$Foundation/src/js/BaseComponent';
import dLScriptsObserver from '$Foundation/src/js/dLScriptsObserver';

export default class ArticleTabbedNavigation extends BaseComponent {
    constructor(el) {
        super(el);
        this.isInited = true;
        dLScriptsObserver.onLoad(() => {
            // make sure header is inited
            this.init();
        });
    }

    static get tagName() {
        return 'article-tabbed-nav';
    }

    setVariables() {
        this.list = this.el.querySelector('[data-ref="list"]');
        this.listWrapper = this.el.querySelector('[data-ref="list-wrapper"]');
        this.elHeight = 0;
        this.listHeight = 0;
        this.header = document.querySelector('header[data-js-header-scroll]');
        this.headerHeight = 0;
        this.main = document.querySelector('main');
        this.isDirectionDown = false;
        this.heightDifference = 0;
        this.lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.listLink = this.list.querySelector('a.rc-tab');
        this.listFigure = this.list.querySelector('figure');
        this.isScrolling = 0;
    }

    addListeners() {}

    init() {
        this.setVariables();
        this.addListeners();
        this.initNav();
    }

    initNav() {
        this.el.style.top = this.header.offsetHeight + 'px'; // set offset from top so tabbed nav below main nav
        setTimeout(() => {
            this.elHeight = this.listWrapper.offsetHeight;

            this.heightDifference =
            parseInt(this.listFigure.offsetHeight + this.listFigure.style.marginBottom, 0) + 10;

            this.changeNavHeight(true);
            window.addEventListener(
                'scroll',
                () => {
                    // fix for iPhone
                    if (this.isBottom()) {
                        return;
                    }
                    this.updateNav();
                    // Clear our timeout throughout the scroll
                    window.clearTimeout(this.isScrolling);
                    // Set a timeout to run after scrolling ends
                    this.isScrolling = setTimeout(() => {
                        this.updateNavAfterScroll();
                    }, 200);
                },
                false
            );

        }, 500);        
    }

    isBottom() {
        return ((window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight);
    }
    updateNav() {
        const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScroll > this.lastScrollTop) {
            if (!this.isDirectionDown) {
                this.isDirectionDown = true;                
            }
        } else if (this.isDirectionDown) {
            this.isDirectionDown = false;
        }
        
        this.changeNavHeight(this.isDirectionDown);
        this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    }

    setNavTop() {
        // get translate value of second part of nav in header
        const headerNavSec = this.header.querySelector('.rc-header__nav--secondary');
        const headerNavSecHeight = headerNavSec.clientHeight;
        const headerNavSecStyles = window.getComputedStyle(headerNavSec);
        const headerNavSecTransform = headerNavSecStyles.getPropertyValue('transform');
        let headerNavSecTranslateY = 0;

        if (headerNavSecTransform !== 'none') {
            let headerNavSecTransformValues = headerNavSecTransform.split('(')[1];
            headerNavSecTransformValues = headerNavSecTransformValues.split(')')[0];
            headerNavSecTransformValues = headerNavSecTransformValues.split(',');
            headerNavSecTranslateY = parseInt(headerNavSecTransformValues[5], 0);
        }

        // get height of header
        const headerNavPrimary = this.header.querySelector('.rc-header__nav--primary');
        const headerNavPrimaryNew = this.header.querySelector('.rc-header__nav--primary--new');

        let headerHeight = '';

        if (headerNavPrimaryNew) {
            headerHeight = Math.floor(this.header.clientHeight) + 'px';
        } else {
            headerHeight = Math.floor(headerNavPrimary.clientHeight + headerNavSecHeight + headerNavSecTranslateY) + 'px';
        }

        
        this.el.style.top = headerHeight; // set offset from top to be below main nav        
    }

    updateNavAfterScroll() {
        this.setNavTop();
    }

    changeNavHeight(isDecreasing) {
        this.setNavTop();       
        if (isDecreasing) {
            this.listHeight = this.elHeight - this.heightDifference;
            this.list.style.marginTop = '-' + this.heightDifference + 'px';            
        } else {
            this.listHeight = this.elHeight;
            this.list.style.marginTop = '0';            
        }        
        this.main.style.marginTop = this.listHeight + 10 + 'px';
    }
}

import Utils from '$Foundation/src/js/utils';
import { config } from '../../index';
import MediatorEvents from '../enums/mediatorEvents';
import MediatorEventModel from '../models/mediatorEventModel';
import AbstractFilterControl from './AbstractFilterControl';
import ViewportInfo, { DEVICE_TYPE_DESKTOP } from '$Foundation/src/js/ViewportInfo';

export default class FilterSelectControl extends AbstractFilterControl {
    constructor(el, id) {
        super(el, id);
        this.init();
    }

    init() {
        this.setVariables();
        this.addListheners();
    }

    addListheners() {
        this.addListener(this.el, 'change', e => this.handleSelectChange(e));
    }

    setVariables() {
        this.isInited = true;
        this.isExcluded = this.el.getAttribute('data-exclude-filter')
            ? this.el.getAttribute('data-exclude-filter') === 'true'
            : false;
    }

    setMediator(mediator) {
        this.mediator = mediator;
    }

    clear() {
        this.el.selectedIndex = -1;
    }

    isActive() {
        return this.el.selectedIndex !== -1
            ? this.el.options[ this.el.selectedIndex ].value !== ''
            : false;
    }

    getCategory() {
        const parentNode = Utils.getClosestNodes(this.el, '[data-ref="filter-category"]');

        const category = parentNode[ 0 ].getAttribute('data-filter-category').toLowerCase();
        return category;
    }

    getLabel() {
        return this.id;
    }

    getValue() {
        return this.el.options[ this.el.selectedIndex ].value;
    }

    setValue(value) {
        this.el.selectedIndex = value;
    }

    shouldBeExcluded() {
        return this.isExcluded;
    }

    handleSelectChange() {
        if (
            ViewportInfo.deviceTypeByViewport !== DEVICE_TYPE_DESKTOP &&
            ViewportInfo.isTouch &&
            !this.isExcluded
        ) {
            return;
        }

        const mediatorEvent = new MediatorEventModel();
        const searchQuery = config.filterPanelControl.getSearchQuery();
        const urlParams = new URLSearchParams(window.location.search);
        const contactTypes = urlParams.get('contacttypes') && urlParams.get('contacttypes');
        const sortResultsBy = urlParams.get('sortresultsby') && urlParams.get('sortresultsby');

        Object.assign(searchQuery, ...config.requestParameters);

        if (config.filterSearchControl) {
            searchQuery.searchQuery = config.filterSearchControl.getInputValue();
        }

        mediatorEvent.searchQuery = searchQuery;
        const bounds = config.state.map.bounds;
        if (Array.isArray(bounds)) Object.assign(searchQuery, ...bounds);
        searchQuery.latitude = config.state.map.latitude;
        searchQuery.longitude = config.state.map.longitude;
        searchQuery.currentLatitude = config.state.map.currentLatitude;
        searchQuery.currentLongitude = config.state.map.currentLongitude;
        
        if (contactTypes) {
            searchQuery.contacttypes = contactTypes;
        }

		if (!Utils.isEukanuba()) {           			
			if (sortResultsBy) {
				searchQuery.sortresultsby = sortResultsBy;
			}
        }
       		
        searchQuery.isLocal = true;
      
		if (!Utils.isEukanuba()) {
			searchQuery.isLocal = searchQuery.searchQuery != null && searchQuery.searchQuery.length > 0 ? false : true;
		}
		
        const queryString = Utils.convertToQuery(searchQuery);

        mediatorEvent.eventUrl = `${config.endPointUrl}${queryString}`;
        mediatorEvent.eventType = MediatorEvents.filterSelectChanged;

        if (config.mediator) {
            config.mediator.stateChanged(mediatorEvent);
        }
    }
}

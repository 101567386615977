import './styles.scss';
import BaseComponent from '$Foundation/src/js/BaseComponent';

export default class TabbedImage extends BaseComponent {
    constructor(el) {
        super(el);
        this.init();
        this.isInited = true;
    }

    static get tagName() {
        return 'tabbed-image';
    }

    setVariables() {
        const contentId = this.el.dataset.id;
        const container = document.querySelector("[data-id='"+contentId+"']");
        this.tabs = container.querySelector(".wrapper");
        this.tabButton = container.querySelectorAll(".rc-image-tab-content");
        this.contents = container.querySelectorAll(".rc-content-container");
    }

    addListeners() {
        this.addListener(this.tabs, 'click', e => this.handlePlayButtonClick(e));
    }

    init() {
        this.setVariables();
        this.addListeners();
    }

    handlePlayButtonClick(e) {
        const id = e.target.dataset.id;
        const title = e.target.getAttribute('id');
            if (id) {
                this.tabButton.forEach((btn) => {
                btn.classList.remove("active");
                });
                e.target.classList.add("active");
                this.contents.forEach((content) => {
                content.classList.remove("active");
                });
                const element = document.getElementById("description" + id);
                element.classList.add("active");

                let dataLayer = window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'imageTabClick',
                    'component': {
                        'title': title,
                    }
                });
            }
    };
}

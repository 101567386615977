import BaseComponent from '$Foundation/src/js/BaseComponent';
import './styles.scss';

export default class ParagraphsInTwoColumns extends BaseComponent {
    constructor(el) {
        super(el);

        this.init();
    }

    static get tagName() {
        return 'paragraphs-in-two-columns';
    }

    setVariables() {        
        this.buttonMore = this.el.querySelector('.paragraphs-in-two-columns-button .show-more');
        this.buttonLess = this.el.querySelector('.paragraphs-in-two-columns-button .show-less');
        this.items = this.el.querySelectorAll('.paragraphs-in-two-columns-container');
        this.isOpen = false;
    }

    init() {
        this.setVariables()
        this.addListeners();
    }

    addListeners() {
        this.addListener(this.buttonMore, 'click', () => this.handleButtonClick());
        this.addListener(this.buttonLess, 'click', () => this.handleButtonClick());
    }

    handleButtonClick() {
        const forEach = Array.prototype.forEach;
        this.buttonMore.classList.toggle('hide');
        this.buttonLess.classList.toggle('hide');
       
        forEach.call(this.items, (el, i ) => {
            el.classList.toggle('blured');

            if (i) {
                el.classList.toggle('hide');
            }
        });

        if (this.isOpen) {
            this.items[0].scrollIntoView({block: "center", behavior: "smooth"})
        }

        this.isOpen = !this.isOpen;
    }
}

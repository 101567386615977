import React, { useCallback, useState, useEffect } from "react";

import DropdownWrapper from "./styled";

const MyAccountDropdown = ({
  loginurl,
  loginlabel,
  signupurl,
  signuplabel,
  submenuitems,
  loginEnabled,
}) => {
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  const handleClickIcon = useCallback(() => {
    setShowAccountDropdown(true);
  }, [setShowAccountDropdown]);

  useEffect(() => {
    function handleClick() {
      setShowAccountDropdown(false);
    }

    document.body.addEventListener("click", handleClick);
    return () => document.body.removeEventListener("click", handleClick);
  }, [setShowAccountDropdown]);

  const parsedSubMenuItems = submenuitems ? JSON.parse(submenuitems) : [];

  return ( 
    <div onClick={(e) => e.stopPropagation()}>
      <button
        className="rc-btn rc-btn--icon rc-icon rc-user--xs rc-iconography"
        aria-label="Settings"
        onClick={handleClickIcon}
        type="button"
      >
        <span className="rc-screen-reader-text">My Account</span>
      </button>
      {showAccountDropdown && (
        <DropdownWrapper className="rc-text-colour--text">
          {(loginEnabled === "true" || signupurl) && (
            <div className="register-container">
              {loginEnabled === "true" && (
                <>
                  <p className="rc-body">Already registered ?</p>
                  <a href={loginurl}>
                    <button
                      className="rc-btn rc-btn--one register-container_log-in-btn"
                      type="button"
                    >
                      {loginlabel}
                    </button>
                  </a>
                </>
              )}
              {signupurl && (
                <div className="rc-body">
                  <span>Don't have an account yet ?</span>
                  <a className="rc-styled-link" href={signupurl}>
                    {signuplabel}
                  </a>
                </div>
              )}
            </div>
          )}
          <div className="separator" />
          <div className="external-links">
            {parsedSubMenuItems.map((links) => (
              <a
                href={links?.Item2 || "#"}
                target="_blank"
                className="rc-large-body"
                rel="noreferrer"
              >
                {links?.Item1 || ""}
                <i className="rc-icon rc-external--xs rc-iconography" />
              </a>
            ))}
          </div>
        </DropdownWrapper>
      )}
    </div>
  );
};

export default MyAccountDropdown;

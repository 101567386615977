import { camelizeString } from '$Foundation/src/js/utils';

class GTM {
    push(category, action, label, event = 'eventTrack', advisorAvailability) {
        const siteId = document.body.dataset.siteId;
        const eventCamelized = camelizeString(event);
        const eventFull = siteId + eventCamelized;
        let advisor = {};
        let isAdvisorInfoEmpty = true;

        const collectuserData = (advisorAvailabilityValue) => {
            if (advisorAvailabilityValue) {
                Object.assign(advisor, { availability: advisorAvailabilityValue });
                isAdvisorInfoEmpty = false;
            }
        }     

        if (window.dataLayer) {

            const resultObject = {};

            Object.assign(resultObject,
                {
                    event: eventFull
                },
                {
                    interaction: {
                        category,
                        action,
                        label
                    }
                }
            );

            collectuserData(advisorAvailability);
            if (!isAdvisorInfoEmpty) {
                Object.assign(resultObject, { advisor });
            }
            window.dataLayer.push(resultObject);
        }
    }
}

export default new GTM();
